import Vue from 'vue'

const abortControllerUpdate = new AbortController()

export default {
  namespaced: true,
  state: {
    currentInvoice: null,
  },
  actions: {
    setCurrentInvoice: ({ commit }, invoiceData) => {
      commit('SET_CURRENT_INVOICE', invoiceData)
    },
    async getPaykeeperInvoice({ commit }, { amount, finance_id }) {
      const result = await Vue.$http.post('/finance/paykeeper_invoice/', { amount, finance_id })
      commit('SET_CURRENT_INVOICE', result)
    },
    async updateCurrentInvoice({ state, commit }) {
      abortControllerUpdate.abort()

      const result = await Vue.$http.put('/finance/paykeeper_invoice/', {
        invoice_id: state.currentInvoice.invoice_id,
        signal: abortControllerUpdate.signal,
      })

      commit('SET_CURRENT_INVOICE', {
        ...state.currentInvoice,
        ...result,
      })
    },
  },
  mutations: {
    SET_CURRENT_INVOICE: (state, payload) => (state.currentInvoice = payload),
  },
}
