<template>
  <div class="account-box">
    <router-link :is="disableLink ? 'span' : 'router-link'" :to="{ name: 'profileIndex' }" class="account-box__avatar">
      <img :src="thumb" :alt="name" />
    </router-link>
    <div class="account-box__person">
      <router-link :is="disableLink ? 'span' : 'router-link'" :to="{ name: 'profileIndex' }" class="account-box__name">
        {{ name }}
      </router-link>
      <span class="account-box__role">{{ role }}</span>
    </div>
    <i v-if="showExit" class="icon-exit" @click="logout" />
  </div>
</template>

<script>
export default {
  name: 'AccountBox',
  props: {
    name: String,
    role: String,
    thumb: String,
    showExit: {
      type: Boolean,
      default: false,
    },
    disableLink: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/functions';
@import '@/assets/scss/config';
@import '@/assets/scss/mixins';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.account-box {
  display: flex;
  align-items: center;
  // Elements
  .account-box__avatar {
    flex-shrink: 0;
    border-radius: 50%;
    display: block;
    width: 34px;
    height: 34px;
    margin-right: 16px;
    // Elements
    img {
      display: block;
      height: 100%;
      width: 100%;
      border-radius: 50%;
      object-fit: cover;
    }
  }
  .account-box__person {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
  .account-box__name {
    text-decoration: none;
    font-weight: 500;
    display: inline-block;
    line-height: 1;
    color: inherit;
  }
  .account-box__role {
    margin-top: 2px;
    line-height: 1;
  }
  .account-box__logout {
    flex-shrink: 0;
  }
}
</style>
