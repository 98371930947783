import { baseUrl, USER_TYPE } from '@/constants'

export const createUserLink = user => {
  const { user_type, profile, id } = user

  if (user_type !== USER_TYPE.BUYER && !profile) {
    return ''
  }

  return `${baseUrl}/${user_type === USER_TYPE.BUYER ? `clients/${id}` : `providers/${profile?.id}`}`
}
