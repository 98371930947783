export default {
  weight: {
    kg: 'kg',
    t: 't',
    piece: 'pcs',
    pcs: 'pcs',
    liter: 'l',
    package: 'pkg',
    pkg: 'pkg',
    l: 'l',
    unit: 'ед',
  },
  currencies: {
    RUB: '₽',
    USD: '$',
    EUR: '€',
  },
  // примеры: 0 | 1, 21, 101 | 2, 32, 222 | 5, 20, 118
  position: '0 positions | {n} position | {n} positions',
  bids: '0 bids | {n} bid | {n} bids',
  fromProviders: '0 providers | {n} provider | {n} providers',
  suggestions: '0 suggestions | {n} suggestion | {n} suggestions',
}
