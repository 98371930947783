export default {
  screenSize(state) {
    return state.screenSize
  },
  responsiveSize(state) {
    const [width] = state.screenSize
    if (width < 576) {
      return 'xs'
    }
    if (width < 767.5) {
      return 'sm'
    }
    if (width < 991.5) {
      return 'md'
    }
    if (width < 1200) {
      return 'lg'
    }
    return 'xl'
  },
  sidebarOpened(state) {
    return state.sidebarOpened
  },
  goodsFiltersOpened(state) {
    return state.goodsFiltersOpened
  },
  goodsFiltersActiveTab(state) {
    return state.goodsFiltersActiveTab
  },
}
