import units from './units/ru.js'
import status from './status/ru.js'
import order from './order/ru.js'
import actions from './actions/ru.js'
import errors from './errors/ru.js'

export default {
  units,
  status,
  order,
  actions,
  ...errors,
  First: 'Перв.',
  Last: 'Посл.',
  About: 'О проекте',
  Accept: 'Принять',
  Accrual: 'Начисление',
  Action: 'Действие',
  Amount: 'Количество',
  Approve: 'Одобрить',
  'Approve cooperation with the seller?': 'Одобрить сотрудничество с продавцом?',
  'Amount in package': 'Количество в упаковке',
  'Amount in pack': 'Количество в упаковке',
  'Amount exceeds available balance': 'Сумма превышает доступный баланс',
  'Available for withdrawal': 'Доступно к выводу',
  Any: 'Любой',
  'Accruals/Write-offs': 'Начисления/Списания',
  'All changes': 'Все изменения',
  'Amount of discount compensation in this order': 'Сумма компенсации скидки в этом заказе',
  'Acceptance report': 'Отчет-приемки',
  'Agree to receive the newsletter': 'Согласие на уведомления и рассылку',
  'Acceptance transfer': 'Приемка-передача',
  'Accepted / Delivered': 'Принято / Поставлено',
  'Account and documents': 'Расчеты  и документы',
  and: 'и',
  'Actual delivery / Discrepancy / Comment': 'Фактическая поставка / Возврат / Принято / Комментарий',
  'Add the first warehouse.': 'Добавьте свой первый склад.',
  'Add to cart': 'В корзину',
  'Add warehouse address': 'Добавить адрес склада',
  'Add warehouse': 'Добавить склад',
  'Add your own warehouse': 'Добавьте свой склад',
  Add: 'Добавить',
  'Add organization': 'Добавить организацию',
  'A deposit will be required': 'Потребуется внесение депозита',
  'Added successfully': 'Успешно добавлен',
  'Additional request for order': 'Пожелание к заказу',
  'Additional fields required': 'Дополнительные данные',
  'Additional data': 'Дополнительные данные',
  'Additional expenses': 'Дополнительные расходы',
  Address: 'Адрес',
  'Add product': 'Добавить товар',
  'SMS-message with a password to enter the system has been sent to your phone number':
    'СМС-сообщение с паролем для входа в систему было отправлено на ваш номер телефона',
  'Access to the system will be provided to you within 24 hours after checking the submitted data for compliance with the rules for using our service.':
    'Доступ в систему будет предоставлен вам в течении 24 часов после проверки отправленных данных на соответствие правилам использования нашего сервиса',
  'Edit product': 'Редактировать товар',
  'To log into your account, use the verification code from the last SMS as password':
    'Для входа в аккаунт используйте как пароль код подтверждения из последнего SMS',
  'Edit order': 'Редактировать заказ',
  Editing: 'Редактирование',
  'Enter additional request for order': 'Напишите пожелание к заказу',
  'Enter sum': 'Введите сумму',
  'All {0}': 'Все {0}',
  "All DC's": 'Все РЦ',
  'Already have an account?': 'Уже есть аккаунт?',
  'An invoice for payment will be sent to your email address':
    'Счет на оплату будет отправлен на ваш электронный адрес',
  "At buyer's address": 'По адресу покупателя',
  "At seller's address": 'По адресу продавца',
  'Auction starts at': 'Начало приёма ставок',
  'Auction ends at': 'Конец приёма ставок',
  'An invoice was sent to your email address': 'Счёт на оплату был отправлен на вашу электронную почту',
  Application: 'Заявка',
  Auction: 'Торги',
  'Actual delivery': 'Фактическая доставка',
  Available: 'В наличии',
  Apply: 'Применить',
  'Available in DC': 'В наличии в РЦ',
  'Balance replenishment': 'Пополнение баланса',
  'БAZA provided a discount to the buyer, which will be compensated to the Seller. This amount of compensation will be returned to you in the form of a bonus, which can be transferred to your personal account for further withdrawal as cash':
    'БAZA предоставила скидку покупателю, которая будет компенсирована продавцу. Данная сумма компенсации будет возвращена вам в виде бонуса, который можно перевести на лицевой счет для дальнейшего вывода как денежные средства',
  Balance: 'Баланс',
  'Basic data': 'Основные данные',
  Basic: 'Базовый',
  'Best offer': 'Лучшее предложение',
  'Best before date': 'Срок годности до',
  'Best offer: {0} rubles': 'Лучшее предложение: {0} рублей',
  Cooperation: 'Сотрудничество',
  Brand: 'Бренд',
  Back: 'Назад',
  Bonuses: 'Мои бонусы',
  'Bonuses history': 'История бонусов',
  'Bonuses have been successfully transferred to the account': 'Бонусы успешно переведены на счёт',
  'Bank address': 'Адрес банка',
  Bank: 'Банк',
  PolicyTextOnRegister: 'Нажимая на кнопку Зарегистрироваться вы соглашаетесь с ',
  PolicyTerm: 'Политикой конфиденциальности',
  'By category': 'Группами',
  'By confirming the order you confirm delivery cost of': 'Подтверждая заказ вы подтверждаете стоимость доставки',
  'By confirming the order you confirm the transit transaction through the operator with a commission fee for the amount of':
    'Подтверждая заказ вы подтверждаете транзитную сделку через оператора за\n комиссионное вознаграждение на сумму',
  'By date': 'По дате',
  'By name': 'По названию',
  'By price': 'По цене',
  'By products': 'По продуктам',
  'By default': 'По умолчанию',
  BIC: 'БИК',
  'Bad request.': 'Неверный запрос.',
  'Before proceeding with the registration, you need to agree to the contract for the supply of goods.':
    'Прежде чем продолжить регистрацию, вам необходимо согласиться с договором поставки товара.',
  'Before proceeding with the registration, you need to agree to the terms of use.':
    'Прежде чем продолжить регистрацию, вам необходимо согласиться с правилами пользования.',
  'Before proceeding with the registration, you need to accept the user agreement.':
    'Прежде чем продолжить регистрацию, вам необходимо принять пользовательское соглашение.',
  'By email': 'По электронной почте',
  CEO: 'Генеральный директор',
  'Caliber {0}': 'Калибр {0}',
  Caliber: 'Размерность',
  'Caliber: {0}': 'Калибр: {0}',
  'Reject cooperation with the seller?': 'Отказаться от сотрудничества с продавцом?',
  Cancel: 'Отменить',
  'Cancel combining': 'Отменить объединение',
  Comment: 'Комментарий',
  'Combined lot': 'Объединенный лот',
  'Combine lots': 'Объединить лоты',
  'Car number: {0}': 'Госзнак: {0}',
  Categories: 'Категории',
  Category: 'Категория',
  'Confirmation Code': 'Код подтверждения',
  Confirm: 'Подтвердить',
  'Confirm action': 'Подтвердите действие',
  'Contract for the supply of goods (public offer)': 'Договор поставки товара (публичная оферта)',
  'Canceled supplies': 'Отменённые поставки',
  'Category:': 'Категория:',
  'Change main': 'Сменить основной',
  'Change password': 'Изменить пароль',
  Chat: 'Чат',
  Commission: 'Комиссия',
  'Commission report': 'Отчет комиссионера',
  'Confirm receipt': 'Подтвердить получение',
  'Return request #{0} from {1}': 'Запрос на возврат #{0} от {1}',
  'Put on realization request #{0} from {1}': 'Запрос на реализацию #{0} от {1}',
  Change: 'Изменить',
  Checkout: 'Перейти к оформлению',
  'Choose a warehouse for delivery': 'Выбрать склад для доставки',
  'Choose a unit of measure': 'Выберите единицу измерения',
  'Choose a warehouse for shipment at own expanse': 'Выбрать склад для самовывоза',
  'Choose one of your warehouses': 'Выберите один из ваших складов',
  'Choose convenient for you warehouse': 'Выберите удобный для вас склад',
  'Choose a brand': 'Выберите бренд',
  'Choose the city': 'Выберите город',
  'Choose the distribution center for shipment at own expense:': 'Выбрать распределительный центр для самовывоза:',
  Choose: 'Выбрать',
  Chosen: 'Выбрано',
  Currency: 'Валюта',
  Cashback: 'Кэшбек',
  'Checking account': 'Расчетный счет',
  'Chief accountant': 'Главный бухгалтер',
  'Country not specified': 'Страна не указана',
  'Country of origin': 'Страна производства',
  'City not specified': 'Город не указан',
  City: 'Город',
  'Clear cart': 'Очистить корзину',
  'Clear all filters': 'Сбросить фильтр',
  Clearance: 'Растаможка',
  Close: 'Закрыть',
  'Complete Registration': 'Завершить регистрацию',
  Company: 'Юридическое лицо',
  'Company details': 'Данные компании',
  'Correspondent account': 'Корреспондентский счёт',
  Confirm: 'Подтвердить',
  'Contact person': 'Контактное лицо',
  'Contact support': 'Написать в тех-поддержку',
  Cost: 'Стоимость',
  Country: 'Страна',
  'Currency not selected {0}': 'Валюта не выбрана {0}',
  CurrentCountry: 'Текущая страна: {0}',
  CurrentCity: 'Текущий город: {0}',
  CurrentCityOfDelivery: 'Текущий город доставки: {0}',
  CurrentCountryOfDelivery: 'Текущая страна доставки: {0}',
  Contacts: 'Контакты',
  'Confirm сode': 'Подтвердить код',
  'Cart is clear': 'Корзина пуста.',
  'Choose a group': 'Выберите группу',
  'Choose a product': 'Выберите продукт',
  Continue: 'Продолжить',
  'Customize БAZA for you!': 'Настройте БAZA под себя!',
  'Choose a grade': 'Выберите сорт',
  'Creating product': 'Добавляем товар',
  'Choose a country': 'Выберите страну',
  'Creating request': 'Создаем заявку',
  'Choose a packaging': 'Выберите упаковку',
  'Create new': 'создать новую',
  'Code from Email': 'Код из сообщения',
  'Code from SMS': 'Код из СМС',
  'Create suggestion': 'Создать предложение',
  'Current DC': 'Текущий РЦ',
  'Current city: {0}': 'Текущий город: {0}',
  'Current password': 'Текущий пароль',
  'Current warehouse': 'Текущий склад',
  'Complete a short survey to receive personalized offers from the marketplace':
    'Пройдите короткий опрос чтобы получать персонализированные предложения от маркетплейса',
  'Data for shipment at own expense': 'Данные для самовывоза',
  Date: 'Дата',
  'Document field is required.': 'Поле документы обязательно к заполнению.',
  'Document Upload': 'Загрузка документов',
  'Document description': 'Описание документа',
  Description: 'Описание',
  'DC BAZA': 'РЦ БАЗА',
  'dd.mm.yy': 'дд.мм.гг',
  Debt: 'Долг',
  'Delivery from "{0}"': 'Поставка от «{0}»',
  'Delivery by preorder': 'Поставка по предзаказу',
  'Delivery from stock': 'Поставка по наличию',
  'Delivery of this item is currently unavailable': 'Доставка товара недоступна в данный момент',
  'Delivery to selected destination is currently unavailable, please select or add another one and try again':
    'Доставка по выбранному маршруту в данный момент недоступна. Пожалуйста, выберите или добавьте новый и попробуйте снова',
  'Delivery to address': 'Доставка по адресу',
  'Delivery cost': 'Стоимость доставки',
  Distributor: 'Дистрибьютор',
  'Distributor approval will be required': 'Потребуется разрешение дистрибьютора',
  Distributors: 'Дистрибьюторы',
  'Discount percentage': 'Процент скидки',
  'Discount price': 'Цена со скидкой',
  'Direct sale': 'Прямая продажа',
  Demands: 'Потребности',
  Days: 'Дней',
  Delay: 'Отсрочка',
  'Delayed payment': 'Оплата с отсрочкой',
  'You have changed your shipping details. The data will be updated after review by the administrator.':
    'Вы изменили данные о доставке. Данные будут обновлены после рассмотрения администратором.',
  'You have changed your organization details. Data and status will be updated after review by the administrator.':
    'Вы изменили данные вашей организации. Данные и статус будут обновлены после рассмотрения администратором.',
  'Delivery to selected destination is currently unavailable. Please choose another one and try again':
    'Доставка по выбранному маршруту в данный момент недоступна. Пожалуйста выберите или добавьте новый и попробуйте снова',
  'Pickup at selected destination is currently unavailable. Please choose another one and try again':
    'Самовывоз из выбранного склада в данный момент недоступен. Пожалуйста выберите другой и попробуйте снова',
  'Driver name': 'Имя водителя',
  Dropshipping: 'Дропшиппинг',
  Delivered: 'Поставлено',
  Deliveries: 'Поставки',
  DeliveryChangedAt: '{0} {1}',
  Delivery: 'Доставка',
  'Delivery terms': 'Условия доставки',
  'Delivery terms will be displayed after selecting a seller':
    'Условия доставки будут отображены после выбора продавца',
  'Delivery terms will be displayed after selecting a distributor':
    'Условия доставки будут отображены после выбора дистрибьютора',
  'Delivery #{0}': 'Поставка №{0}',
  'Delivery address {0}': 'Доставка по адресу {0}',
  'Delivery address': 'Адрес доставки',
  'Delivery address: {0}': 'Доставка по адресу: {0}',
  'Delivery {date} to address': 'Доставка {date} по адресу',
  'at address': 'по адресу',
  Delete: 'Удалить',
  'Delivery cost: {0}': 'Стоимость доставки: {0}',
  'Desired delivery date': 'Желаемая дата поставки',
  'Desired delivery time': 'Желаемое время поставки',
  'Delivery date': 'Дата поставки',
  'Desired date': 'Желаемая дата',
  'Desired time': 'Желаемое время',
  'DC Baza': 'РЦ База',
  Dimension: 'Размерность',
  'Download offer agreement': 'Скачать договор-оферту',
  'Download completed application': 'Скачать заполненное заявление',
  'Delivery price': 'Стоимость доставки',
  'Dimension:': 'Размерность:',
  'Documents to be loaded': 'Загружаемые документы',
  Documents: 'Документы',
  'Document sent successfully.': 'Документ успешно отправлен.',
  "Don't have an account?": 'Нет аккаунта?',
  'Edit profile': 'Редактировать профиль',
  Error: 'Ошибка',
  Edit: 'Редактировать',
  'Email has sent': 'Письмо отправлено',
  'Email notifications': 'Уведомления по E-Mail',
  'Email or phone': 'Эл. почта или телефон',
  'End of receiving suggestions': 'Окончание приёма откликов',
  'Enter weight of one piece': 'Введите вес/объем одной шт.',
  'Enter discount percentage': 'Введите процент скидки',
  'Enter amount in pack': 'Введите количество в упаковке',
  'Enter address': 'Напишите адрес',
  'Enter e-mail': 'Введите e-mail',
  'Enter password': 'Введите пароль',
  'Enter price': 'Введите цену',
  'Enter packing capacity': 'Введите затарку упаковки',
  'Enter minimum order quantity': 'Введите минимальное количество для заказа',
  'Enter new password': 'Введите новый пароль',
  'Enter the password': 'Введите пароль',
  'Enter the name of bank': 'Введите название банка',
  'Enter at least 3 characters': 'Введите минимум 3 символа',
  'Enter name': 'Введите название',
  'Enter OGRN': 'Введите ОГРН',
  'Enter OGRNIP': 'Введите ОГРНИП',
  'Enter INN': 'Введите ИНН',
  'Enter your phone number. We will send you a code to reset your password.':
    'Введите номер телефона. Мы отправим вам код для восстановления пароля.',
  'Enter your answer': 'Введите свой ответ',
  'Enter checking account': 'Введите расчетный счет',
  'Enter OKPO': 'Введите ОКПО',
  'Enter KPP': 'Введите КПП',
  'Enter BIC': 'Введите БИК',
  'Enter caliber': 'Введите размерность',
  'Extra info': 'Дополнительно',
  'Enter the phone number': 'Введите номер телефона',
  'Enter your name': 'Введите имя',
  'Enter full name': 'Введите полное название',
  'Enter your new password again': 'Введите еще раз новый пароль',
  'Enter the legal address': 'Введите юридический адрес',
  'Enter your message...': 'Напишите сообщение...',
  'Excellent quality! We are very happy with cooperation, I advise':
    'Отличное качество! Очень довольны сотрудничеством, советую',
  'Exclude from combined lot': 'Исключить из объединения',
  Exclude: 'Исключить',
  Expand: 'Раскрыть',
  'Expense description': 'Описание расхода',
  'Expense amount': 'Сумма расхода',
  Extra: 'Дополнительно',
  'Extra:': 'Дополнительно:',
  Enable: 'Активировать',
  'Feature will be enabled soon': 'Возможность временно недоступна',
  'Field {0} required': 'Field {0} required',
  'Files to be loaded': 'Эти файлы будут загружены',
  'File type': 'Тип файла',
  'File exceeds the allowed size': 'Файл превышает допустимый размер',
  'Fields are required to be completed': 'Поля обязательные для заполнения',
  'Federal Supplier': 'Федеральный поставщик',
  'Fill in your organization details and wait for verification by the administrator':
    'Заполните данные организации и дождитесь проверки администратором',
  Farming: 'Фермерское хозяйство',
  Filter: 'Фильтр',
  Filters: 'Фильтр',
  'First select the country in your profile': 'Сначала выберите страну в профиле',
  'Field is required': 'Поле обязательно к заполнению',
  'First select a country to display cities.': 'Сначала выберите страну для отображения городов.',
  'Foreign company': 'Иностранная компания',
  'Foreign Person': 'Иностранное лицо',
  'For today': 'За сегодня',
  'For a year': 'На год',
  'For {0} months': 'На {0} месяцев',
  'Full name of the bank': 'Полное название банка',
  'Full price': 'Полная цена',
  Fulfillment: 'Фулфилмент',
  'Forgot password?': 'Забыли пароль?',
  'Full Name: {0}': 'ФИО: {0}',
  'Full details': 'Полные реквизиты',
  'Full details are available upon activation of Direct Sales':
    'Полные реквизиты доступны при активации Прямой продажи',
  'Full name': 'Полное наименование',
  Guest: 'Гость',
  'Go to the offers': 'Перейти к предложениям',
  'Goods delivered': 'Поставлено товара',
  'Goods will be delivered from current to selected warehouse for additional price':
    'Товар будет доставлен на другой выбранный вами склад для самовывоза за дополнительную стоимость',
  Grade: 'Сорт',
  Group: 'Группа',
  'Go back': 'Назад',
  Hide: 'Скрыть',
  History: 'История',
  'If you do not find the letter in your inbox, then check the Spam folder.':
    'Если Вы не найдете письмо во входящих, то проверьте папку «Спам».',
  "It's impossible to place an order for now because of negative balance in your personal account, please {0} and try again":
    'Невозможно создать заказ по причине отрицательного баланса в вашем лицевом счете. Пожалуйста {0} и попробуйте снова.',
  'There are not enough funds in your personal account to create an order. Please {0} and try again.':
    'Недостаточно средств на лицевом счете для создания заказа. Пожалуйста {0} и попробуйте снова.',
  Instruction: 'Инструкция',
  'In which cities do you purchase?': 'В каких городах вы закупаете?',
  'In cart': 'В корзине',
  Importer: 'Импортер',
  'Interface language': 'Язык интерфейса',
  'Individual person': 'Физическое лицо',
  'Incoming supplies': 'Входящие заявки',
  INN: 'ИНН',
  "I'll pick up by myself": 'Сам заберу',
  'Invalid email': 'Неправильный email',
  'Invalid phone number': 'Неправильный номер телефона',
  'Information about transport successfully updated': 'Информация о транспорте успешно обновлена',
  'in profile settings': 'в настройках профиля',
  'in organization settings': 'в настройках организации',
  'Incoterms country': 'Страна incoterms',
  'Incoterms city': 'Город incoterms',
  'include delivery in the price': 'Включить доставку в цену',
  'Ivanov Ivan Ivanovich': 'Иванов Иван Иванович',
  'I am': 'Я являюсь',
  'Incoming Supply Requests': 'Входящие заявки',
  'Incoterms 2020': 'Incoterms 2020',
  'Invalid date time.': 'Неверная дата,',
  'Invalid format.': 'Неверный формат.',
  'Input city name': 'Введите название города',
  Inactive: 'Неактивен',
  'No user found.': 'Пользователь не найден.',
  'No user credentials': 'Неверный логин или пароль',
  'No additional expenses yet': 'Нет дополнительных расходов',
  'No requests from sellers yet': 'Вы еще не получали заявки от продавцов',
  'No transactions yet': 'Пока нет транзакций',
  'Nothing found, try changing your query.': 'Ничего не найдено, попробуйте изменить запрос.',
  'Nothing found yet': 'Не найдено',
  'Jane Doe': 'Аделаиды Масловой',
  Jurisdiction: 'Юрисдикция',
  KPP: 'КПП',
  'Kind of packing': 'Вид упаковки',
  'Keep products in: {0}': 'Оставить товары в: {0}',
  kg: 'кг',
  Kg: 'Кг',
  Liter: 'Литр',
  Piece: 'Штука',
  Pack: 'Упаковка',
  'Legal address': 'Юридический адрес',
  'Leave the page? Unsaved data may be lost': 'Покинуть страницу? Несохраненные данные могут быть утеряны',
  Location: 'Локация',
  'Locked funds': 'Заблокированные средства',
  'List is updating...': 'Список обновляется...',
  'List is empty': 'Список пуст',
  'Loading...': 'Загрузка...',
  'Load more': 'Загрузить еще',
  'License Plate Number': 'Госзнак',
  'Log out': 'Выход',
  'Legal entity': 'Юридическое лицо',
  'Legal status': 'Юридический статус',
  Login: 'Войти',
  'Login and take a survey': 'Войти и пройти опрос',
  'Location {0} is not set.': 'Локация {0} не установлена.',
  'Lot No. {0} from {1}, {2}': 'Лот №{0} от {1}, {2}',
  Main: 'Основное',
  'My interests': 'Мои интересы',
  'My business': 'Мой бизнес',
  'Maximum length: \n is {0} symbols': 'Максимальная длина:\n {0} символов',
  Max: 'Макс',
  Messages: 'Сообщения',
  Manufacturer: 'Производитель',
  'Mark all as read': 'Отметить все как прочитанное',
  'Make Model': 'Марка модель',
  'Minimum length 6 characters': 'Минимальная длина 6 символов',
  'Minimum 1 image is required': 'Требуется минимум 1 изображение',
  'Minimum order quantity': 'Минимальное количество для заказа',
  'Model: {0}': 'Модель: {0}',
  Name: 'Наименование',
  'Need delivery': 'Нужна доставка',
  'New password': 'Новый пароль',
  'New price': 'Новая цена',
  'New quantity': 'Новое количество',
  'New total price': 'Новая сумма',
  Notification: 'Уведомление',
  'Not enough characters for searching': 'Недостаточно символов для поиска',
  'Сredit limit exceeded. To proceed you have to pay for previous orders or {0}.':
    'Превышен кредитный лимит. Чтобы продолжить, необходимо оплатить предыдущие поставки или {0}.',
  News: 'Новости',
  'detail: No active account found with the given credentials': 'Пользователя с такими данными не существует.',
  "There's no any bids for now": 'Ставок пока нет',
  'Thank you': 'Спасибо',
  'No available statuses': 'Нет доступных статусов',
  'No. {0}': '№ {0}',
  'Not selected': 'Не выбран',
  'Not confirmed': 'Не подтвержден',
  'Not relevant': 'Не актуально',
  'Not specified': 'Не указан',
  'Not enough bonusses.': 'Не хватает бонусов.',
  'No products selected': 'Продукты не выбраны',
  'Not found': 'Не найдено',
  'No messages yet': 'Сообщений пока нет',
  'Not permitted to perform this action.': 'Нет разрешения для выполнения этого действия.',
  'No active account found with the given credentials': 'Не найден активный аккаунт с указанными данными',
  No: 'Нет',
  'No comment': 'Нет',
  'Nothing was found by your search, try': 'По запросу ничего не найдено, попробуйте',
  Published: 'Опубликован',
  Notifications: 'Уведомления',
  Return: 'Возврат',
  Reseller: 'Торговый посредник',
  'Next step': 'Продолжить',
  'Not calculated': 'Не рассчитано',
  'No seller found.': 'Продавец не найден.',
  'No buyer found.': 'Покупатель не найден.',
  'Buyer not confirmed.': 'Покупатель не подтвержден.',
  'No product found.': 'Продукт не найден.',
  'New application': 'Новая заявка',
  'Now you can go to the offers and choose the most suitable':
    'Теперь вы можете перейти к предложениями и выбрать наиболее подходящее',
  'No incoming requests yet': 'Нет входящих заявок',
  'No outgoing requests yet': 'Нет исходящих заявок',
  'No info about delivery': 'Нет информации о доставке',
  'Only new': 'Только новые',
  'Total: {0}': 'Всего: {0}',
  'Total price after edit': 'Общая стоимость после редактирования',
  'To enable this option you need to activate direct sale in organization settings':
    'Необходимо активировать прямую продажу в настройках организации',
  'Total weight after edit': 'Общий вес после редактирования',
  'To download prefilled document you have to fill all required fields and additional':
    'Чтобы скачать заполненное заявление необходимо заполнить все обязательные поля а также дополнительные',
  'Total sum': 'Сумма',
  'Order No. {0}': 'Заказ № {0}',
  Order: 'Заказ',
  'Order #{0}': 'Заказ №{0}',
  'Order configuration': 'Параметры заказа',
  'Order successfully sent!': 'Заказ успешно отправлен!',
  'Organization not specified': 'Организация не указана',
  'Organization name': 'Название организации',
  'Organization email': 'Email организации',
  'Organization phone number': 'Телефон организации',
  'One item weight': 'Вес одной шт/уп',
  'On check': 'Проверка',
  OGRN: 'ОГРН',
  OGRNIP: 'ОГРНИП',
  'OGRN/OGRNIP': 'ОГРН/ОГРНИП',
  OKPO: 'ОКПО',
  Organization: 'Организация',
  'Organization type': 'Тип организации',
  'Organization verified': 'Организация верифицирована',
  Okay: 'Хорошо',
  'Online providers': 'Поставщики в сети',
  Online: 'Онлайн',
  'Online clients': 'Онлайн клиенты',
  'On moderation': 'На проверке',
  'Outgoing Supply Requests': 'Исходящие заявки',
  'Outgoing supplies': 'Исходящие заявки',
  'Organization saved.': 'Организация сохранена.',
  'Organization details has been successfully saved and will be updated after verification.':
    'Данные организации отправлены на проверку. После проверки администрацией, профиль будет обновлен.',
  Packing: 'Упаковка',
  'Packing capacity (kg/liter)': 'Затарка упаковки (кг/литр)',
  pcs: 'шт',
  Profile: 'Профиль',
  'Profile successfully updated': 'Профиль успешно обновлен',
  'Packing: {0}': 'Упаковка: {0}',
  'Packing:': 'Упаковка:',
  'Page not found': 'Страница не найдена',
  'Paid up / Payment date': 'Оплачено / День оплаты',
  Paid: 'Оплачено',
  'Password changed successfully.': 'Пароль успешно изменен.',
  'Password changed': 'Пароль изменен',
  'Password recovery instructions have been sent to': 'Инструкция по восстановлению пароля отправлена на',
  Password: 'Пароль',
  'Passwords do not match': 'Пароли не совпадают',
  'Payment delay of {0} days': 'Отсрочка платежа {0} дней',
  'Payment delay {0} days': 'Отсрочка платежа\n {0} дней',
  'Payment delay': 'Отсрочка оплаты',
  'Payment delay (days)': 'Отсрочка платежа (дней)',
  'Payment with a delay': 'Оплата с отсрочкой',
  'Payment {0}': 'Оплата {0}',
  'Payment #{0}': 'Оплата №{0}',
  'Payment terms': 'Условия оплаты',
  Pay: 'Оплатить',
  Payment: 'Оплата',
  'Personal account': 'Лицевой счёт',
  Pickup: 'Самовывоз',
  'Pickup price from warehouse': 'Цена при самовывозе со склада',
  "Pickup at seller's address": 'Самовывоз по адресу продавца',
  'Pickup at address': 'Самовывоз по адресу',
  'Pickup {date} at address': 'Самовывоз {date} по адресу',
  'Pickup on {0} at address {1}': 'Самовывоз {0} по адресу {1}',
  'Pickup price': 'Цена при самовывозе со склада',
  'Price for': 'Цена за',
  Pictures: 'Изображения',
  'Phone number': 'Номер телефона',
  'Phone number: {0}': 'Номер телефона: {0}',
  'Photo updated': 'Фото обновлено',
  Photos: 'Фотографии',
  Price: 'Цена',
  Preorder: 'Предзаказ',
  Preview: 'Предпросмотр',
  'Product availability': 'Наличие товара',
  'Product successfully updated': 'Товар успешно обновлён',
  'Product promotion': 'Продвижение товара',
  'Product deleted': 'Товар удалён',
  'Product grade': 'Вид продукта',
  'Product description': 'Описание продукта',
  Private: 'Физ. Лицо',
  'Product groups': 'Группы товаров',
  'Proceed to checkout': 'Перейти к оплате',
  'Place a bid': 'Сделать ставку',
  'Please, select a chat for correspondence.': 'Пожалуйста, выделите чат для переписки.',
  'Please select at least 1 distributor': 'Пожалуйста, выберите минимум 1 дистрибьютора',
  'Please specify payment delay': 'Пожалуйста укажите отсрочку платежа',
  'Please select tariff plan': 'Пожалуйста, выберите тарифный план',
  'Please select product groups': 'Пожалуйста выберите группы товаров',
  'Please select type of business': 'Пожалуйста выберите тип бизнеса',
  'Please select purchase quantities': 'Пожалуйста выберите объемы закупки',
  'Please select purchasing cities': 'Пожалуйста выберите города закупки',
  'Please add at least 1 image': 'Пожалуйста, добавьте минимум 1 изображение',
  'Please set date and time': 'Пожалуйста, укажите дату и время.',
  'Please set warehouse address in profile settings': 'Пожалуйста, укажите адрес склада в настройках профиля',
  'Please choose warehouse': 'Пожалуйста, выберите склад',
  'Please choose warehouse for pickup': 'Пожалуйста выберите склад для самовывоза',
  'Please choose warehouse for delivery': 'Пожалуйста выберите склад для доставки',
  'Please select return date': 'Пожалуйста, выберите дату возврата',
  'Please fill the required fields': 'Пожалуйста, заполните необходимые поля',
  'Please select delivery method': 'Пожалуйста, выберите способ доставки',
  'Please select warehouse for pickup': 'Пожалуйста, выберите склад для самовывоза',
  'Please select warehouse for delivery': 'Пожалуйста, выберите склад для доставки',
  'Please select date and time': 'Пожалуйста, выберите дату и время',
  'Please fill document description': 'Пожалуйста, заполните описание документа',
  'Please attach a file': 'Пожалуйста, прикрепите файл',
  'Please fill organization information first': 'Сначала заполните данные об организации',
  'To download prefilled application you have to provide additional information about organization. Please fill the fields below.':
    'Чтобы скачать заполненное заявление необходимо предоставить дополнительные данные об организации. Пожалуйста заполните форму ниже',
  Position: 'Должность',
  'Primary order': 'Первичный заказ',
  'Payment Purpose': 'Назначение платежа',
  'Product properties': 'Свойства продукта',
  'Product price': 'Цена продукта',
  Product: 'Продукт',
  Products: 'Продукты',
  'Products are here': 'Товар находится здесь',
  Publish: 'Опубликовать',
  'Piece weight: {0} kg': 'Вес штуки: {0} кг',
  'Profile has been successfully saved.': 'Профиль успешно сохранен.',
  'Profile has been successfully saved. Delivery data will be updated after verification.':
    'Профиль успешно сохранен. Данные о доставке обновятся после проверки.',
  'Put on realization': 'Поставить на реализацию',
  'Quantum {0}': 'Квант {0}',
  Quantum: 'Квант',
  'Quantum: {0}': 'Квант: {0}',
  'Quantum:': 'Квант:',
  'Quality class': 'Класс качества',
  Quantity: 'Количество',
  Register: 'Зарегистрироваться',
  Remove: 'Удалить',
  'Russian Federation': 'РФ',
  'Registered: {0}': 'Зарегистрирован: {0}',
  'Remember me': 'Запомнить меня',
  'Repeat new password': 'Повторите новый пароль',
  'Repeat password': 'Введите пароль повторно',
  Retry: 'Повторить попытку',
  Request: 'Заявка',
  'Request an invoice': 'Запросить счет',
  'Request sent successfully': 'Запрос успешно отправлен',
  'Request successfully sent': 'Заявка успешно отправлена',
  'Request status': 'Статус заявки',
  'Request return': 'Оформить возврат',
  'Required field': 'Обязательное поле',
  'Required fields': 'Поля обязательные к заполнению',
  Resend: 'Выслать повторно',
  'Reset all': 'Сбросить все',
  'Roll up': 'Свернуть',
  'Reset password': 'Восстановление пароля',
  Reset: 'Cбросить',
  Reject: 'Отклонить',
  Revoke: 'Отозвать',
  'Revoke cooperation with the seller?': 'Отозвать сотрудничество с продавцом?',
  Rejected: 'Отклонено',
  Responses: 'Отклики',
  'Registration number': 'Регистрационный номер',
  'Review from': 'Отзыв от',
  'Review of goods at delivery address': 'Осмотр товара по адресу доставки',
  'Return date': 'Дата возврата',
  'Save changes': 'Сохранить изменения',
  Save: 'Сохранить',
  'Sale type': 'Тип продажи',
  Subscribe: 'Подписаться',
  Saved: 'Данные сохранены',
  'Secure payment': 'Обеспечительный платеж',
  Send: 'Отправить',
  Show: 'Показать',
  'Send to provider': 'Отправить продавцу',
  Selected: 'Выбрано',
  'Select DC': 'Выбрать РЦ',
  'Select distributor': 'Выбрать дистрибьютора',
  'Select seller': 'Выбрать продавца',
  'Select the distributors through whom you want to sell the product':
    'Выберите дистрибьюторов, через которых желаете продавать товар',
  'Select all': 'Выбрать все',
  'Select from the list': 'Выберите из списка',
  'See full profile': 'Посмотреть профиль',
  'Select warehouse for delivery': 'Выберите склад для доставки',
  'Select time': 'Выбрать время',
  'Select organization type': 'Выберите тип предприятия',
  'Select distribution center': 'Выберите распределительный центр',
  'Select one or more products': 'Выберите один или несколько продуктов',
  'Select delivery method first': 'Сначала выберите способ доставки',
  'Select city': 'Выбрать город',
  'Select warehouse for pickup': 'Выберите склад для самовывоза',
  'Select a file to upload': 'Выберите файл для загрузки',
  'Select lots you wish to combine': 'Выберите лоты, которые желаете объединить',
  'Select 2 or more lots': 'Выберите два или более лота',
  'Shipment at own expense': 'Самовывоз',
  'Should be at least \n of {0} days': 'Можно выбирать только выше\n {0} дней',
  'Should not be more than \n {0} days': 'Можно выбирать только до\n {0} дней',
  'Size {0}': 'Размер {0}',
  'Show details': 'Показать детали',
  'Something went wrong': 'Произошла ошибка',
  'Show product in promotions': 'Показывать продукт в акциях',
  Standards: 'Стандарты качества',
  'Start chat with {0}': 'Начать чат с {0}',
  'Submit Code': 'Отправить код',
  'Start chat with seller': 'Начать чат с продавцом',
  'Start chat with buyer': 'Начать чат с покупателем',
  Status: 'Статус',
  'Status successfully updated': 'Статус успешно обновлён',
  'Save password': 'Сохранить пароль',
  'Start chat': 'Начать чат',
  'Send code again': 'Отправить код повторно',
  'Send code': 'Отправить код',
  'Rate is required': 'Выберите оценку',
  'Start typing the city name': 'Начните вводить название города...',
  'Start typing the country name': 'Начните вводить название страны...',
  'Starting price: {0} rub': 'Стартовая цена: {0} руб',
  'Select Distribution Center address {0}': 'Выберите один из Распределительных Центров {0}',
  'Select distributor first': 'Сначала выберите дистрибьютора',
  'Select seller first': 'Сначала выберите продавца',
  'Special offer': 'Акция',
  Storage: 'Хранение',
  Sorting: 'Сортировка',
  Successful: 'Успешная операция',
  'Successfully changed': 'Успешно изменен',
  'Successfully updated': 'Успешно обновлен',
  'Successfull invoice request': 'Счёт успешно запрошен',
  'Set as Main Warehouse': 'Сделать склад основным',
  Support: 'Служба поддержки',
  'Support chat': 'Чат поддержки',
  Statistics: 'Статистика',
  SurveyOfGoodsAtAddressOf: 'Осмотр товара по адресу {0}',
  Survey: 'Опрос',
  'Survey is available only for buyers': 'Опрос доступен только для покупателей',
  'Survey of goods at the address of buyer': 'Осмотр товара по адресу покупателя',
  'Survey of goods at the address of seller': 'Осмотр товара по адресу продавца',
  'Survey of goods at {0} address': 'Для доставки будет использоваться адрес, который указал {0}',
  'Survey of goods': 'Осмотр товара',
  Terms: 'Правила работы',
  'Temporarily suspended': 'Временно недоступно',
  'Take a survey': 'Пройти опрос',
  'Tariff plans': 'Тарифные планы',
  'Terms of Service': 'Правила сервиса',
  'Terms of use of the Marketplace rev.2.0.': 'Правила пользования Маркетплейса ред.2.0.',
  'Go to cart': 'Перейти в корзину',
  'The closest point of delivery of goods is St. Petersburg, Sofiyskaya St., 60 of warehouse 22/3':
    'Ближайший пункт сдачи товара Санкт-Петербург, ул. Софийская, д. 60 склад 22/3',
  'The product participates in the promotion at the expense of БAZA': 'Продукт участвует в акции за счет БAZA',
  'The list of warehouses is empty.': 'Список складов пуст.',
  'The main warehouse has been successfully changed.': 'Основной склад успешно изменен.',
  'The new password must not be the same as the old one': 'Новый пароль не должен совпадать со старым',
  'The new password must be at least 6 characters long and contain both upper and lower case letters and numbers':
    'Новый пароль должен быть не менее 6 символов длиной и содержать буквы в верхнем и нижнем регистре а также числа',
  'The password length should be at least {0} symbols': 'Минимальная длина пароля {0} символов',
  'The password was successfully changed, go back to the authorization page and login again':
    'Пароль был успешно изменен, перейдите на страницу логина и авторизуйтесь снова',
  'There are no created transactions.': 'Нет сформированных операций.',
  'There are no goods in the basket. Collect a basket in': 'В корзине нет товаров. Соберите корзину в',
  'There are no new notifications.': 'Нет новых уведомлений.',
  'There are not enough funds on your balance.': 'На вашем балансе недостаточно средств.',
  'This month': 'Этот месяц',
  'Type of Activity': 'Вид деятельности',
  'Type of activity of the organization': 'Вид деятельности организации',
  'Type of sales': 'Вид продаж',
  'This field is required.': 'Это поле обязательно.',
  'To use this option it is necessary for you': 'Чтобы воспользоваться этой опцией вам необходимо',
  'To see contacts you need to have VIP-client status. Please contact admininstrator.':
    'Для просмотра контактов необходим статус VIP-клиента. Пожалуйста, обратитесь к администратору.',
  'To view contacts, you need to have direct sales enabled in organization settings':
    'Для просмотра контактов необходимо активировать вид продаж - Прямая продажа',
  Today: 'Cегодня',
  'There is not selected main warehouse in {0}': 'Не установлен основной склад в {0}',
  'There is not selected in profile.': 'Не установлена основная валюта в профиле.',
  'Total weight': 'Общий вес',
  'Try to get a code and try again.': 'Попробуйте получить новый код и попробовать еще раз.',
  'To place an order, please choose warehouse address for pickup in {0}':
    'Чтобы совершить заказ, пожалуйста выберите склад для самовывоза в {0}',
  'There is no information about payments.': 'Отсутствует информация о платежах.',
  'Top up': 'Пополнить',
  'Top up by invoice': 'Пополнить по счету',
  'Top up by card': 'Пополнить по карте',
  'Pay by card': 'Оплата картой',
  'Pay by invoice': 'Оплата по счету',
  'Pay in cash': 'Оплата наличными',
  'Pay and place an order': 'Оплатить и оформить заказ',
  'Place an order': 'Оформить заказ',
  'To make order, please add your organization info in {0} and wait for verification by admin.':
    'Чтобы совершить заказ, пожалуйста заполните информацию об организации в {0} и дождитесь верификации администратором.',
  'Please add your info about your organization in {0} and wait for verification by admin.':
    'Пожалуйста, заполните информацию об организации в {0} и дождитесь верификации администратором.',
  Search: 'Поиск',
  Seller: 'Продавец',
  'Selling through a distributor': 'Продажа через дистрибьютора',
  Summary: 'Итого',
  'Supply #{0}': 'Заявка №{0}',
  Select: 'Выбрать',
  'Select product for request': 'Выбрать продукт для заявки',
  'Select distribution center address': 'Выберите один из распределительных центров',
  'Select product to display sorts.': 'Выберите продукт для отображения сортов.',
  'Summary {0} positions with total weight {1} kg and total price {2}':
    'Итого {0} позиций общим весом {1} кг на сумму {2}',
  'Summary {0} with total weight of {1} kg and total price {2}': 'Итого {0} общим весом {1} кг. на сумму {2}',
  'Summary {0} with total quantity of {1} pcs. and total price {2}': 'Итого {0} общим количеством {1} шт. на сумму {2}',
  'Summary {0} with total weight of {1} kg and total price with delivery <span class="price-without-delivery">{2}</span> {3}':
    'Итого {0} общим весом {1} кг. с доставкой на сумму <span class="price-without-delivery">{2}</span> {3}',
  'Summary {0} with total quantity of {1} pcs. and total price with delivery <span class="price-without-delivery">{2}</span> {3}':
    'Итого {0} общим количеством {1} шт. на сумму с доставкой <span class="price-without-delivery">{2}</span> {3}',
  'Summary {0} with total quantity of {1} and {2} for total price of {3}':
    'Итого {0} общим количеством {1} и {2} на сумму c с доставкой {3}',
  'Show original': 'Открыть оригинал',
  'in terms of delivery': 'в условиях доставки',
  'Total amount can change depending on the amount of the closed deal':
    'Общая сумма может измениться в зависимости от суммы завершённой сделки',
  'Total weight {0}': 'Общий вес {0}',
  'Through distributor': 'Через дистрибьютора',
  'Total price': 'Общая стоимость',
  'Total {0}': 'Итого {0}',
  'Please select country in profile to load cities from': 'Пожалуйста, выберите страну в профиле чтобы выбрать город',
  Trailer: 'Прицеп',
  'Transit deal': 'Тразитная сделка',
  'Transfer to the account': 'Перевести на счёт',
  'Direct deal': 'Прямая сделка',
  'Pre-order deal': 'Поставка по предзаказу',
  'In stock deal': 'Поставка по наличию',
  'Transaction history': 'История сделки',
  'Transaction terms': 'Условия сделки',
  'Item in package weight': 'Вес штуки в упаковке',
  Transfer: 'Перевести',
  'Transfer bonuses to account': 'Перевод бонусов на счёт',
  'Transport details': 'Данные транспорта',
  Transport: 'Транспорт',
  'United Fruit Company': 'Юнайтед Фрут Компани',
  'Update password': 'Изменить пароль',
  'Update order': 'Обновить поставку',
  'Update warehouse address': 'Обновить адрес склада',
  'Use address from your profile': 'Установить адрес из вашего профиля',
  'Upload photo': 'Загрузить фото',
  'Update date': 'Обновить дату',
  'Upload document': 'Загрузить документ',
  'Up to {0}': 'До {0}',
  Upload: 'Загрузить',
  'User has already been verified.': 'Пользователь уже верифицирован.',
  'User was successfully verified.': 'Пользователь успешно верифицирован.',
  'User agreement': 'Пользовательское соглашение',
  'Invalid.': 'Недейсвительный.',
  'User type': 'Тип пользователя',
  Update: 'Сменить',
  Urgent: 'Срочные',
  Unknown: 'Неизвестен',
  Unpublish: 'Снять с публикации',
  unit: 'ед.',
  Unpublished: 'Снят с публикации',
  'Warehouse address added successfully.': 'Адрес склада успешно добавлен.',
  'Warehouse address has been successfully updated.': 'Адрес склада успешно обновлен.',
  'Warehouse not set': 'Не выбран адрес склада',
  'Verification error.': 'Ошибка верификации',
  'Write-off': 'Списание',
  Warehouses: 'Склады',
  'Withdraw funds': 'Вывести средства',
  'Withdraw selected': 'Вывести выбранные',
  'Withdrawal request successfully created': 'Запрос на вывод успешно отправлен',
  'With discount': 'Со скидкой',
  Withdraw: 'Вывести',
  'Wrong Email': 'Неправильный email',
  'Wrong email': 'Некорректный email',
  'Weight of one piece': 'Вес/Объем одной штуки',
  'Weight of one {0}': 'Вес одной {0}',
  'Weight of one': 'Вес одной',
  'Weight of a piece in a package': 'Вес штуки в упаковке',
  'Wrong file format': 'Неверный формат файла',
  'with delivery': 'с доставкой',
  Weight: 'Вес',
  'Your profile': 'Ваш профиль',
  'Your position': 'Ваша должность',
  'Your access is restricted. Please contact support': 'Ваш доступ ограничен. Пожалуйста, обратитесь в поддержку',
  'You must be logged in to participate in the survey.': 'Для участия в опросе необходимо войти в аккаунт',
  'Bonuses accumulated per month will be transferred to your personal account in the next month after current billing period. They will enlisted as usual money so you will be able to pay for orders with them':
    'Накопленные за месяц бонусы будут переведены на ваш лицевой счет в следующем за отчетным периодом месяце. Они будут зачислены как обычные деньги и ими можно будет оплачивать заказы.',
  'You did not make transactions yet or they were not registered.':
    'Вы еще не совершали операций или они не были зарегистрированы.',
  'You have already taken the survey': 'Вы уже проходили опрос',
  'You followed the incorrect reference or the page was removed.':
    'Вы перешли по неправильной ссылке или страница была удалена.',
  'You have been successfully registered. You will be able to log in only after confirming your profile by email.':
    'Вы были успешно зарегистрированы. Вы сможете авторизоваться только после подтверждения вашего профиля по email.',
  'You have no added warehouses yet.': 'У вас пока нет добавленных складов.',
  'You cannot delete this warehouse.': 'Вы не можете удалить данный склад.',
  'You can add additional costs for compensation by the supplier, except for commission and delivery of orders':
    'Вы можете добавить дополнительные расходы для компенсации поставщиком, кроме комиссии и доставки заказов',
  'You can only combine lots marked with the same color': 'Можно объединять только лоты выделенные одинаковым цветом',
  'You have no applications yet, try': 'У вас пока нет заявок, попробуйте',
  'You have no created deliveries.': 'У вас нет сформированных поставок.',
  'Your Email': 'Адрес электронной почты',
  'Your application': 'Ваша заявка',
  'Your answer': 'Свой ответ',
  'Answers successfully saved': 'Ответы успешно сохранены',
  'Your name': 'Ваше имя',
  'You need to choose one of the distribution centers for pickup':
    'Вам необходимо выбрать один из распределительных центров для самовывоза',
  'Please, set warehouse address for delivery in profile settings':
    'Пожалуйста, установите склад для доставки в настройках профиля',
  'Please contact support to update your product groups':
    'Пожалуйста, обратитесь в чат поддержки чтобы обновить группы товаров',
  "Please contact support to update your organization's kind of activity":
    'Пожалуйста, обратитесь в чат поддержки чтобы обновить вид деятельности организации',
  'Your request will be processed within 48 hours.': 'Ваш запрос будет обработан в течении 48 часов.',
  'You already created suggestion for this demand': 'Вы уже создали предложение для этой заявки',
  'You do not have any transactions available for withdrawal': 'У вас нет сделок доступных для вывода средств',
  "You don't have any products added, which could be included in supply request. Please add some and try again":
    'У вас пока нет добавленных продуктов подходящих для заявки на поставку. Пожалуйста добавьте и попробуйте снова.',
  "You don't have any warehouses for delivery yet, please add and try again":
    'У вас пока нет складов для доставки, пожалуйста добавьте и попробуйте снова.',
  'Your location': 'Ваша локация',
  "You don't have added products yet.": 'У вас еще нет добавленных продуктов.',
  'address not specified': 'адрес не указан',
  'Waiting for payment': 'Ожидание платежа',
  'Successful payment': 'Успешная оплата',
  'Successful order': 'Успешный заказ',
  'Successfull transfer': 'Успешный перевод',
  'Sign in / Sign up': 'Вход / Регистрация',
  'Please go to the payment page and complete the payment':
    'Пожалуйста, перейдите на страницу оплаты и совершите платеж',
  Buyer: 'Покупатель',
  days: 'дней',
  delivery: 'поставка',
  'Delivery on {0} to address {1}': 'Доставка {0} по адресу {1}',
  'Delivery – 1pcs.': 'Доставка – 1шт.',
  'Delivery is not available, collect items in one city/area':
    'Доставка недоступна, соберите товары в одном городе/районе',
  from: 'от',
  'from {0} {1}': 'от {0} {1}',
  'from {0}': 'от {0}',
  'is published': 'опубликована',
  main: 'основной',
  market: 'маркете',
  'on November 13, 2020': '13 ноября 2020',
  'reset all filters': 'сбросить фильтры',
  'Request #{0}': 'Заявка #{0}',
  'rub / kg': 'руб / кг',
  'rub / {0}': 'руб / {0}',
  marketProductName: '{0} {1}',
  marketProductPrice: '{0} / {1}',
  marketQuantityFrom: 'от {0} {1}',
  rub: 'руб',
  fio: 'ФИО',

  Total: 'Сумма',
  'Total weight should be at least {0} kg.': 'Общий вес должен быть не менее {0} кг.',
  'to sign the contract with the operator': 'заключить договор с Оператором',
  'total amount {0}': 'на сумму {0}',
  '{0} No. {1}': '{0} №{1}',
  '{0} grades': '{0} сортов',
  '{0} products': '{0} продукта',
  '{0} reviews': '{0} отзывов',
  '{0} rub': '{0} руб',
  '{0} / {1}': '{0} / {1}',
  '{0} transaction': '{0} сделка',
  '{0}% commission fee': '{0}% комиссионный сбор',
  '{0} delivery': '{0} поставка',
  '{0} length must be {1} characters': '{0} должен содержать {1} символов',
  '{0} length must be {1} or {2} characters': '{0} должен содержать {1} или {2} символов',
  '{0} days': '{0} дней',
  Exporter: 'Экспортёр',
  'Other...': 'Другое...',
  Which: 'Какие',
  'What products are you interested in?': 'Какие продукты вам интересны?',
  'What type of business are you buying for?': 'Для какого типа бизнеса вы покупаете?',
  'What purchase quantities are you interested in?': 'Какие объемы закупки  вас интересуют?',
  All: 'Все',
  'are you interested in?': 'вас интересуют?',
  'БAZA Store operator': 'Консультант БAZA Store',
  'What product categories are you interested in?': 'Какие категории продуктов вас интересуют?',
  'Choose one or more options': 'Выберите один или несколько вариантов',
  'Choose a quality class': 'Выберите класс качества',
  'Choose a kind of packing': 'Выберите вид упаковки',
  '💁‍♀️ Multiple categories can be selected. Category ⚡ PROMOTIONS ⚡ includes all categories':
    '💁‍♀️ Можно выбрать несколько категорий. Категория ⚡АКЦИИ ⚡ включает в себя все категории',
  '🗺️ Purchasing geography': '🗺️ География закупки',
  '🔥 Any categories with ⚡ PROMOTIONS ⚡': '🔥 Любые категории с ⚡ АКЦИЯМИ ⚡',
  '🤝 What type of business are you buying for?': '🤝 Для какого типа бизнеса вы покупаете?',
  '⚖️ What order volumes are you interested in?': '⚖️ Какие объемы заказов вас интересуют?',
  '🛒 Retail Store': '🛒 Розничный магазин',
  '🏬 Chain Store': '🏬 Сетевой магазин',
  '👨‍🍳 Restaurant': '👨‍🍳 Ресторан',
  '🏭 Production': '🏭 Производство',
  '🚛 Wholesale Resale': '🚛 Оптовая перепродажа',
  '📦 From the box / mesh (from 1 kg.)': '📦 От коробки / сетки (от 1 кг.)',
  '🏗️ From a pallet (from 500 kg.)': '🏗️ От паллета (от 500 кг.)',
  '🚚 From a truck (from 20 t.)': '🚚 От фуры (от 20 т.)',
  'from БAZA warehouse': 'со склада БAZA',
  'Which product categories are you interested in?': 'Какие категории продуктов вас интересуют?',
  Moscow: 'Москва',
  'Saint-Petersburg': 'Санкт-Петербург',
  'All Russia': 'Вся Россия',
  Reason: 'Причина',
  'Reject reason': 'Причина отклонения',
  Complete: 'Завершить',
  Kristina: 'Кристина',
  productModal: {
    order: 'Заказать',
  },
  // Меню навигации
  productCategory: {
    'Высший сорт': 'Высший сорт',
    'Первый сорт': 'Первый сорт',
    'Второй сорт': 'Второй сорт',
    Некондиция: 'Некондиция',
    'Без категории': 'Без категории',
  },
  productGroups: {
    Фрукты: 'Фрукты',
    Овощи: 'Овощи',
    Грибы: 'Грибы',
    Зелень: 'Зелень',
    Ягоды: 'Ягоды',
    Экзотика: 'Экзотика',
    Салаты: 'Салаты',
  },
  productPackings: {
    Ящик: 'Ящик',
    Коробка: 'Коробка',
    Мешок: 'Мешок',
    Сетка: 'Сетка',
    Стаканчик: 'Стаканчик',
    Пакет: 'Пакет',
    Лоток: 'Лоток',
    'Биг бэг': 'Биг бэг',
    Вакуум: 'Вакуум',
  },
  nav: {
    admin: {
      main: {
        home: 'Потребности',
        market: 'Рынок продуктов',
        applications: 'Мои продукты',
        deliveries: 'Поставки',
      },
      additional: {
        providers: 'Поставщики',
        finance: 'Мой кошелек',
        chat: 'Сообщения',
      },
    },
    seller: {
      main: {
        demands: 'Потребности',
        products: 'Мои продукты',
        deliveries: 'Поставки',
      },
      additional: {
        clients: 'Клиенты',
        finance: 'Мой кошелек',
        chat: 'Сообщения',
      },
    },
    buyer: {
      main: {
        market: 'Маркет',
        applications: 'Мои заявки',
        deliveries: 'Поставки',
      },
      additional: {
        providers: 'Поставщики',
        finance: 'Мой кошелек',
        chat: 'Сообщения',
      },
    },
    support: {
      main: { market: 'Маркет', demands: 'Потребности', clients: 'Клиенты', providers: 'Поставщики' },
      additional: { chat: 'Сообщения' },
    },
  },
  'shorts.kg': 'Кг',
  'shorts.pack': 'Уп',
  'shorts.piece': 'Шт',
  'shorts.liter': 'Л',
  'addapplication.title': 'Добавить заявку',
  'applications.title': 'Мои заявки',
  'cart.title': 'Корзина',
  'chat.title': 'Сообщения',
  'checkout.title': 'Оформление заказа',
  'client.title': 'Клиент',
  'clients.title': 'Покупатели',
  'deliveries.title': 'Поставки',
  'delivery.title': 'Поставка №{0}',
  'finance.title': 'Финансы',
  'login.title': 'Вход',
  'market.title': 'Маркет',
  'myproducts.title': 'Мои продукты',
  'pagenotfound.title': 'Страница не найдена',
  'profileedit.title': 'Редактировать профиль',
  'provider.title': 'Поставщик',
  'providers.title': 'Поставщики',
  'register.title': 'Регистрация',
  'standarts.title': 'Стандарты',
  'updatepassword.title': 'Сменить пароль',
  'demands.title': 'Потребности',
  'Search text...': 'Что ищете?',
  // 'This month': 'Текущий месяц',
  'Canceled deliveries': 'Отмененные поставки',
}
