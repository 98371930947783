<template>
  <svg
    class="logo"
    :class="{ 'logo--dynamic': changeColorOnMobile }"
    :width="width"
    :height="height"
    viewBox="0 0 100 22"
    preserveAspectRatio="xMidYMin"
    xmlns="http://www.w3.org/2000/svg"
  >
    <text y="20">{{ $siteName }}</text>
  </svg>
</template>

<script>
export default {
  name: 'Logo',
  props: {
    width: {
      type: Number,
      default: 95,
    },
    height: {
      type: Number,
      default: 22,
    },
    changeColorOnMobile: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/functions';
@import '@/assets/scss/config';
@import '@/assets/scss/mixins';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.logo {
  font-weight: 700;
  font-size: 30px;
  line-height: 1;
  fill: $GreenM;
  -webkit-transition: 0.3s ease all 0s;
  -o-transition: 0.3s ease all 0s;
  transition: 0.3s ease all 0s;

  &--dynamic {
    @include media-breakpoint-down(sm) {
      fill: $White;
    }
  }
}
</style>
