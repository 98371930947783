import { isProdClient } from '@/constants'

window.rrPartnerId = '63c12406c3020dd01ae4d19a'
window.rrApi = {}
window.rrApiOnReady = window.rrApiOnReady || []

export const RR = {
  /**
   * Инициализация интеграции с RetailRocket
   */
  init: () => {
    if (isProdClient) {
      try {
        window.rrApi.addToBasket =
          window.rrApi.order =
          window.rrApi.categoryView =
          window.rrApi.view =
          window.rrApi.recomMouseDown =
          window.rrApi.recomAddToCart =
            function () {}
        ;(function (d) {
          var ref = d.getElementsByTagName('script')[0]
          var apiJs,
            apiJsId = 'rrApi-jssdk'
          if (d.getElementById(apiJsId)) return
          apiJs = d.createElement('script')
          apiJs.id = apiJsId
          apiJs.async = true
          apiJs.src = '//cdn.retailrocket.ru/content/javascript/tracking.js'
          ref.parentNode.insertBefore(apiJs, ref)
        })(document)
      } catch (e) {
        console.error(e)
      }
    }
  },
  /**
   * Отправляет данные о просмотре категории/группы товара
   * @param {number} categoryId - ID категории
   */
  reportCategoryView: categoryId => {
    if (isProdClient) {
      ;(window['rrApiOnReady'] = window['rrApiOnReady'] || []).push(function () {
        try {
          window.rrApi.categoryView(categoryId)
        } catch (e) {}
      })
    }
  },
  /**
   * Отправляет данные о просмотре конкретного товара
   * @param {number} productId - ID товара
   */
  addProductViewTracker: productId => {
    if (isProdClient) {
      ;(window['rrApiOnReady'] = window['rrApiOnReady'] || []).push(function () {
        try {
          window.rrApi.view(productId)
        } catch (e) {}
      })
    }
  },
  /**
   * Отправляет данные о добавлении товара в корзину
   * @param {number} productId - ID товара
   */
  reportAddToCart: productId => {
    if (isProdClient) {
      try {
        window.rrApi.addToBasket(productId)
      } catch (e) {}
    }
  },
  /**
   * Отправляет данные о созданном заказе
   * @param {number} orderId - ID заказа
   * @param {Array.<{id: Number, qnt: Number, price: Number}>} items - Информация о товарах
   */
  reportCreatedOrder: (orderId, items) => {
    if (isProdClient) {
      ;(window['rrApiOnReady'] = window['rrApiOnReady'] || []).push(function () {
        try {
          window.rrApi.order({
            transaction: orderId,
            items,
          })
        } catch (e) {}
      })
    }
  },
  /**
   * Отправляет базовые данные о пользователе для персонализации
   * @param {string} email - Email
   * @param {string} name - Имя
   */
  setUserInfo: (email, name) => {
    if (isProdClient) {
      ;(window['rrApiOnReady'] = window['rrApiOnReady'] || []).push(function () {
        window.rrApi.setEmail(email, { name })
      })
    }
  },
}
