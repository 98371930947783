<template>
  <div class="action-notification">
    <header class="action-notification__header">
      <p class="action-notification__title">
        {{ $t(title) }}
      </p>

      <button v-if="dismissable" type="button" class="action-notification__close" @click="$emit('dismiss')">
        <img :src="require('../../assets/images/icons/x-mark.svg')" :alt="$t('Dismiss')" />
      </button>
    </header>

    <p class="action-notification__text">
      {{ $t(description) }}
    </p>

    <b-button
      variant="success"
      size="sm"
      type="button"
      class="action-notification__button"
      @click="$emit('action-click')"
    >
      {{ $t(actionButtonText) }}
    </b-button>
  </div>
</template>

<script>
export default {
  name: 'NotificationsItemWithAction',
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: false,
      default: '',
    },
    actionButtonText: {
      type: String,
      required: true,
    },
    dismissable: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/functions';
@import '@/assets/scss/config';
@import '@/assets/scss/mixins';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.action-notification {
  margin: 8px 0;
  padding: 12px;
  background-color: $White;
  border: 1px solid $LGray3;
  border-radius: 6px;
  display: grid;
  grid-auto-rows: auto;
  gap: 8px;

  &__header {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 8px;
  }

  &__close {
    color: inherit;
    width: 20px;
    height: 20px;
    border-radius: 4px;
    background-color: $Grey10;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    transition: background-color $transition;
    color: inherit;

    > img {
      display: block;
      width: 16px;
      height: 16px;
      object-fit: contain;
    }

    &:hover {
      background-color: $LGray3;
    }
  }

  &__title {
    font-weight: 600;
  }

  &__text {
    color: $DGray;
    font-size: 15px;
  }

  &__button {
    padding: 4px 8px;
    border-radius: 6px;
    min-width: 80px;
    justify-self: flex-end;
    font-size: 15px;
  }
}
</style>
