import { getField } from 'vuex-map-fields'

export const getters = {
  getField,
  orderBuyers(state) {
    return state.orders.map(order => order.buyer)
  },
  orderSellers(state) {
    return state.orders.map(order => order.seller)
  },
}
