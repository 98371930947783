<template>
  <svg viewBox="0 0 100 100" width="24" height="24">
    <circle :cx="50" :cy="50" :r="50" :fill="fillColor" />
    <text x="50%" y="50%" fill="#fff" :font-size="60" dominant-baseline="central" text-anchor="middle">
      {{ firstLetter.toUpperCase() }}
    </text>
  </svg>
</template>

<script>
import { hsv2rgb, rgb2hex } from '@/helpers/colors'

export default {
  name: 'PlaceholdermageGenerator',
  props: {
    imageName: {
      type: String,
    },
  },
  computed: {
    firstLetter() {
      return String(this.imageName).charAt(0)
    },
    fillColor() {
      const rgb = hsv2rgb((String(this.imageName).charCodeAt(0) % 24) * 15, 1, 0.8)
      //console.log(rgb)
      return rgb2hex(rgb)
    },
  },
}
</script>
