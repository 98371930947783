import { getField } from 'vuex-map-fields'
import { FACE_TYPES, USER_TYPE } from '@/constants'

export const getters = {
  getField,
  isSeller: state => state.currentUser.user_type === USER_TYPE.SELLER,
  isBuyer: state => state.currentUser.user_type === USER_TYPE.BUYER,
  isExporter: state => state.currentUser.user_type === USER_TYPE.EXPORTER,
  isSupportAgent: state => state.currentUser.user_type === USER_TYPE.SUPPORT_AGENT,
  isSystemSeller: state => state.currentUser.profile?.is_system_seller,
  isCompany: state => state.currentUser.profile?.face_type === FACE_TYPES.company,
  isIndividual: state => state.currentUser?.profile?.face_type === FACE_TYPES.individual,
  currentCurrency: state => {
    if (state.currentUser?.user_type === 3) {
      return state.currentUser.profile?.organization.currency || null
    }
    return 'RUB'
  },
  currentUserLanguage: state => state.currentUser.language,
}
