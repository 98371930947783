// Хранилище для хранения настроек и прочей хуеты
import Vue from 'vue'

function _fixValue(params) {
  params = Object.assign({}, params)
  if (params.value != null && typeof params.value !== 'string') {
    params.value = JSON.stringify(params.value)
  }
  return params
}

function _parseValue(value) {
  // Раскомментировать, когда в остальных местах будет убран JSON.parse
  // try {
  //   return JSON.parse(value)
  // } catch (e) {
  return value
  // }
}

export default {
  namespaced: true,
  state: {
    storageItems: {},
  },
  actions: {
    async createOrUpdate({ commit }, params) {
      params = _fixValue(params)
      const res = await Vue.$http.post('/storage/', params)
      commit('SET_STORAGE_ITEM', res)
      return res
    },
    async read({ commit }, key) {
      try {
        const res = await Vue.$http.get(`/storage/${key}/`)
        res.value = _parseValue(res.value)
        commit('SET_STORAGE_ITEM', res)
        return res.value
      } catch (e) {
        if (e.status_code === 404) {
          return null
        }
        throw e
      }
    },
    async readAll({ commit }, params = {}) {
      // Всегда возвращает последние N записей. Нет там пагинации
      // Если записей > N, то более старые будут потерты
      // В данный момент N=200
      const data = await Vue.$http.get('/storage/', { params })
      // [{key: 'foo', value: 'bar'}, {key: 'baz', value: 42}]
      // => {foo: 'bar', baz: 42}
      const res = Object.fromEntries(data.map(item => [item.key, _parseValue(item.value)]))
      commit('SET_STORAGE_ITEMS', res)
      return res
    },
    async update({ commit }, { key, ...params }) {
      params = _fixValue(params)
      const res = await Vue.$http.put(`/storage/${key}/`, params)
      commit('REMOVE_STORAGE_ITEM', key)
      commit('SET_STORAGE_ITEM', res)
      return res
    },
    async delete({ commit }, key) {
      await Vue.$http.delete(`/storage/${key}/`)
      commit('REMOVE_STORAGE_ITEM', key)
    },
  },
  mutations: {
    SET_STORAGE_ITEMS(state, items) {
      state.storageItems = items
    },
    SET_STORAGE_ITEM(state, { key, value }) {
      state.storageItems[key] = value
    },
    REMOVE_STORAGE_ITEM(state, key) {
      delete state.storageItems[key]
    },
  },
}
