import { updateField } from 'vuex-map-fields'

export const mutations = {
  setCurrentUser: (state, user) => (state.currentUser = user),
  setDocuments: (state, documents) => (state.documents = documents),
  updateField,
  changeLanguage(state, payload) {
    state.language = payload
  },
}
