<template>
  <div class="site__wrap site__wrap--auth">
    <header class="site__header header lock-padding">
      <div class="header__home">
        <div class="header__container container-fluid">
          <nav class="navbar navbar-expand-md">
            <div class="navbar-brand">
              <a href="/" class="navbar-brand__link">
                {{ $siteName }}
              </a>
            </div>
          </nav>
          <LanguageSelect class="header__language" @change="onLanguageChange" />
        </div>
      </div>
    </header>
    <main class="site__main fill-height">
      <section class="auth firstscreen base">
        <div class="base__container container">
          <div class="base__content">
            <slot />
          </div>
        </div>
      </section>
    </main>
    <footer class="site__footer footer footer--auth">
      <div class="footer__bottom">
        <div class="container-fluid">
          <div class="d-flex justify-content-between align-items-center">
            <div class="footer__bottom-left">
              <div class="footer__privacy">&copy; {{ new Date().getFullYear() }} baza.store. Все права защищены</div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import LanguageSelect from '../common/LanguageSelect.vue'

export default {
  components: { LanguageSelect },
  methods: {
    ...mapMutations('user', ['changeLanguage']),
    async getCountries() {
      await this.$store.dispatch('misc/loadCountries')
    },
    onLanguageChange(lang) {
      this.getCountries()
      this.changeLanguage(lang)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/functions';
@import '@/assets/scss/config';
@import '@/assets/scss/mixins';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.header {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 50;
  transition: 0.3s ease box-shadow 0s;

  &__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 100px;

    @include media-breakpoint-down(md) {
      padding: 0 15px;
    }
  }

  .navbar {
    z-index: 2;
    padding: 0;
    height: $size-header-height + 15px;

    @include media-breakpoint-down(sm) {
      height: $size-header-height-mobile + 15px;
    }

    .nav-link {
      padding: 5px;
      font-weight: 500;
      font-size: 16px;
      line-height: 1.5;
      color: $Black;
      white-space: nowrap;
      transition: 0.3s ease all 0s;
      position: relative;

      @include media-breakpoint-up(lg) {
        &:hover {
          color: rgba($color: $Black, $alpha: 0.75);
        }
      }

      @include media-breakpoint-down(xl) {
        font-size: 14px;
      }

      &::before {
        content: '';
        display: block;
        width: 100%;
        height: 2px;
        background-color: $GreenM;
        position: absolute;
        bottom: 0;
        left: 0;
        transform: translate(0, 22px) scale(0);
        transition: 0.3s ease all 0s;

        @include media-breakpoint-down(xl) {
          transform: translate(0, 23px) scale(0);
        }
      }

      &.active {
        &::before {
          transform: translate(0, 22px) scale(1);

          @include media-breakpoint-down(xl) {
            transform: translate(0, 23px) scale(1);
          }
        }
      }
    }
  }

  .navbar-brand {
    display: flex;
    align-items: center;

    @include media-breakpoint-down(xs) {
      padding: 0;
    }

    &__link {
      font-weight: 700;
      font-size: 38px;
      line-height: 1;
      color: $GreenM;
      transition: 0.3s ease all 0s;
      text-decoration: none;

      @include media-breakpoint-up(lg) {
        &:hover {
          color: rgba($color: $GreenM, $alpha: 0.75);
        }
      }
    }

    &__text {
      font-weight: 400;
      color: $DGray;
      font-size: 14px;
      line-height: 17px;

      @include media-breakpoint-down(xl) {
        font-size: calc(12px + 2 * ((100vw - #{$layoutMobile}px) / (#{$layoutDesktop} - #{$layoutMobile})));
        line-height: calc(17 / 14);
      }
    }
  }
}

.firstscreen {
  @include adaptiv-value('padding-top', 136, 30, 1);
  @include adaptiv-value('padding-bottom', 115, 30, 1);
  @include adaptiv-value('margin-bottom', -417, 0, 1);
  // overflow-x: hidden;

  // @include media-breakpoint-down(sm) {
  //   overflow: hidden;
  // }

  @media screen and (min-width: 2560px) {
    margin-bottom: -100px;
  }

  @media screen and (min-width: 2200px) {
    margin-bottom: -200px;
  }

  @media screen and (min-width: 1600px) {
    @include adaptiv-value('margin-bottom', -300, 0, 1);
  }

  @include media-breakpoint-up(xl) {
    @include adaptiv-value('margin-bottom', -350, 0, 1);
  }

  &__row {
    display: flex;

    @include media-breakpoint-down(xs) {
      display: block;
      margin: 0 -15px;
    }
  }

  &__content {
  }

  &__image {
    flex: 0 0 100%;

    @include adaptiv-value('margin-top', -53, 0, 0);

    @include media-breakpoint-down(xs) {
      margin: 150px 0 0;
      transform: scale(1.6);
      transform-origin: 0;
    }

    @include media-breakpoint-down(xs) {
      margin-top: 120px;
    }

    @include media-breakpoint-down(xs) {
      margin-top: 100px;
    }

    svg {
      display: block;
      max-width: 100%;
      height: auto;
    }
  }
}

.base {
  @include adaptiv-value('padding-bottom', 33, 15, 1);

  padding-top: 50px;

  @include media-breakpoint-up(md) {
    padding-top: 16px;
  }

  &__container {
    max-width: 100%;

    @include media-breakpoint-up(md) {
      padding-left: 24px;
    }
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.auth {
  margin: 0 !important;
  height: 100%;
  width: 100%;

  .firstscreen__content {
    @include media-breakpoint-down(sm) {
      flex: 0 0 100%;
    }

    @include media-breakpoint-down(xs) {
      margin-bottom: 0;
    }
  }
  &__image {
    @include media-breakpoint-up(md) {
      display: grid;
      align-items: center;
    }

    @include media-breakpoint-down(sm) {
      display: none;
    }

    img {
      width: 100%;
      height: auto;
    }
  }
}

.site {
  &__wrap {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }

  &__main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: $size-header-height 0px 0px 0px;
    background-color: $LGray4;

    @include media-breakpoint-down(sm) {
      padding: $size-header-height-mobile 0px 0px 0px;
    }
  }

  &__footer {
    margin-top: auto;
  }
}

.footer {
  background-color: $GreenM;
  color: $White;
  position: relative;
  z-index: 2;

  &__bottom {
    @include adaptiv-value('padding-top', 38, 20, 1);
    @include adaptiv-value('padding-bottom', 38, 20, 1);
    font-size: 16px;
    line-height: 18px;
  }

  &__bottom-left {
    padding-right: 15px;
    display: flex;
    font-size: 14px;
    line-height: 18px;

    @include media-breakpoint-down(xs) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__privacy {
    @include adaptiv-value('padding-right', 40, 20, 1);

    @include media-breakpoint-down(xs) {
      margin-bottom: 15px;
    }
  }

  &--auth {
    background-color: $LGray4;
    color: $Gray;

    .popup-over__title {
      color: $Gray;

      &::before {
        border-color: $Gray;
      }
    }

    .kan-logo {
      display: none;
    }
  }
}
</style>
