<template>
  <Transition v-if="show" name="fadeInOut">
    <div class="floating-action-request">
      <header class="floating-action-request__header">
        <p class="floating-action-request__title">
          {{ $t(title) }}
        </p>

        <button type="button" class="floating-action-request__close" @click="handleDismiss">
          <img :src="require('@/assets/images/icons/x-mark.svg')" :alt="$t('Close')" />
        </button>
      </header>

      <div class="floating-action-request__content">
        <p class="floating-action-request__text">
          {{ $t(description) }}
        </p>
      </div>

      <footer class="floating-action-request__footer">
        <b-button class="floating-action-request__action-button" variant="success" @click="handleAction">{{
          $t(actionButtonText)
        }}</b-button>
      </footer>
    </div>
  </Transition>
</template>

<script>
import { eventBus } from '@/helpers'

export default {
  name: 'FloatingActionRequest',
  data: () => ({
    show: false,
    title: '',
    description: '',
    actionButtonText: '',
    callback: null,
    dismissCallback: null,
  }),
  mounted() {
    eventBus.$on('showFloatingActionRequest', evt => {
      this.title = evt.title
      this.description = evt.description
      this.actionButtonText = evt.actionButtonText
      this.callback = evt.callback
      this.dismissCallback = evt.dismissCallback
      this.show = true
    })
  },
  methods: {
    resetState() {
      this.show = false
      this.title = ''
      this.description = ''
      this.actionButtonText = ''
      this.callback = null
      this.dismissCallback = null
    },
    handleDismiss() {
      if (this.dismissCallback) {
        this.dismissCallback()
      }
      this.resetState()
    },
    handleAction() {
      if (this.callback) {
        this.callback()
      }
      this.resetState()
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/functions';
@import '@/assets/scss/config';
@import '@/assets/scss/mixins';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import '@/assets/scss/transitions.scss';

.floating-action-request {
  position: fixed;
  bottom: 20px;
  left: 16px;
  border-radius: 10px;
  background-color: $White;
  padding: 16px;
  z-index: 1000;
  width: 90%;
  max-width: 400px;
  box-shadow: 0px 10px 32px rgba(0, 0, 0, 0.06);
  font-family: $fontSecondary, $fontMain, sans-serif;
  display: grid;
  grid-auto-rows: auto;
  gap: 12px;

  &__header {
    display: grid;
    grid-template-columns: 1fr 30px;
    gap: 12px;
  }

  &__text {
    font-family: $fontMain, sans-serif;
    font-size: 16px;
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
  }

  &__close {
    width: 28px;
    height: 28px;
    border-radius: 4px;
    background-color: $Grey10;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    transition: background-color $transition;
    color: inherit;

    > img {
      display: block;
      width: 24px;
      height: 24px;
      object-fit: contain;
    }

    &:hover {
      background-color: $LGray3;
    }
  }

  &__action-button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    line-height: 130%;
    font-size: 14px;
    padding: 8px 10px;
    margin-top: 8px;
  }
}
</style>
