import { API_URL } from '@/constants'
import store from '@/store'
import axios from 'axios'
import { eventBus } from '@/helpers/eventBus'
import i18n from '@/i18n'

export const http = axios.create({
  baseURL: API_URL,
})

http.interceptors.request.use(config => {
  config = { addAccessToken: true, ...config }

  if (config.showLoadingBar) {
    eventBus.$emit('showLoadingBar', true)
  }

  const { headers } = config

  headers['Accept-Language'] =
    headers['Accept-Language'] || store.state.user.currentUser.language || i18n.locale || navigator.language

  if (store.getters['auth/loggedIn']) {
    if (config.addAccessToken) {
      headers.Authorization = store.state.auth.token.access.authorizationHeader
    }
  }
  return config
}, Promise.reject)

http.interceptors.response.use(
  response => {
    if (response.config.showLoadingBar) {
      eventBus.$emit('showLoadingBar', false)
    }

    if (response.data.error) {
      return Promise.reject(response.data.error)
    }

    return response.data
  },
  error => {
    console.error(error)
    eventBus.$emit('showLoadingBar', false)
    return Promise.reject(error.response.data)
  },
)

export default http
