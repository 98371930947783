import Vue from 'vue'

export const actions = {
  async loadCountries({ commit }, params = {}) {
    const result = await Vue.$http.get('/misc/countries/', { params, addAccessToken: false, showLoadingBar: false })
    commit('setCountries', result)
  },
  async loadCities({ commit, state }, params = {}) {
    const haveParams = Object.keys(params).length > 0

    // Проверка чтобы не грузить ~2.5 сек города везде.
    // Города могут быть загружены с фильтрацией из-за чего просто проверка длины не подходит,
    // поэтому ставит соответствующий флаг (в большинстве мест загрузки - параметры не передаются)
    if (!state.cities.length || haveParams || state.citiesLoadedWithParams) {
      const result = await Vue.$http.get('/misc/cities/', { params, addAccessToken: false })
      commit(
        'setCities',
        result.map(item => {
          return { ...item, name: item.name.replace('г. ', '') }
        }),
      )
      commit('setCitiesLoadedWithParams', haveParams)
    }
  },
  async loadOrganizationTypes({ commit }, params = {}) {
    const result = await Vue.$http.get('/misc/organization_types/', {
      params,
      addAccessToken: false,
    })
    commit('setOrganizationTypes', result)
  },
  async loadCurrencies({ commit }, params = {}) {
    const result = await Vue.$http.get('/misc/currencies/', {
      params,
      addAccessToken: false,
      showLoadingBar: false,
    })
    commit('setCurrencies', result)
  },

  async loadExchangeRate({ commit }, id) {
    const result = await Vue.$http.get(`/misc/currencies/get_currency/${id}/`)
    commit('setCurrentRubleExchangeRate', result.rate)
  },

  async getHints(context, { hintType, ...params }) {
    const result = await Vue.$http.get(`/misc/hints/${hintType}`, { params })
    return result
  },

  async loadWeightUnits({ commit }) {
    const result = await Vue.$http.get('/misc/weight_units/')
    commit('setWeightUnits', result)
  },
}
