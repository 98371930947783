export class EventEmitter {
  #events = null
  constructor() {
    this.#events = new Map()
  }
  on(event, listener) {
    let listeners = this.#events.get(event)
    if (undefined === listeners) this.#events.set(event, (listeners = new Set()))
    listeners.add(listener)
  }
  emit(event, ...args) {
    const listeners = this.#events.get(event)
    if (listeners) {
      for (let listener of listeners) {
        listener(...args)
      }
    }
  }
  off() {
    throw new Error('not implemented yet')
  }
}

export default EventEmitter
