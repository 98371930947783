<template>
  <div class="sidebar-accordion">
    <AccordionFilterItem
      v-if="filterItemsToShow.includes('orderPartners')"
      :title="isBuyer ? $t('Seller') : $t('Buyer')"
      :items="preparedOrderOrganizations"
      :select-items-handler="setSelectedOrderPartners"
    >
      <template v-slot="{ item }">
        <span v-if="item">
          {{ item.short_title }}
        </span>
      </template>
    </AccordionFilterItem>

    <AccordionFilterItem
      v-if="filterItemsToShow.includes('financePartners')"
      :title="isBuyer ? $t('Seller') : $t('Buyer')"
      :items="preparedFinanceOrganizations"
      :select-items-handler="setSelectedFinancePartners"
    >
      <template v-slot="{ item }">
        <span v-if="item">
          {{ item.full_name }}
        </span>
      </template>
    </AccordionFilterItem>

    <AccordionFilterItem
      v-if="filterItemsToShow.includes('orderStatuses')"
      title="Статус"
      :items="orderStatuses"
      :select-items-handler="setSelectedOrderStatuses"
      :check-selected="(item) => orderStatusesSelected.includes(item)"
    >
      <template v-slot="{ item }">
        <span v-if="item">
          {{ $t(`status.order.${item}`) }}
        </span>
      </template>
    </AccordionFilterItem>

    <AccordionFilterItem
      v-if="filterItemsToShow.includes('financeStatuses')"
      title="Статус"
      :items="financeStatuses"
      :select-items-handler="setSelectedFinanceStatuses"
      :check-selected="(item) => financeStatusesSelected.includes(item)"
    >
      <template v-slot="{ item }">
        <span v-if="item">
          {{ $t(`status.payment.${item.toLowerCase()}`) }}
        </span>
      </template>
    </AccordionFilterItem>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import AccordionFilterItem from './AccordionFilterItem'

export default {
  name: 'AccordionFilter',

  components: {
    AccordionFilterItem,
  },

  computed: {
    ...mapGetters('user', ['isBuyer']),
    ...mapState('order', ['orderStatuses']),
    ...mapState('finance', ['financeStatuses']),
    ...mapGetters('filtration', [
      'fixedOrderPartners',
      'fixedFinancePartners',
      'orderStatusesSelected',
      'financeStatusesSelected',
    ]),

    filterItemsToShow() {
      return this.$route.meta.filterItemsToShow
    },

    preparedOrderOrganizations() {
      return this.filterUniqueOrganizations(this.getOrganizations(this.fixedOrderPartners))
    },

    preparedFinanceOrganizations() {
      return this.filterUniqueOrganizations(this.getOrganizations(this.fixedFinancePartners))
    },
  },

  methods: {
    ...mapActions('filtration', [
      'setOrderPartnersSelected',
      'setOrderStatusesSelected',
      'setFinancePartnersSelected',
      'setFinanceStatusesSelected',
    ]),

    getOrganizations(partners) {
      return partners.map(p => p.organization)
    },

    // В ордерах фильтрует по buyer/seller.user_id
    getOrganizationUserId(org, partners) {
      const currentPartner = partners.find(partner => partner.organization.id === org.id)
      return currentPartner.user_id
    },

    // В финансах фильтрует по buyer/seller.id
    getOrganizationAgentId(org, partners) {
      const currentPartner = partners.find(partner => partner.organization.id === org.id)
      return currentPartner.id
    },

    getNamedOrganizations(orgs) {
      return orgs.filter(org => org.short_title || org.full_name)
    },

    setSelectedOrderPartners(orgs) {
      const partnerIds = orgs.map(org => this.getOrganizationUserId(org, this.fixedOrderPartners))
      this.setOrderPartnersSelected(partnerIds)
    },

    setSelectedOrderStatuses(statuses) {
      this.setOrderStatusesSelected(statuses)
    },

    setSelectedFinancePartners(orgs) {
      const partnerIds = orgs.map(org => this.getOrganizationAgentId(org, this.fixedFinancePartners))
      this.setFinancePartnersSelected(partnerIds)
    },

    setSelectedFinanceStatuses(statuses) {
      this.setFinanceStatusesSelected(statuses)
    },

    filterUniqueOrganizations(orgs) {
      const uniqOrgs = orgs.reduce((total, org) => {
        if (total[org.id]) return total
        total[org.id] = org
        return total
      }, {})

      return Object.values(uniqOrgs)
    },
  },
}
</script>

<style lang="scss" scoped></style>
