import Vue from 'vue'

export const actions = {
  async listProductGroups({ commit }, params = {}) {
    const { withAdditional, ...rest } = params

    const result = await Vue.$http.get('/product/groups/', { params: rest, showLoadingBar: false })
    let filteredGroups = result

    if (!withAdditional) {
      filteredGroups = result.filter(g => !g.additional)
    }

    commit('setGroups', filteredGroups)
  },
  async listProducts({ commit }, params = {}) {
    const result = await Vue.$http.get('/product/products/', params)

    // console.log('listProducts', result)
    commit('setProducts', result.results)
  },
  async listProductsSorts({ commit }, params = {}) {
    const result = await Vue.$http.get('/product/sorts/', { params, showLoadingBar: false })
    // console.log('listProductsSorts', result)
    commit('setSorts', result)
  },
  async listProductsCategories({ commit }, params = {}) {
    const result = await Vue.$http.get('/product/categories/', { params, showLoadingBar: false })
    // console.log('listProductsCategories', result)
    commit('setCategories', result)
  },
  async listProductsPackings({ commit }, params = {}) {
    const result = await Vue.$http.get('/product/packings/', { params, showLoadingBar: false })
    // console.log('listProductsPackings', result)
    commit('setPackings', result)
  },
  async listBrands({ commit }, params = {}) {
    const result = await Vue.$http.get('/product/brands/', { params, showLoadingBar: false })
    commit('setBrands', result)
  },
}
