<template>
  <picture :title="name" class="product-group-image-wrapper">
    <img v-if="image" class="product-group-image" :src="image" :alt="name" />
    <PlaceHolderImageGenerator v-else :image-name="name" />
  </picture>
</template>

<script>
import { API_URL } from '@/constants'
import PlaceHolderImageGenerator from '@/components/common/PlaceHolderImageGenerator.vue'
export default {
  name: 'ProductImage',
  components: {
    PlaceHolderImageGenerator,
  },
  props: {
    name: String,
    imageLink: String,
  },
  computed: {
    image() {
      if (this.imageLink) {
        return this.imageLink
      }

      let image
      const imageName = `${this.name?.toLowerCase().replace(' ', '-')}`

      try {
        image = require(`@/assets/images/icons/product/${imageName}.png`)
      } catch (e) {
        console.warn(`File not found ${imageName}`)
      }

      return image ? image : ''
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/config';

.product-group-image-wrapper {
  display: block;
  height: $base-grid * 3;
  width: $base-grid * 3;
}

.product-group-image {
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  text-align: center;
  height: inherit;
  width: inherit;
  border-radius: 100px;
  font-size: 10px;
  color: white;
}
</style>
