import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    orderDeals: [],
    currentPage: 1,
    totalPages: 1,
    status: 'idle',
  },
  actions: {
    async listOrderDeals({ commit }, params = {}) {
      commit('SET_STATUS', 'loading')

      try {
        const result = await Vue.$http.get('/tochka/order-deals', { params })
        commit('SET_ORDER_DEALS', result.results)
        commit('SET_TOTAL_PAGES', result.total_pages)
        return result
      } finally {
        commit('SET_STATUS', 'idle')
      }
    },
  },
  mutations: {
    SET_ORDER_DEALS: (state, deals) => (state.orderDeals = deals),
    SET_TOTAL_PAGES: (state, pages) => (state.totalPages = pages),
    SET_STATUS: (state, newStatus) => (state.status = newStatus),
  },
}
