export default {
  attention: {
    common: {
      transit_eligible_alert_text: 'Для того, чтобы совершить сделку, вам необходимо {0}',
      transit_eligible_alert_link_text: 'заключить договор с оператором',
    },
    forSeller: {
      new: 'Вы можете внести изменения в стоимость и количество или подтвердить текущий заказ.',
      seller_update:
        'Вы внесли изменения в заказ и отправили покупателю. Ожидайте действий покупателя. Покупатель примет изменения или аннулирует заказ.',
      confirm: 'Заказ был подтвержден. Ожидается комплектация заказа.',
      waiting_operator_confirm:
        'Заказ ожидает подтверждения оператора. После подтверждения, вы можете приступать к сборке.',
      operator_confirm: 'Ваш заказ был подтвержден со стороны оператора. Можете приступать к сборке.',
      operator_update:
        'Внесены изменения со стороны оператора. Если вы согласны с изменениями, нажмите кнопку «Согласен». Изменения считаются подтвержденными только после согласия поставщика и покупателя.',
      seller_confirm_operator_update:
        'Вы подтвердили свое согласие на изменения со стороны оператора. После согласия покупателя, товар отправится из РЦ.',
      sent_from_dc:
        'Товары отправлены покупателю из распределительного центра Базы. Покупатель скоро подтвердит получение.',
      on_way:
        'Товар в пути в распределительный центр Базы. После получения товара, оператор подтвердит или внесёт изменения в поставку.',
      buyer_confirm_update:
        'Покупатель принял ваши изменения. Теперь заказ ожидает подтверждения оператора. После подтверждения, можете уведомить покупателя о сборке.',
      collected: 'Товар собран. Нажмите «Отправить», когда товар будет отгружен.',
      report:
        'Покупатель не готов принять весь товар. В данное время оператор рассматривает претензию покупателя. Детально изучить отчет приёмки покупателя вы можете после рассмотрения оператором.',
      adjustment_by_admin:
        'Оператор рассмотрел претензию покупателя. Вы можете ознакомиться с отчетом приемки ниже. Сейчас вы можете оформить возрат товаров или поставить их на реализацию.',
      put_on_realization:
        'Ваша сделка завершена. Не забудьте оценить сотрудничество с покупателем, этим вы помогаете сервису становиться лучше.',
      return_registration:
        'Ваша сделка завершена. Не забудьте оценить сотрудничество с покупателем, этим вы помогаете сервису становиться лучше.',
      complete:
        'Вы успешно завершили сделку. Не забудьте оценить сотрудничество с покупателем, этим вы помогаете сервису становиться лучше',
      hold:
        'Покупатель не согласен с вашими корректировками. Для завершения поставки необходимо урегулирование разногласий в соответствии с договором.',
      waiting_filled_truck:
        'Транспорт отправлен к вам. Как будет завершена погрузка прикрепите товаро-сопроводительные документы и нажмите «Отправить». Внесите изменения в заказ в соответствии с фактически отгруженным объемом товара.',
      evaluated: 'Ваша сделка завершена. Мы благодарим вас за оценку покупателя.',
      report_created: 'Не забудьте оценить сотрудничество с поставщиком, этим вы помогаете сервису становиться лучше.',
      report_with_zero_discrepancies:
        'Не забудьте оценить сотрудничество с поставщиком, этим вы помогаете сервису становиться лучше.',
    },
    forBuyer: {
      new: 'Вы можете отменить заказ прежде чем поставщик подтвердит готовность к исполнению.',
      waiting_operator_confirm:
        'Заказ ожидает подтверждения оператора. После подтверждения, поставщик сможет приступать к сборке.',
      on_way:
        'Товар в пути в распределительный центр Базы. После получения товара, оператор подтвердит или внесёт изменения в поставку.',
      operator_update:
        'Оператор произвел изменения к заказу. Чтобы продолжить, требуется ваше согласие. После подтверждения заказа со стороны поставщика, у вас появится кнопка «Согласен». Нажмите на нее, если вы также согласны с изменениями.',
      seller_update:
        'Поставщик изменил состав заказа и стоимость. Вы можете ознакомиться с предлагаемой поставкой и подтвердить кликом по кнопке «Согласен» или отказаться от заказа',
      buyer_confirm_update:
        'Поставщик уведомлен о вашем согласии. Когда товар будет укомплектован, вы будете уведомлены о сборке.',
      seller_confirm_operator_update:
        'Оператор внес изменения в текущую поставку. Поставщик согласен с изменениями. Если вы также согласны, подтвердите кликом по кнопке «Согласен»',
      filled: 'Нажмите кнопку «Получено», если вы получили товар.',
      sent_from_dc:
        'Товары отправлены к вам из распределительного центра Базы. После получения товаров, нажмите кнопку «Доставлено»',
      deliver:
        'Если вы нашли деффекты в товарах и готовы принять только часть поставки, нажмите кнопку «Приемка» и внесите данные, которые не соответствуют заказу.',
      seller_correct:
        'Поставщик изменил состав заказа и стоимость. Вы можете ознакомиться с изменениями и завершить заказ кликом по кнопке «Завершить» или «Удержать» заказ если не согласны с изменениями.',
      rate_seller:
        'Вы успешно завершили сделку. Не забудьте оценить сотрудничество с поставщиком, этим вы помогаете сервису становиться лучше.',
      complete:
        'Вы успешно завершили сделку. Не забудьте оценить сотрудничество с поставщиком, этим вы помогаете сервису становиться лучше.',
      adjustment_by_admin:
        'Ваш заказ успешно поставлен, если у вас не осталось замечаний, вам необходимо завершить поставку.',
      put_on_realization:
        'Ваша сделка завершена. Не забудьте оценить сотрудничество с поставщиком, этим вы помогаете сервису становиться лучше.',
      return_registration:
        'Ваша сделка завершена. Не забудьте оценить сотрудничество с поставщиком, этим вы помогаете сервису становиться лучше.',
      report_with_zero_discrepancies:
        'Ваш заказ поставлен, если у вас не осталось замечаний, вам необходимо завершить поставку.',
      report:
        'Вы решили вернуть некоторые товары из заказа. Пока оператор рассматривает вашу претензию, вы можете посмотреть свой отчет.',
      waiting_truck: 'Заполните данные транспорта и водителя, после нажмите «Ожидаю погрузку»',
      // Not status, just to not display review request again
      evaluated: 'Ваша сделка завершена. Мы благодарим вас за оценку поставщика.',
    },
  },
  actionButtons: {
    request_cancelation: 'Аннулировать',
    cancel: 'Отменить',
    seller_update: 'Изменить',
    buyer_confirm_update: 'Согласен',
    waiting_operator_confirm: 'Подтвердить',
    submit: 'Подтвердить',
    confirm: 'Подтвердить',
    collected: 'Уведомить о сборке',
    on_way: 'Отправить в РЦ',
    seller_confirm_operator_update: 'Согласен',
    buyer_confirm_operator_update: 'Согласен',
    sent_from_dc: 'Согласен',
    filled: 'Отправить',
    waiting_truck: 'Ожидаю транспорт',
    waiting_filled_truck: 'Ожидаю погрузку',
    deliver: 'Получено',
    send: 'Отправить',
    seller_correct: 'Исправлено',
    hold: 'Удержать',
    estimate: 'Оценить',
    seeReport: 'Показать отчет приёмки', // TODO Remove on adding table
    report: 'Приемка',
    put_on_realization: 'Поставить на реализацию',
    return_registration: 'Оформить возврат',
    complete: 'Завершить',
  },
  actionButtonsMessages: {
    'Provide documents': 'Предоставьте <a href="#documents" class="link">документы</a> для поставки. ',
    'Provide truck details': 'Предоставьте информацию о <a href="#truck" class="link">грузе</a>. ',
  },
  orderTypes: [
    {
      id: 1,
      name: 'Заявка',
    },
    {
      id: 2,
      name: 'Торги',
    },
  ],
}
