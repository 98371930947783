<template>
  <div v-if="showFilters" class="sidebar" :style="sidebarStyle">
    <transition name="sidebar__animated">
      <div v-if="!sidebarOpened" class="sidebar__toggler">
        <div class="sidebar__account">
          <router-link
            :is="isSupportAgent ? 'span' : 'router-link'"
            class="sidebar__account-avatar"
            :to="{ name: 'profileIndex' }"
          >
            <img :src="userThumbnail" :alt="currentUser.name" />
          </router-link>
        </div>

        <div class="sidebar__burger" @click="setSidebarOpened(true)">
          <svg width="21" height="19" viewBox="0 0 21 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect
              x="0.5"
              y="18.75"
              width="2"
              height="10"
              transform="rotate(-90 0.5 18.75)"
              fill="white"
              fill-opacity="0.2"
            />
            <rect
              x="0.5"
              y="10.75"
              width="2"
              height="20"
              transform="rotate(-90 0.5 10.75)"
              fill="white"
              fill-opacity="0.2"
            />
            <rect
              x="0.5"
              y="2.75"
              width="2"
              height="14"
              transform="rotate(-90 0.5 2.75)"
              fill="white"
              fill-opacity="0.2"
            />
          </svg>
        </div>

        <div class="sidebar__filter-result">
          {{ $t('Filter') }}
        </div>

        <a class="sidebar__filter-reset" @click.prevent="clearAllFilters">{{ $t('Reset') }}</a>
      </div>
    </transition>
    <transition name="sidebar__animated">
      <div v-if="sidebarOpened" class="sidebar__content">
        <AccountBox
          class="sidebar__content-account"
          :thumb="userThumbnail"
          :name="currentUser?.name || $t('Guest')"
          :role="currentUser.kind"
          :disable-link="isSupportAgent || !loggedIn"
        />
        <button class="sidebar__hide-button" @click.prevent="setSidebarOpened(false)">
          <img class="edit-product__close-icon" src="@/assets/images/icons/close.svg" alt="" />
        </button>

        <div class="sidebar__filters-list">
          <component
            :is="component"
            v-for="(component, i) in $route.meta.sidebarComponents"
            :key="i"
            :exclude-modal-triggers="$route.meta.excludeModalTriggers"
            class="sidebar__filter"
          />

          <button v-if="$route.meta.showClearFiltersButton" class="sidebar__clear-filters" @click="clearAllFilters">
            {{ $t('Clear all filters') }}
          </button>
        </div>

        <button v-if="loggedIn" type="button" class="sidebar__logout" @click="logout">
          <i class="icon-exit mr-1" />{{ $t('Log out') }}
        </button>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { mapGetters } from 'vuex'
import { mapActions } from 'vuex'
import globalFiltersFuncs from '@/mixins/globalFiltersFuncs'
import inResponsiveSizes from '@/helpers/filters/inResponsiveSizes'
import AccountBox from '@/components/common/AccountBox.vue'
import CategoriesFilter from '@/components/filters/sidebar/Categories.vue'
import GroupsFilter from '@/components/filters/sidebar/Groups.vue'
import ModalTriggerList from '@/components/filters/sidebar/ModalTriggerList.vue'
import AccordionFilter from '@/components/filters/sidebar/AccordionFilter.vue'
import StatusesFilter from '@/components/filters/sidebar/StatusesFilter.vue'

import { resolveAssetUrl } from '@/helpers/url'

export default {
  components: {
    AccountBox,
    GroupsFilter,
    CategoriesFilter,
    ModalTriggerList,
    AccordionFilter,
    StatusesFilter,
  },
  mixins: [globalFiltersFuncs],
  computed: {
    ...mapState('user', ['currentUser']),
    ...mapGetters(['screenSize', 'responsiveSize', 'sidebarOpened']),
    ...mapGetters('user', ['isSupportAgent']),
    ...mapGetters('auth', ['loggedIn']),
    userThumbnail() {
      return this.currentUser.photo_small
        ? resolveAssetUrl(this.currentUser.photo_small)
        : require('@/assets/images/icons/nophoto.png') || require('@/assets/images/icons/nophoto.png')
    },
    sidebarStyle() {
      return {
        width: !this.inResponsiveSizes(['xs', 'sm']) ? (this.sidebarOpened ? '250px' : '65px') : 'auto',
        height: this.inResponsiveSizes(['xs', 'sm']) ? (this.sidebarOpened ? '100%' : '50px') : 'auto',
      }
    },
    showFilters() {
      return (
        !this.inResponsiveSizes(['xs', 'sm']) ||
        (this.inResponsiveSizes(['xs', 'sm']) &&
          this.$route.meta.sidebarComponents &&
          this.$route.meta.sidebarComponents.length !== 0)
      )
    },
  },
  methods: {
    ...mapActions(['setSidebarOpened']),
    ...mapActions('filtration', ['clearAllFilters']),
    inResponsiveSizes,
  },
}
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/functions';
@import '@/assets/scss/config';
@import '@/assets/scss/mixins';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.sidebar {
  background-color: $DGray2;
  color: $White;
  position: relative;
  transition: $popoutTransition ease all 0s;

  // Elements
  .sidebar__toggler {
    display: flex;
    align-items: center;
    height: 50px;
    @include media-breakpoint-down(sm) {
      padding: 0 20px;
    }
    @include media-breakpoint-up(md) {
      flex-direction: column;
      align-items: stretch;
      width: 65px;
      height: 100%;
    }
    // Elements
    .sidebar__account {
      display: none;
      @include media-breakpoint-up(md) {
        display: inline-flex;
        width: 65px;
        height: 65px;
        align-items: center;
        justify-content: center;
      }
    }
    .sidebar__account-avatar {
      display: inline-flex;
      height: 34px;
      width: 34px;
      border-radius: 50%;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .sidebar__burger {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      @include media-breakpoint-up(md) {
        flex-grow: 1;
      }
    }

    .sidebar__filter-result {
      flex-grow: 1;
      @include media-breakpoint-down(sm) {
        margin: 0 16px;
      }
      @include media-breakpoint-up(md) {
        display: none;
      }
    }
    .sidebar__filter-reset {
      cursor: pointer;
      @include media-breakpoint-up(md) {
        display: none;
      }
    }
  }

  .sidebar__content {
    display: grid;
    grid-template-columns: 1fr 40px;
    grid-template-rows: min-content minmax(100px, 1fr) 40px;
    grid-template-areas: 'user hide-button' 'filters filters' 'logout logout';
    grid-row-gap: $base-grid * 2.5;
    padding: $base-grid * 2.5;
    max-height: 100vh;

    @include media-breakpoint-up(md) {
      height: 100vh;
      grid-row-gap: $base-grid * 4;
    }
  }

  // Content account
  .sidebar__content-account {
    grid-area: user;
  }

  .sidebar__hide-button {
    grid-area: hide-button;
    border: none;
    background-color: transparent;
    height: 40px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s;

    &:hover {
      background-color: rgba(white, 0.01);
    }
  }

  // Filters
  .sidebar__filters-list {
    grid-area: filters;
    align-self: start;
    display: grid;
    grid-gap: $base-grid * 3;
    overflow-y: auto;
    max-height: 100%;
  }

  .sidebar__logout {
    grid-area: logout;
    display: flex;
    align-items: center;
    color: white;
    border: none;
    background-color: rgba(white, 0.03);
    border-radius: 4px;
    padding: $base-grid;
  }

  .sidebar__animated {
    // States
    &-enter-active,
    &-leave-active {
      transition: $popoutTransition ease all 0s;
    }
    &-enter-active {
      position: relative;
      z-index: 10;
    }
    &-leave-active {
      position: absolute;
      top: 0;
    }
    &-enter,
    &-leave-to {
      opacity: 0;
      transform: translateY(100%);
      @include media-breakpoint-up(md) {
        transform: translateX(-100%);
      }
    }
  }

  &__clear-filters {
    color: $White;
    background-color: transparent;
    border: none;
    cursor: pointer;
    text-decoration: underline;
    display: inline-block;
    justify-self: flex-start;
    padding: 0;
    margin: 0;
    font-size: 15px;

    &:hover {
      opacity: 0.8;
    }
  }
}
</style>
