export const state = {
  sellers: [],
  buyers: [],
  buyer: {},
  seller: {},
  totalPages: 1,
  currentPage: 1,
  availableWarehouses: [],
  bazaWarehouses: [],
  systemSellers: [],
  kindsOfActivity: [],
  subscriptionPlans: [],
}
