<template>
  <component
    :is="$options.COMPONENT_TYPES[notification.data_type] || $options.COMPONENT_TYPES.default"
    v-bind="notificationAttrs"
    class="notification"
    :class="{ 'notification--chat': isChatType }"
  >
    <b-avatar
      v-if="isChatType"
      :src="avatarSource"
      :alt="notificationTitle"
      :size="30"
      variant="light"
      class="notification__avatar"
    />

    <div class="notification__title">
      {{ notificationTitle }}
    </div>
    <div class="notification__message">
      {{ notificationMessage }}
    </div>
    <b-badge v-if="isChatType" variant="success" pill class="notification__messages-count">
      {{ messagesCount }}
    </b-badge>
  </component>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'NotificationsItem',
  NOTIFICATION_TYPES: {
    chat: 'chat',
    order: 'order',
    demandSuggestion: 'demand_suggestion',
  },
  COMPONENT_TYPES: {
    chat: 'router-link',
    order: 'router-link',
    demand_suggestion: 'router-link',
    default: 'div',
  },
  i18n: {
    messages: {
      ru: {
        demand_suggestion: 'Предложение в заявке',
        payment: 'Оплата',
        order: 'Заказ',
      },
      en: {
        demand_suggestion: 'Suggestion in demand',
        payment: 'Payment',
        order: 'Заказ',
      },
    },
  },
  props: {
    notification: {
      type: Object,
      required: true,
    },
    messagesCount: {
      type: [Number, null],
      required: false,
    },
    orderMessage: {
      type: String,
      required: false,
    },
  },
  computed: {
    ...mapState('chat', ['chats']),
    isChatType() {
      return this.notification.data_type === this.$options.NOTIFICATION_TYPES.chat
    },
    notificationAttrs() {
      const notificationLinkParams = {
        chat: {
          to: {
            name: 'chatUser',
            params: {
              userId: this.notification.data.id,
            },
          },
        },
        [this.$options.NOTIFICATION_TYPES.order]: {
          to: {
            name: 'delivery',
            params: {
              deliveryId: this.notification.data.order,
            },
          },
        },
        [this.$options.NOTIFICATION_TYPES.demandSuggestion]: {
          to: {
            name: 'application',
            params: {
              applicationId: this.notification.data.id,
            },
          },
        },
      }

      return notificationLinkParams[this.notification.data_type] || {}
    },
    avatarSource() {
      const senderAvatar = this.chats.find(chat => chat.sender.id === this.notification.data.id)?.sender.photo_small
      return senderAvatar ? senderAvatar : require('@/assets/images/icons/nophoto.png')
    },
    notificationTitle() {
      if (this.isChatType) {
        return this.chats.find(chat => chat.sender.id === this.notification.data.id)?.sender.name || ''
      }
      return this.$t(this.notification.data_type)
    },
    notificationMessage() {
      if (this.isChatType) {
        return this.chats.find(chat => chat.sender.id === this.notification.data.id)?.message || ''
      }
      return this.notification.message
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/functions';
@import '@/assets/scss/config';
@import '@/assets/scss/mixins';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.notification {
  display: block;
  border-bottom: 1px solid $LGray5;
  padding: 10px 4px;
  text-decoration: none;
  color: inherit;
  font-size: 14px;
  line-height: 1.4;
  border-bottom: 1px solid $LGray5;

  &:is(a) {
    transition: $transition background-color;

    &:hover {
      background-color: darken($White, 1.5);
    }
  }

  &--chat {
    display: grid;
    grid-template-columns: min-content 1fr min-content;
    column-gap: $base-grid;
  }

  &__title {
    font-weight: 600;
    grid-column: 2 / 3;
  }

  &__message {
    color: $DGray;
    grid-column: 2 / 3;
    word-break: break-word;
  }

  &__avatar {
    grid-column: 1 / 2;
    grid-row: span 2;
  }

  &__messages-count {
    grid-column: 3 / 4;
    grid-row-start: 1;
    align-self: flex-start;
    padding: 3px 5px;
  }
}
</style>
