<template>
  <div
    class="product-category"
    :style="{
      backgroundColor: category.color,
    }"
  >
    <span class="product-category__name">{{ $t(`productCategory.${category.name}`) }}</span>
    <span v-if="category.icon" class="product-category__icon">
      <i :class="`icon-${category.icon}`" />
    </span>
  </div>
</template>

<script>
export default {
  props: {
    id: Number,
  },
  data() {
    return {
      categories: [
        {
          id: 0,
          name: 'Без категории',
          color: '#',
          icon: null,
        },
        {
          id: 1,
          name: 'Первый сорт',
          color: '#e1f7a5',
          icon: '1',
        },
        {
          id: 2,
          name: 'Высший сорт',
          color: '#bcfcb1',
          icon: 'up',
        },
        {
          id: 3,
          name: 'Второй сорт',
          color: '#bcfcb1',
          icon: '2',
        },
        {
          id: 4,
          name: 'Некондиция',
          color: '#f9dabe',
          icon: 'down',
        },
      ],
    }
  },
  computed: {
    category() {
      // TODO Change for fallback
      return this.categories.find(item => item.id == this.id) || {}
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/functions';
@import '@/assets/scss/config';
@import '@/assets/scss/mixins';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.product-category {
  display: inline-flex;
  align-items: center;
  padding: 4px 5px;
  border-radius: 2px;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: $DGray;
  display: inline-flex;

  // Elements
  .product-category__name {
    font-size: 12px;
    line-height: 1;
    white-space: nowrap;
  }

  .product-category__icon {
    font-size: 10px;
    margin-left: 4px;
    line-height: 1;
  }
}
</style>
