import Vue from 'vue'
import VueI18n from 'vue-i18n'
import ru from './locales/ru'
import en from './locales/en'

Vue.use(VueI18n)

export const i18n = new VueI18n({
  // используем по-умолчанию язык, используемый браузером
  // язык пользователя применяется к хуке для роутера
  locale: navigator.language.split('-')[0],
  fallbackLocale: 'en',
  // Если в локади нет ключа, то вместо сообщения используется отфармотированный ключ
  formatFallbackMessages: true,
  silentFallbackWarn: true,
  messages: {
    en,
    ru,
  },
  // copy-pasted from https://kazupon.github.io/vue-i18n/guide/pluralization.html#custom-pluralization
  pluralizationRules: {
    ru(choice, choicesLength) {
      if (choice === 0) {
        return 0
      }

      const teen = choice > 10 && choice < 20
      const endsWithOne = choice % 10 === 1

      if (choicesLength < 4) {
        return !teen && endsWithOne ? 1 : 2
      }
      if (!teen && endsWithOne) {
        return 1
      }
      if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
        return 2
      }

      return choicesLength < 4 ? 2 : 3
    },
  },
})

export { i18n as default }
