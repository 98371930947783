<template>
  <div v-click-outside="close" class="notifications">
    <div class="notifications__header">
      <span>{{ $t('Notifications') }}</span>
      <button v-if="notifications.length" class="notifications__mark-read" @click="markAsRead()">
        {{ $t('Mark all as read') }}
      </button>
      <button v-else class="notifications__close" @click="close()">
        <img src="@/assets/images/icons/close.svg" class="notifications__close-icon" />
      </button>
    </div>
    <div class="notifications__list">
      <template v-if="notifications.length || notificationsWithAction.length">
        <NotificationsItemWithAction
          v-for="item in notificationsWithAction"
          :key="item.title"
          :title="item.title"
          :description="item.description"
          :dismissable="item.dismissable"
          :action-button-text="item.actionButtonText"
          @action-click="item.callback"
        />

        <NotificationsItem
          v-for="(notification, key) in preparedNotifications"
          :key="key"
          class="notifications__item"
          :notification="notification"
          :messages-count="notification.count ? notification.count : null"
        />
      </template>
      <div v-else class="notifications__message">
        {{ $t('There are no new notifications.') }}
      </div>
    </div>
  </div>
</template>

<script>
import { WSNotifier } from '@/helpers/ws'
import { mapActions, mapState } from 'vuex'
import { eventBus } from '@/helpers/eventBus'
import NotificationsItem from '@/components/common/NotificationsItem.vue'
import NotificationsItemWithAction from './NotificationsItemWithAction.vue'

export default {
  name: 'Notifications',
  components: {
    NotificationsItem,
    NotificationsItemWithAction,
  },
  data() {
    return {
      notifier: null,
      notifications: [],
      isCleared: false,
    }
  },
  computed: {
    ...mapState('chat', ['newChatMessages', 'chats']),
    ...mapState('notification', ['notificationsWithAction']),
    preparedNotifications() {
      const uniqChatNotificatons = this.notifications.reduce((total, notification) => {
        if (notification.data_type === 'chat') {
          notification = { ...notification, count: 1 }
          if (total[notification.data.id]) {
            total[notification.data.id].count++
            return total
          }
          total[notification.data.id] = notification
        } else {
          total[notification.id] = notification
        }
        return total
      }, {})

      return Object.values(uniqChatNotificatons)
    },
  },
  watch: {
    notifications() {
      this.$emit('setUnreadNotificationsMarker', !!this.notifications.length || this.notificationsWithAction.length)
    },
  },
  async created() {
    try {
      await this.$store.dispatch('chat/listChats')
    } catch (e) {
      console.error(e)
    }
  },
  mounted() {
    const connect2Notifications = () => {
      this.notifier = new WSNotifier()
      this.notifier.on('error', () => {
        this.notifier.disconnect()
        setTimeout(connect2Notifications, 30_000)
      })
      this.notifier.on('message', async message => {
        switch (message.type) {
          case 'get_notifications':
            this.notifications = [...message.notifications]

            try {
              await this.setNewChatMessages(this.notifications.filter(n => n.data_type === 'chat'))
            } catch (e) {
              console.error(e)
            }
            break
          // Нельзя просто так взять и отдавать данные в одном формате
          // {"type": "send_data", "notification_id": "68d4af72baed4c2c920afb647fac3d11", "message": "You have a new message", "data": {"id": 46}, "data_type": "chat", "read": false, "timestamp": "1618226810.671928", "global": false}
          case 'send_data':
            if (message.data_type === 'chat') {
              if (!this.isUserInCurrentChat(message.data.id)) {
                try {
                  await this.setNewChatMessages([...this.newChatMessages, message])
                  await this.listChats()
                } catch (e) {
                  console.error(e)
                }
                this.notifications.push(message)
                let audio = new Audio(require('@/assets/notification.mp3'))
                audio.play()
              } else {
                this.setNotificationAsRead(message)
              }
            } else {
              this.notifications.push(message)
            }
            break

          default:
            throw new Error(`Unknown message type ${message.type}`)
        }
      })
    }
    connect2Notifications()

    eventBus.$on('read_messages', messages => {
      messages.forEach(message => {
        if (this.notifications.find(n => n.id === message.id)) {
          this.setNotificationAsRead(message)
        }
      })
    })
  },
  methods: {
    ...mapActions('chat', ['setNewChatMessages', 'listChats']),
    close() {
      this.$emit('close')
    },
    markAsRead() {
      if (this.notifications.length) {
        this.notifications.forEach(notification => {
          this.setNotificationAsRead(notification)
        })
        this.isCleared = true
      }
    },
    setNotificationAsRead(notification) {
      this.notifier.send({
        notification_type: 'read_notification',
        notification_id: notification.id || notification.notification_id,
      })
    },
    isUserInCurrentChat(id) {
      return this.$route.path === `/chat/${id}`
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/functions';
@import '@/assets/scss/config';
@import '@/assets/scss/mixins';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import '@/assets/scss/silence/scroll-bar-decorative.scss';

.notifications {
  position: absolute;
  z-index: 4;
  top: 65px;
  right: -50px;
  width: 335px;
  height: auto;
  max-height: 300px;
  padding: 15px;
  background: $White;
  box-shadow: 5px 10px 20px #00000033;
  border-radius: 4px;
  overflow: hidden;

  @include media-breakpoint-down(sm) {
    top: 50px;
  }

  &__item:last-child {
    border-bottom: none;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;

    span {
      font-weight: 600;
      font-size: 17px;
    }
  }

  &__mark-read {
    font-size: 14px;
    border: none;
    border-bottom: 1px dashed;
    background: transparent;
    padding: 0;
    line-height: 0;

    &:hover {
      color: $Gray2;
    }
  }

  &__close {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 22px;
    height: 22px;
    border-radius: 4px;
    border: none;
    background-color: $LGray3;
    transition: background-color $transition;

    &:hover {
      background-color: darken($LGray3, 2);
    }
  }

  &__close-icon {
    width: 10px;
    height: 10px;
  }

  &__list {
    $notifications-header-height: 33px;
    $notifications-padding-y: 15px;
    height: auto;
    max-height: 300px - $notifications-header-height - ($notifications-padding-y * 2);
    overflow-y: auto;
    padding-right: 6px;
    margin-right: -6px;
    @extend %scroll-bar-decorative-small;
  }

  &__message {
    padding-top: 10px;
    text-align: center;
    color: $DGray;
  }
}
</style>
