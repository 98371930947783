<template>
  <ul class="filter-modals">
    <li v-if="!excludeModalTriggers.includes('product')" class="filter-modals__item" @click="openFiltersModal(0)">
      <div class="filter-modals__name">
        <span>{{ $t('Product') }}</span>
        <sup v-if="productsSelectedLength !== 0" class="filter-modals__quantity">{{ productsSelectedLength }}</sup>
      </div>
    </li>
    <li v-if="!excludeModalTriggers.includes('grade')" class="filter-modals__item" @click="openFiltersModal(1)">
      <div class="filter-modals__name">
        <span>{{ $t('Grade') }}</span>
        <sup v-if="sortsSelectedLength !== 0" class="filter-modals__quantity">{{ sortsSelectedLength }}</sup>
      </div>
    </li>
    <li v-if="!excludeModalTriggers.includes('country')" class="filter-modals__item" @click="openFiltersModal(2)">
      <div class="filter-modals__name">
        <span>{{ $t('Country') }}</span>
        <sup v-if="countriesSelectedLength !== 0" class="filter-modals__quantity">{{ countriesSelectedLength }}</sup>
      </div>
    </li>
    <li v-if="!excludeModalTriggers.includes('location')" class="filter-modals__item" @click="openFiltersModal(3)">
      <div class="filter-modals__name">
        <span>{{ $t('Location') }}</span>
        <sup v-if="citiesSelectedLength !== 0" class="filter-modals__quantity">{{ citiesSelectedLength }}</sup>
      </div>
    </li>
  </ul>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ModalTriggerList',
  props: {
    excludeModalTriggers: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  computed: {
    ...mapGetters(['goodsFiltersActiveTab']),
    ...mapGetters('filtration', [
      'productsSelectedLength',
      'sortsSelectedLength',
      'countriesSelectedLength',
      'citiesSelectedLength',
    ]),
  },
  methods: {
    ...mapActions(['setGoodsFiltersOpened', 'setGoodsFiltersActiveTab', 'setSidebarOpened']),
    openFiltersModal(activeTab) {
      this.setSidebarOpened(false)
      this.setGoodsFiltersActiveTab(activeTab)
      this.setGoodsFiltersOpened(true)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/functions';
@import '@/assets/scss/config';
@import '@/assets/scss/mixins';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.filter-modals {
  padding: 0;
  margin: 0;
  list-style: none;

  // Elements
  .filter-modals__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    cursor: pointer;

    &::after {
      content: '';
      display: block;
      width: 14px;
      height: 15px;
      background: url('~@/assets/images/icons/chevron-right.svg') no-repeat center/cover;
    }
  }
  .filter-modals__name {
    display: flex;
    align-items: center;
    padding-right: 15px;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #fff;
    transition: $transition ease all 0s;
  }
  .filter-modals__quantity {
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: #363636;
    border-radius: 1px;
    position: relative;
    margin-left: 9px;
    padding: 0 2px;
    transition: $transition ease all 0s;
  }
}
</style>
