import Vue from 'vue'

export const actions = {
  async listDemands({ state, commit }, params = {}) {
    const result = await Vue.$http.get(`/demand/demands/`, { params })

    commit('setDemands', result.results)

    if (state.demandStatuses.length !== result.statuses) {
      commit('setDemandStatuses', result.statuses)
    }

    if (state.totalPages !== result.total_pages) {
      commit('setTotalPages', result.total_pages)
      commit('setCurrentPage', 1)
    }
  },
  async getDemand({ commit }, { id, ...params }) {
    const result = await Vue.$http.get(`/demand/demands/${id}/`, { params })
    // console.log('getDemand', result);
    commit('setDemand', result)
  },
  async createDemand(context, params) {
    const result = await Vue.$http.post('/demand/demands/', params)
    console.log('createDemand', result)
    return result
  },
  async deleteDemand(context, { id, ...params }) {
    await Vue.$http.delete(`/demand/demands/${id}/`, params)
  },
  changePage({ commit }, page) {
    commit('setCurrentPage', page)
  },
}
