import { WS_URL } from '@/constants'
import Store from '@/store'
import { EventEmitter } from './eventEmitter'

class WSClient extends EventEmitter {
  #endpoint = null
  #socket = null
  constructor(endpoint) {
    super()
    this.#endpoint = endpoint
    this.connect()
  }
  get endpoint() {
    return this.#endpoint
  }
  get url() {
    return `${WS_URL}/${this.#endpoint}`
  }
  connect() {
    console.log(`try to connect to ${this.url}`)
    if (null === Store.state.auth.token) {
      setTimeout(() => this.emit('error', new Error('no access token')), 0)
      return
    }
    this.#socket = new WebSocket(this.url, ['Token', Store.state.auth.token.access])
    this.addSocketCallbacks()
  }
  disconnect() {
    if (null === this.#socket) return
    this.#socket.close()
    this.#socket = null
  }
  addSocketCallbacks() {
    this.#socket.onopen = this.onOpen.bind(this)
    this.#socket.onmessage = this.onMessage.bind(this)
    this.#socket.onerror = this.onError.bind(this)
    this.#socket.onclose = this.onClose.bind(this)
  }
  onOpen(ev) {
    this.emit('open', ev)
  }
  onMessage(ev) {
    this.emit('message', JSON.parse(ev.data))
  }
  onError(ev) {
    this.emit('error', ev)
  }
  onClose(ev) {
    this.emit('close', ev)
  }
  send(data) {
    console.log(data)
    this.#socket.send(JSON.stringify(data))
  }
}

export class WSChatClient extends WSClient {
  constructor(recipientId) {
    super(`chat/${recipientId}/`)
    // TODO: вынести
    this.on('message', data => {
      if ('messages' in data) {
        this.emit('messages', data.messages)
      } else if ('event' in data) {
        const message = { ...data }
        delete message.event
        this.emit(data.event, message)
      } else {
        console.error(data)
      }
    })
  }
  send(message) {
    super.send({ type: 'send_message', message })
  }
}

export class WSNotifier extends WSClient {
  constructor() {
    super('notification/')
  }
}
