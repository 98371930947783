export const transformOrderData = order => {
  const { total_product_weight, delivery_price, delivery_price_included, products } = order

  const addedDeliveryPriceForProduct = delivery_price_included ? delivery_price / total_product_weight : 0

  const productsWithAddedDeliveryPerUnit = products.map(p => {
    // Если wt_unit.weight null значит единица веса шт/уп/... если же вес есть значит кг/л/...
    // В случае с упаковками, штуками итд идет умножение доставки за кг на общий вес штуки/упаковки
    const price = p.price + addedDeliveryPriceForProduct * (p.wt_unit.weight ?? p.one_item_weight)
    const net_price = price * p.weight

    return {
      ...p,
      price,
      net_price,
      initialPrice: price,
      initialNetPrice: net_price,
      initialWeight: p.weight,
      priceWithoutAddedDelivery: p.price,
    }
  })

  return {
    ...order,
    products: productsWithAddedDeliveryPerUnit,
    deliveryPrice: delivery_price,
  }
}
