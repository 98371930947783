<template>
  <div id="app">
    <vue-topprogress
      ref="topProgress"
      :color="inResponsiveSizes(['xs', 'sm']) ? '#ffcc4a' : '#1d9f3a'"
      color-shadow
      :height="3"
    ></vue-topprogress>
    <Notification />
    <router-view />
    <GlobalModal />
    <FloatingActionRequest />
  </div>
</template>

<script>
import { toRepresent } from '@/helpers'
import inResponsiveSizes from '@/helpers/filters/inResponsiveSizes'
import { eventBus } from '@/helpers/eventBus'
import { mapActions, mapGetters, mapState } from 'vuex'
import GlobalModal from '@/components/modals/GlobalModal.vue'
import Notification from '@/components/common/Notification.vue'
import FloatingActionRequest from './components/common/FloatingActionRequest.vue'
import { inviteUserToSurvey } from './funcs/userPreferencesSurvey'
import { createOrUpdateUserInfoInGoogleSheet } from './funcs/googleSheet'
import { RR } from './plugins/retailRocket'

export default {
  name: 'App',
  components: {
    GlobalModal,
    Notification,
    FloatingActionRequest,
  },
  computed: {
    ...mapState('auth', ['token']),
    ...mapState('user', ['currentUser']),
    ...mapGetters('user', ['isBuyer']),
  },
  watch: {
    'currentUser.id': {
      handler() {
        if (this.currentUser.id) {
          try {
            createOrUpdateUserInfoInGoogleSheet(this.currentUser)
            inviteUserToSurvey()

            if (this.isBuyer && !!this.currentUser.email) {
              RR.setUserInfo(this.currentUser.email, this.currentUser.name)
            }
          } catch (e) {
            console.error(e)
          }
        }
      },
    },
  },
  async created() {
    this.setScreenSize(this.getRealSize())
    window.addEventListener('resize', () => {
      this.setScreenSize(this.getRealSize())
    })

    // Нужно дождаться пока не выполнится первая проверка
    await this.$store.dispatch('auth/refreshToken')
    // Эти юниты нужны везде так что грузим их при старте приложения
    await this.$store.dispatch('misc/loadWeightUnits')
  },
  mounted() {
    eventBus.$on('showLoadingBar', v => {
      v ? this.$refs.topProgress.start() : this.$refs.topProgress.done()
    })

    let that = this
    eventBus.$on('error', e => {
      const errorText = toRepresent(e.message || e)

      this.$notif({
        success: false,
        title: that.$t('Something went wrong'),
        body: that.$te(errorText) ? that.$t(errorText) : errorText,
      })
    })
  },
  methods: {
    ...mapActions(['setScreenSize']),
    getRealSize() {
      let height = window.innerHeight
      let width = window.innerWidth
      return [width, height]
    },
    inResponsiveSizes,
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/index';
</style>
