// см. https://api.baza.store/docs/
import { Token } from '@/helpers/jwt'
import Vue from 'vue'

// TODO: вынести куда-нибудь
const REFRESH_DELAY = 30000

export const actions = {
  async login({ commit }, params) {
    let token = await Vue.$http.post('/auth/login/', params, {
      addAccessToken: false,
    })
    commit('setToken', new Token(token))
  },
  async register(context, params) {
    const result = await Vue.$http.post('/auth/register/', params, {
      addAccessToken: false,
    })

    return result
  },
  async refreshToken({ commit, state }) {
    const refreshTask = async () => {
      const { token } = state
      if (null === token) {
        console.log('no token')
        return
      }
      if (token.refresh.isExpired) {
        console.log('refresh token is expired')
        return
      }
      if (token.access.exp * 1e3 - REFRESH_DELAY > Date.now()) {
        console.log('token has not expired or will not expire soon')
        return
      }
      console.log('refresh token')
      // Вроде как токен нужно в заголовке передавать, но в джанге все не как у людей
      let result = await Vue.$http.post(
        '/auth/refresh/',
        { refresh: token.refresh },
        {
          addAccessToken: false,
        },
      )
      token.updateFromResponse(result)
      commit('setToken', token)
    }
    setInterval(refreshTask, REFRESH_DELAY)
    return await refreshTask()
  },
  async forgotPassword(context, payload) {
    return await Vue.$http.post('/auth/forgot-password/', payload)
  },
  async resetPassword(context, payload) {
    return Vue.$http.post('/auth/reset-password/', payload)
  },
  async sendVerificationCode(context, payload) {
    await Vue.$http.post('/auth/verification/send/', payload)
  },
  async checkVerificationCode(context, payload) {
    await Vue.$http.post('/auth/verification/check/', payload)
  },
  async loadCurrentUser({ commit }) {
    const user = await Vue.$http.get('/auth/me/')
    commit('setCurrentUser', user)
  },
  async setPassword(context, params) {
    await Vue.$http.put(`/auth/set_password/`, params)
  },
  async updatePassword({ commit }, params) {
    // Зачем пользователя-то возвращать?
    const user = await Vue.$http.put(`/auth/change-password/`, params)
    commit('setCurrentUser', user)
  },
  async getVerificationCode(context, params) {
    const result = await Vue.$http.post('/auth/verification/send/', params)
    console.log(result)
  },
  async checkVerificationCode(context, params) {
    const result = await Vue.$http.post('/auth/verification/check/', params)
    return result
  },
  async sendConfirmationCode(context, params) {
    const result = await Vue.$http.post('/auth/confirmation-code/send/', params)
    console.log(result)
  },

  async checkConfirmationCode(context, params) {
    const result = await Vue.$http.post('/auth/confirmation-code/check/', params)
    console.log(result)
  },
  logout({ commit }) {
    commit('user/setCurrentUser', {}, { root: true })
    commit('logout')
  },
}
