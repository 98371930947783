// TODO: импортировать и экспортировать все
import eventBus from './eventBus'
import * as jwt from './jwt'
import * as validation from './validation'
import * as errors from './errors'

export { eventBus, validation, jwt, errors }

export const isInt = val => {
  return Number(val) === val && val % 1 === 0
}

// [null, void 0, 0, false, '', [], {}].map(x => x == null)
// (7) [true, true, false, false, false, false, false]
// Use obj == null instead
export const isNullOrUndefined = val => val === null || val === undefined

export const hasOwn = (obj, key) => obj != null && Object.prototype.hasOwnProperty.call(obj, key)

export const isType = (type, value) =>
  Object.prototype.toString
    .call(value)
    .slice(8, -1)
    .toLowerCase() === type.toLowerCase()

export const isPlainObject = o => isType('Object', o)

export const isEmpty = x =>
  (Array.isArray(x) && x.length === 0) || (isPlainObject(x) && Object.keys(x).length === 0) || !x

export const isIterable = object => object != null && typeof object[Symbol.iterator] === 'function'

// костыль для vuex-map-fields?
export const replaceNull = v => (v === null ? '' : v)

export const clone = obj => JSON.parse(JSON.stringify(obj))

// TODO: реализовать, если потребуется
export const deepClone = obj => {
  throw new errors.NotImplementedError()
}

export const removeExtension = fileName => {
  const pos = fileName.lastIndexOf('.')
  return pos !== -1 ? fileName.slice(0, pos) : fileName
}

export const upperFirst = word => word.slice(0, 1).toUpperCase() + word.slice(1)

// TODO: переименовать
export const lazyLoadViews = routes =>
  routes.map(route => {
    // route = clone(route)
    if (hasOwn(route, 'componentName') && typeof route.componentName === 'string') {
      // присваивание свойству component содержит какую-то магию
      // так не будет работать:
      // route.component = () => import(`@/views/${route.component}`)
      route.component = () => import(`@/views/${route.componentName}`)
    }
    // sub-routes
    if (hasOwn(route, 'children') && Array.isArray(route.children)) {
      route.children = lazyLoadViews(route.children)
    }
    return route
  })

export const loadScript = src =>
  new Promise((resolve, reject) => {
    const script = document.createElement('script')
    script.src = src
    script.type = 'text/javascript'
    script.async = true
    script.onload = resolve
    script.onerror = reject
    document.head.appendChild(script)
  })

// arr.sort(revCmp)
export const revCmp = (a, b) => (a > b ? -1 : a < b ? 1 : 0)

// TODO: переписать
// export const formatPhoneNumber = number =>
//   String(number)
//     .replace(/\D+/g, '')
//     .replace(/^(\d+)(\d{3})(\d{3})(\d{2})(\d{2})$/, (_, a, b, c, d, e) => `+${a == 8 ? 7 : a} (${b}) ${c}-${d}-${e}`)

// code: token_not_valid; detail: Given token not valid for any token type; messages: ( message: Token is invalid or expired; token_class: AccessToken; token_type: access )
export const toRepresent = o => {
  if (Array.isArray(o)) return '[ ' + o.map(v => toRepresent(v)).join(' | ') + ' ]'
  if (Object.prototype.toString.call(o) === '[object Object]')
    return Object.entries(o)
      .map(v => `${v[0]}: ${toRepresent(v[1])}`)
      .join('; ')
  return String(o)
}

/**
 *
 * @param {*} number
 * @returns {string}
 */
export const normalizePhoneNumber = number => {
  number = String(number).replace(/\D+/g, '')
  if (number.charAt() === '8' && number.length === 11) {
    number = '7' + number.slice(1)
  }
  return '+' + number
}

export const removeEmpty = obj => {
  const newObj = {}
  Object.keys(obj).forEach(key => {
    if (!Array.isArray(obj[key]) && obj[key] === Object(obj[key])) {
      newObj[key] = removeEmpty(obj[key])
    } else if (obj[key] !== undefined) newObj[key] = obj[key]
  })
  return newObj
}

export const roundNumber = (number, decimalPlaces = 2) => {
  return Math.round((number + Number.EPSILON) * `1e${decimalPlaces}`) / `1e${decimalPlaces}`
}
