import { initialFiltrationState } from './state'

export const mutations = {
  changeProductCategoriesSelected(state, productCategoriesSelected) {
    state.productCategoriesSelected = productCategoriesSelected
  },
  changeProductGroupsSelected(state, productGroupsSelected) {
    state.productGroupsSelected = productGroupsSelected
  },
  changeProductsSelected(state, productsSelected) {
    state.productsSelected = productsSelected
  },
  changeSortsSelected(state, sortsSelected) {
    state.sortsSelected = sortsSelected
  },
  changeCountriesSelected(state, countriesSelected) {
    state.countriesSelected = countriesSelected
  },
  changeCitiesSelected(state, citiesSelected) {
    state.citiesSelected = citiesSelected
  },
  changeCitiesByCountries(state, { cities }) {
    state.citiesByCountries = cities
  },
  changeOrderPartnersSelected(state, orderPartnersSelected) {
    state.orderPartnersSelected = orderPartnersSelected
  },
  changeOrderStatusesSelected(state, orderStatusesSelected) {
    state.orderStatusesSelected = orderStatusesSelected
  },
  changeDemandsStatusesSelected(state, demandsStatusesSelected) {
    state.demandsStatusesSelected = demandsStatusesSelected
  },
  changeFinancePartnersSelected(state, financePartnersSelected) {
    state.financePartnersSelected = financePartnersSelected
  },
  changeFinanceStatusesSelected(state, financeStatusesSelected) {
    state.financeStatusesSelected = [...financeStatusesSelected]
  },
  changeFixedOrderPartners(state, orderPartners) {
    state.fixedOrderPartners = orderPartners
  },
  changeFixedFinancePartners(state, financePartners) {
    state.fixedFinancePartners = financePartners
  },
  changeWarehousesSelected(state, warehouses) {
    state.warehousesSelected = warehouses
  },
  resetAllFilters(state) {
    Object.keys(state).forEach(key => {
      state[key] = initialFiltrationState[key]
    })
  },
}
