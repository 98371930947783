import { eventBus } from '@/helpers/eventBus'
import i18n from '@/i18n'

export default {
  install(Vue) {
    Vue.prototype.$notif = function({ success = true, title, body }) {
      eventBus.$emit('notification', {
        isSuccess: success,
        title: title || i18n.t(`Notification`),
        body: body,
      })
    }
  },
}
