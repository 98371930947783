import { updateField } from 'vuex-map-fields'

export const mutations = {
  setBuyer: (state, profile) => (state.buyer = profile),
  setSeller: (state, profile) => (state.seller = profile),
  setSellers: (state, sellers) => (state.sellers = sellers),
  setBuyers: (state, buyers) => (state.buyers = buyers),
  updateField,
  setTotalPages: (state, totalPages) => (state.totalPages = totalPages),
  setCurrentPage: (state, currentPage) => (state.currentPage = currentPage),
  setAvailableWarehouses: (state, warehouses) => (state.availableWarehouses = warehouses),
  setBazaWarehouses: (state, warehouses) => (state.bazaWarehouses = warehouses),
  setSystemSellers: (state, sellers) => (state.systemSellers = sellers),
  setKindsOfActivity: (state, payload) => (state.kindsOfActivity = payload),
  setSubscriptionPlans: (state, payload) => (state.subscriptionPlans = payload),
}
