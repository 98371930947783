export const state = {
  personalAccount: {},
  financesList: [],
  financeDetail: {},
  transfers: [],
  currentPage: 1,
  financeTotalPages: 1,
  transferTotalPages: 1,
  financeStatuses: [],
}
