export default {
  namespaced: true,
  state: {
    notificationsWithAction: [],
  },
  actions: {
    setNotificationsWithAction({ commit }, payload) {
      commit('SET_NOTIFICATIONS_WITH_ACTION', payload)
    },
    addNotificationWithAction({ commit, state }, payload) {
      commit('SET_NOTIFICATIONS_WITH_ACTION', [payload, ...state.notificationsWithAction])
    },
  },
  mutations: {
    SET_NOTIFICATIONS_WITH_ACTION: (state, notifications) => {
      state.notificationsWithAction = notifications
    },
  },
}
