<template>
  <div class="sidebar-accordion__item">
    <h3
      class="sidebar-accordion__title"
      :class="{
        'sidebar-accordion__title--active': itemsShown,
        'sidebar-accordion__title--disabled': !items.length,
      }"
      @click="itemsShown = !itemsShown"
    >
      {{ title }}
      <div v-if="itemsSelectedLength" class="sidebar-accordion__quantity">
        {{ itemsSelectedLength }}
      </div>
    </h3>
    <div
      ref="items"
      class="sidebar-accordion__items"
      :class="{ 'sidebar-accordion__items--no-transition': dataChanged }"
    >
      <div class="sidebar-accordion__items-wrapper">
        <button
          v-for="(item, idx) in items"
          :key="(item && item.id) || item || idx"
          class="accordion-item__trigger"
          :class="{ 'accordion-item__trigger--active': checkInSelected(item) }"
          @click="setSelected(item)"
        >
          <slot :item="item" />
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'AccordionFilterItem',

  props: {
    title: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    selectItemsHandler: {
      type: Function,
      required: true,
    },
    checkSelected: {
      type: Function,
      required: false
    },
  },
  data() {
    return {
      itemsSelected: [],
      itemsShown: false,
      dataChanged: false,
    }
  },

  computed: {
    itemsSelectedLength() {
      return this.itemsSelected.length
    },
  },

  watch: {
    itemsShown() {
      const fullHeight = this.$refs.items.scrollHeight
      this.$refs.items.style.maxHeight = this.itemsShown ? `${fullHeight}px` : 0
    },
    items() {
      this.itemsSelected = []
      this.dataChanged = true
      this.itemsShown = false
      setTimeout(() => {
        this.dataChanged = false
      }, 300)
    },
  },

  methods: {
    checkInSelected(item) {
      if (this.checkSelected) {
        return this.checkSelected(item)
      }

      return this.itemsSelected.some(selectedItem => selectedItem === item)
    },

    setSelected(item) {
      if (!this.checkInSelected(item)) {
        this.itemsSelected.push(item)
      } else {
        this.itemsSelected = this.itemsSelected.filter(i => i !== item)
      }

      this.selectItemsHandler(this.itemsSelected)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/config';
@import '@/assets/scss/silence/scroll-bar-decorative';

.sidebar-accordion__item {
  padding: $base-grid * 2 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  &:last-child {
    padding: $base-grid * 2 0 0;
    border-bottom: none;
  }
}

.sidebar-accordion__title {
  position: relative;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  cursor: pointer;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    width: $base-grid;
    height: $base-grid * 0.25;
    background-color: $White;
    transition: $transition transform;
  }

  &::before {
    right: 0;
    transform: translateY(-50%) rotate(-45deg);
  }

  &::after {
    right: 4px;
    transform: translateY(-50%) rotate(45deg);
  }

  &--active {
    &::before {
      transform: translateY(-50%) rotate(45deg);
    }

    &::after {
      transform: translateY(-50%) rotate(-45deg);
    }
  }

  &--disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}

.sidebar-accordion__quantity {
  display: inline-block;
  margin-left: $base-grid;
  padding: 0 $base-grid * 0.25;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  background-color: $White;
  color: $GreenM;
}

.sidebar-accordion__items {
  padding: 0;
  max-height: 0;
  overflow: hidden;
  transition: $transition max-height;

  &--no-transition {
    transition: none;
  }
}

.sidebar-accordion__items-wrapper {
  @extend %scroll-bar-decorative;
  max-height: 300px;
  overflow-y: auto;
}

.accordion-item__trigger {
  display: block;
  margin-left: $base-grid * 1.5;
  cursor: pointer;
  font-size: 14px;
  line-height: 18px;
  transition: $transition color;
  background-color: transparent;
  border: none;
  color: inherit;
  text-align: left;

  &--active {
    color: $GreenM;
  }

  &:first-child {
    margin-top: $base-grid * 2.5;
  }

  &:not(:last-child) {
    margin-bottom: $base-grid;
  }
}
</style>
