import { isToday } from '@/helpers/date'
import i18n from '@/i18n'

export default function(date, showTimeForToday = false) {
  let newDate = new Date(date)

  return isToday(newDate)
    ? showTimeForToday
      ? newDate.toLocaleTimeString([], { timeStyle: 'short' })
      : i18n.t(`Today`)
    : newDate.toLocaleDateString()
}
