import { USER_TYPE } from '@/constants'
import { eventBus } from '@/helpers'
import i18n from '@/i18n'
import router from '@/router'
import store from '@/store'
import { sendSurveyResultsToGoogleSheet } from './googleSheet'

const SURVEY_INVITE_NOTIFICATION_ID = 'setup_survey_invite'

const invitationParams = {
  id: SURVEY_INVITE_NOTIFICATION_ID,
  title: i18n.t('Customize БAZA for you!'),
  description: i18n.t('Complete a short survey to receive personalized offers from the marketplace'),
  actionButtonText: i18n.t('Take a survey'),
  callback: () => {
    router.push({ name: 'setupSurvey' })
  },
}

const addSurveyInvitationToNotifications = () => {
  store.dispatch('notification/addNotificationWithAction', {
    ...invitationParams,
    dismissable: false,
  })
}

export const checkHasCompletedUserPreferencesSurvey = async () => {
  return (await store.dispatch('storage/read', 'setup_survey_completed')) === 'true'
}

const displaySurveyInvitationRequest = () => {
  if (router.currentRoute.name !== 'setupSurvey') {
    eventBus.$emit('showFloatingActionRequest', {
      ...invitationParams,
      dismissCallback: () => {
        try {
          localStorage.setItem('setup_survey_dismissed', true)
        } catch (e) {
          console.error(e)
        }
      },
    })
  }
}

export const inviteUserToSurvey = async () => {
  const user = store.state.user.currentUser

  if (!user || !Object.keys(user).length) {
    return
  }

  if (user.user_type !== USER_TYPE.BUYER) {
    return
  }

  try {
    const completed = await checkHasCompletedUserPreferencesSurvey()

    if (!completed) {
      addSurveyInvitationToNotifications()

      try {
        const hasDismissedSurvey = localStorage.getItem('setup_survey_dismissed')

        if (!hasDismissedSurvey) {
          setTimeout(() => {
            if (user && Object.keys(user).length) {
              // Вдруг свалил
              displaySurveyInvitationRequest()
            }
          }, 5000)
        }
      } catch (e) {
        console.error(e)
      }
    }
  } catch (e) {
    console.error(e)
  }
}

export const sendUserPreferencesSurveyToServer = async surveyResults => {
  await store.dispatch('storage/createOrUpdate', {
    key: 'setup_survey_completed',
    value: `${true}`,
  })

  await store.dispatch('storage/createOrUpdate', {
    key: 'user_preferences_survey_results',
    value: JSON.stringify(surveyResults),
  })

  await sendSurveyResultsToGoogleSheet(surveyResults)

  store.dispatch(
    'notification/setNotificationsWithAction',
    store.state.notification.notificationsWithAction.filter(i => i.id !== SURVEY_INVITE_NOTIFICATION_ID),
  )
}
