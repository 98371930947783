import { initialDeliveryInfo } from '@/constants'

export const state = {
  orders: [],
  order: {},
  orderProducts: [],
  currentPage: 1,
  totalPages: 1,
  orderStatuses: [],
  deliveryInfo: initialDeliveryInfo,
  weightCoefficient: {},
  orderUpdatedCount: 0, // TODO: Выяснить какого хрена оно не перерендеривает дочерние компоненты
}
