import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    productsForRequest: [],
    selectedProducts: [],
    totalPages: 1,
    currentPage: 1,
    showAddingModal: false,
    status: 'idle', // idle | loading
    loadedPages: new Set([]),
    selectedDistributor: null,
    showSelectDistributor: false,
  },
  actions: {
    toggleProductSelected: ({ commit, getters, state }, product) => {
      const updatedProducts = getters.isProductSelected(product.id)
        ? state.selectedProducts.filter(p => p.id !== product.id)
        : [...state.selectedProducts, product]

      commit('SET_SELECTED_PRODUCTS', updatedProducts)
    },
    async listProductsForSupplyRequest({ commit, state, rootState, rootGetters }, params = {}) {
      const isSystemSeller = rootGetters['user/isSystemSeller']
      const currentUser = rootState.user.currentUser

      const filtersParams = {
        application_id__isnull: true,
        page: state.currentPage,
        supplier: isSystemSeller ? state.selectedDistributor.user.id : currentUser.id,
        seller: isSystemSeller ? currentUser.id : state.selectedDistributor.user.id,
        ...params,
      }

      const result = await Vue.$http.get(`/profile_product/products/`, { params: filtersParams })

      commit('ADD_LOADED_PAGE', state.currentPage)
      commit('SET_PRODUCTS_FOR_SUPPLY_REQUEST', result.results)
      commit('SET_TOTAL_PAGES', result.total_pages)
    },
    changeCurrentPage: ({ commit, dispatch, state }, page) => {
      commit('SET_CURRENT_PAGE', page)

      if (!state.loadedPages.has(page)) {
        dispatch('listProductsForSupplyRequest')
      }
    },
    async createSupplyRequest(_, params) {
      const result = await Vue.$http.post(`/profile_product/application/`, params)
      return result
    },
    changeProductsQuantity: ({ commit }, quantity) => {
      commit('SET_PRODUCTS_QUANTITY', quantity)
    },
    setShowAddingModal: ({ commit }, show) => {
      commit('SET_SHOW_ADDING_MODAL', show)
    },
    updateProductQuantity: ({ commit, state }, { productId, quantity }) => {
      const updatedProducts = state.selectedProducts.map(product => {
        if (product.id === productId) {
          return { ...product, quantity }
        }

        return product
      })

      commit('SET_SELECTED_PRODUCTS', updatedProducts)
    },
    changeStatus: ({ commit }, status) => {
      commit('SET_STATUS', status)
    },
    resetState: ({ commit }) => {
      commit('RESET_STATE')
    },
    setShowSelectDistributor: ({ commit }, show) => {
      commit('SET_SHOW_SELECT_DISTRIBUTOR', show)
    },
    setSelectedDistributor: ({ commit }, distributor) => {
      commit('SET_SELECTED_DISTRIBUTOR', distributor)
    },
    resetProductsState: ({ commit }) => {
      commit('RESET_PRODUCTS_STATE')
    },
  },
  mutations: {
    SET_SELECTED_PRODUCTS: (state, newSelectedProducts) => {
      state.selectedProducts = newSelectedProducts
    },
    SET_PRODUCTS_FOR_SUPPLY_REQUEST: (state, products) => {
      state.productsForRequest = products
    },
    SET_TOTAL_PAGES: (state, numPages) => {
      state.totalPages = numPages
    },
    SET_CURRENT_PAGE: (state, page) => {
      state.currentPage = page
    },
    SET_PRODUCTS_QUANTITY: (state, quantity) => {
      state.productsQuantity = quantity
    },
    SET_SHOW_ADDING_MODAL: (state, val) => {
      state.showAddingModal = val
    },
    SET_STATUS: (state, newStatus) => {
      state.status = newStatus
    },
    ADD_LOADED_PAGE: (state, pageNum) => {
      state.loadedPages.add(pageNum)
    },
    SET_SHOW_SELECT_DISTRIBUTOR: (state, value) => (state.showSelectDistributor = value),
    SET_SELECTED_DISTRIBUTOR: (state, distributor) => {
      state.selectedDistributor = distributor
    },
    RESET_PRODUCTS_STATE: state => {
      state.selectedProducts = []
      state.productsForRequest = []
      state.totalPages = 1
      state.currentPage = 1
    },
    RESET_STATE: state => {
      state.selectedProducts = []
      state.productsForRequest = []
      state.totalPages = 1
      state.currentPage = 1
      state.showAddingModal = false
      state.status = 'idle'
      state.loadedPages = new Set([])
      state.showSelectDistributor = false
      state.selectedDistributor = null
    },
  },
  getters: {
    isProductSelected: state => productId => {
      return state.selectedProducts.some(p => p.id === productId)
    },
  },
}
