import { isProdClient, USER_TYPE } from '@/constants'
import { createUserLink } from '@/helpers/createUserLink'
import store from '@/store'

const appLink = isProdClient
  ? 'https://script.google.com/macros/s/AKfycbxhnf6qMt4AeZw5_EOcqOlg5cis9vDC9Fr2wteev7y62jEJfQLBjg1eyRAEA4TrHNqE/exec'
  : 'https://script.google.com/macros/s/AKfycbzUj_BuftHqc9gb80LzSfmSQL-kzwCcgp9XjvSUjd1pRc2VAKuSeAGBHSUcX6-Cr2_G/exec'

export const sendToGoogleSheet = async data => {
  const queryStr = new URLSearchParams()

  Object.entries(data).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach(item => queryStr.append(key, item.toString()))
    } else {
      queryStr.append(key, value.toString())
    }
  })

  return fetch(`${appLink}?${queryStr.toString()}`, {
    method: 'POST',
    mode: 'no-cors',
  })
}

/**
 * Отправляет данные о пользователе в гугл-таблицу.
 * @param {Object} user
 * @param {number} user.id - ID пользователя
 * @param {number} user.user_type - Тип пользователя строкой
 * @param {string} user.name - Имя пользователя
 * @param {string} user.email - Email пользователя
 * @param {string} user.phone_number - Номер телефона пользователя
 * @param {string} user.created_at - Дата регистрации пользователя
 * @param {Object} user.profile - Профиль пользователя
 * @param {number} user.profile.id - ID Профиля пользователя
 */

export const createOrUpdateUserInfoInGoogleSheet = user => {
  if (!user) {
    return
  }

  const { id, user_type, name, email, phone_number, created_at } = user

  const payload = {
    user_id: id,
    user_type: Object.entries(USER_TYPE).find(([key, val]) => val === user_type)[0],
    name,
    phone_number,
    email: email || '',
    // Значит он только что создан. Все равно оно потом обновится при входе.
    created_at: created_at || new Date().toISOString(),
    user_link: createUserLink(user),
  }

  return sendToGoogleSheet(payload)
}

/**
 * Отправляет данные опроса в гугл-таблицу
 * @param {Object} results - Объект с результатами опроса
 * @param {string[]} results.business_type - Тип бизнеса
 * @param {string[]} results.order_quantities - Объемы заказов
 * @param {string[]} results.geography - География закупок
 * @param {string[]} results.categories - Интересующие категории товаров
 * @param {string[]} results.fruits - Интересующие фрукты
 * @param {string[]} results.vegetables - Интересующие овощи
 * @param {string[]} results.mushrooms - Интересующие грибы
 * @param {string[]} results.greens - Интересующая зелень
 * @param {string[]} results.berries - Интересующие ягоды
 * @param {string[]} results.exotic - Интересующая экзотика
 * @param {string[]} results.salads - Интересующие салаты
 * @param {string[]} results.nuts - Интересующие орехи
 * @param {string[]} results.dried_fruits - Интересующие сухофрукты
 */

export const sendSurveyResultsToGoogleSheet = async results => {
  const currentUser = store.state.user.currentUser
  const payload = { ...results }

  payload.user_id = currentUser.id
  payload.survey_completed_at = new Date().toISOString()
  return sendToGoogleSheet(payload)
}
