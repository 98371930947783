export const isToday = date => {
  const today = new Date()
  return (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  )
}

export const hoursToMs = hours => hours * 60 * 60000

export const formatSecondsToTime = seconds => {
  var date = new Date(null)
  date.setSeconds(seconds)
  return date.toISOString().substr(11, 8)
}

export const getDaysBetweenDates = (firstDate, secondDate) => {
  return Math.abs((new Date(firstDate) - new Date(secondDate)) / 1000 / 60 / 60 / 24)
}
