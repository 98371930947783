<template>
  <div class="statuses">
    <h5 class="statuses__title">
      {{ $t('Request status') }}
    </h5>
    <ul v-if="statuses.length" class="statuses__list">
      <li
        v-for="status in statuses"
        :key="status"
        class="statuses__list-item"
        :class="{ 'statuses__list-item--active': checkInSelected(status) }"
        @click="setSelected(status)"
      >
        <div class="status-trigger" :style="{ backgroundColor: getStatusColor(status) }">
          <span class="status-trigger__name">{{ $t(`status.application.${status}`) }}</span>
        </div>
      </li>
    </ul>
    <p v-else class="statuses__empty">
      {{ $t('No available statuses') }}
    </p>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  name: 'StatusesFilter',
  data() {
    return {
      statuses: [],
      statusColors: {
        new: '#dfe8eb',
        open: '#e1f7a5',
        closed: '#f9dabe',
      },
    }
  },
  computed: {
    ...mapGetters('filtration', ['demandsStatusesSelected']),
    ...mapState('demand', ['demandStatuses'])
  },
  created() {
    this.statuses = this.demandStatuses
  },
  methods: {
    ...mapActions('filtration', ['setDemandsStatusesSelected']),
    checkInSelected(status) {
      return this.demandsStatusesSelected.includes(status)
    },
    setSelected(status) {
      if (this.checkInSelected(status)) {
        this.setDemandsStatusesSelected(this.demandsStatusesSelected.filter(item => item !== status))
      } else {
        this.setDemandsStatusesSelected([...this.demandsStatusesSelected, status])
      }
    },
    getStatusColor(status) {
      return this.statusColors[status] || '#fff'
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/functions';
@import '@/assets/scss/config';
@import '@/assets/scss/mixins';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.statuses {
  padding: $base-grid * 2 0;
  font-size: 14px;
  line-height: 18px;
  // Elements
  .statuses__title {
    margin: 0 0 13px 0;
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 15px;
  }

  .statuses__list {
    display: flex;
    flex-wrap: wrap;
    margin-left: -4px;
  }

  .statuses__list-item {
    cursor: pointer;
    opacity: 0.5;
    transition: opacity 0.2s;
    margin: 0 4px 4px;

    // States
    &--active {
      opacity: 1;
    }
  }
  .statuses__empty {
    color: $White;
    opacity: 0.75;
  }
}

.status-trigger {
  padding: $base-grid * 0.25 $base-grid * 0.65;
  border-radius: $base-grid * 0.25;
  font-weight: 500;
  color: $DGray;
  display: inline-flex;

  .status-trigger__name {
    white-space: nowrap;
  }
}
</style>
