import { lazyLoadViews } from '@/helpers'
import store from '@/store'
import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'
import i18n from '@/i18n'
import Chatra from '@chatra/chatra'
import { baseUrl, USER_TYPE } from '@/constants'

Vue.use(VueRouter)

export const router = new VueRouter({
  mode: 'history',
  routes: lazyLoadViews(routes),
  scrollBehavior: (to, from, savedPosition = { x: 0, y: 0 }) => savedPosition,
})

router.beforeEach(async (to, from, next) => {
  if (store.getters['auth/loggedIn']) {
    try {
      await store.dispatch('user/loadCurrentUser')
      const { currentUser } = store.state.user
      currentUser.language && (i18n.locale = currentUser.language)
      await store.dispatch('storage/createOrUpdate', { key: 'last_open_url', value: to.fullPath }).catch(e => {
        console.warn(e)
      })
    } catch (e) {
      // Если не удалось загрузить юзера (токены протухли?) переводим в логин
      console.error(e)
      store.dispatch('auth/logout')

      return next({
        name: 'login',
        query: {
          redirect: to.fullPath,
        },
      })
    }
  } else {
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth)

    if (requiresAuth) {
      return next({
        name: 'login',
        query: {
          redirect: to.fullPath,
        },
      })
    }
  }

  next()
})

router.afterEach(() => {
  if (window.Chatra) {
    Chatra('pageView', window.location.href)

    const user = store.state.user.currentUser

    if (user && Object.keys(user).length > 0) {
      const { id, user_type, name, email, phone_number, profile } = user

      const userRoute = `${baseUrl}/${user_type === USER_TYPE.BUYER ? `clients/${id}` : `providers/${profile?.id}`}`

      Chatra('setIntegrationData', {
        name: name,
        email: email,
        phone: phone_number,
        notes: userRoute,
      })
    }
  }
})

export default router
