import { fallbackKindsOfActivity } from '@/constants'
import Vue from 'vue'

const normalizeProfile = profile => ({ organization: {}, ...profile })
const filterDeletedUsers = profiles => profiles.filter(p => !!p.user)

export const actions = {
  async loadSeller({ commit }, { id }) {
    let result = await Vue.$http.get(`/profile/sellers/${id}/`)
    result = normalizeProfile(result)
    commit('setSeller', result)
  },
  async listSellers({ state, commit }, params = {}) {
    const result = await Vue.$http.get('/profile/sellers/', { params })
    commit('setSellers', result.results)
    if (state.totalPages !== result.total_pages) {
      commit('setTotalPages', result.total_pages)
      commit('setCurrentPage', 1)
    }
  },
  async listSystemSellers({ commit }, params = {}) {
    const result = await Vue.$http.get('/profile/sellers/', {
      params: {
        ...params,
        is_system_seller: true,
      },
    })

    commit('setSystemSellers', filterDeletedUsers(result.results))
    commit('setTotalPages', result.total_pages)
  },
  async listAllSystemSellers({ commit }) {
    let page = 1
    let totalPages = 1
    let systemSellers = []

    while (page <= totalPages) {
      const result = await Vue.$http.get('/profile/sellers/', { params: { is_system_seller: true, page } })
      systemSellers = [...systemSellers, ...result.results]
      totalPages = result.total_pages
      page++
    }

    commit('setSystemSellers', filterDeletedUsers(systemSellers))
    commit('setTotalPages', totalPages)
  },
  async loadBuyer({ commit }, { id, ...params }) {
    let result = await Vue.$http.get(`/profile/buyers/${id}/`, { params })
    result = normalizeProfile(result)
    commit('setBuyer', result)
  },
  async listBuyers({ state, commit }, params = {}) {
    const result = await Vue.$http.get('/profile/buyers/', { params })
    commit('setBuyers', result.results)

    if (state.totalPages !== result.total_pages) {
      commit('setTotalPages', result.total_pages)
      commit('setCurrentPage', 1)
    }
  },
  async listAvailableWarehouses({ commit }) {
    const result = await Vue.$http.get(`/profile/warehouse-addresses/`, { showLoadingBar: false })
    commit('setAvailableWarehouses', result.results)
  },
  async listBazaWarehouses({ commit }) {
    const result = await Vue.$http.get(`/profile/warehouse-baza/`, { addAccessToken: false, showLoadingBar: false })
    commit(
      'setBazaWarehouses',
      result.results.filter(w => w.is_system),
    )
  },
  async listKindsOfActivity({ commit }, params = {}) {
    let activities = []

    await Vue.$http
      .get('/profile/activities/', { params })
      .then(res => {
        activities = res.results
      })
      .catch(e => {
        console.error(e)
        activities = fallbackKindsOfActivity
      })

    commit('setKindsOfActivity', activities)
  },
  async listSubscriptionPlans({ commit }, params = {}) {
    const result = await Vue.$http.get('/profile/subscription-plans/', { showLoadingBar: false, params })
    commit('setSubscriptionPlans', result.results)
  },
}
