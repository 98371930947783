import Vue from 'vue'

export const actions = {
  setProductCategoriesSelected({ commit }, categories) {
    commit('changeProductCategoriesSelected', categories)
  },
  setProductGroupsSelected({ commit }, groups) {
    commit('changeProductGroupsSelected', groups)
  },
  setProductsSelected({ commit }, products) {
    commit('changeProductsSelected', products)
  },
  setProductSortsSelected({ commit }, sorts) {
    commit('changeSortsSelected', sorts)
  },
  setCountriesSelected({ commit }, countries) {
    commit('changeCountriesSelected', countries)
  },
  setCitiesSelected({ commit }, cities) {
    commit('changeCitiesSelected', cities)
  },
  async loadCitiesByCountry({ commit }, params = {}) {
    const result = await Vue.$http.get('/misc/cities/', { params })
    commit('changeCitiesByCountries', { cities: result })
  },
  setOrderPartnersSelected({ commit }, partners) {
    commit('changeOrderPartnersSelected', partners)
  },
  setOrderStatusesSelected({ commit }, orderStatuses) {
    commit('changeOrderStatusesSelected', orderStatuses)
  },
  setDemandsStatusesSelected({ commit }, demandsStatuses) {
    commit('changeDemandsStatusesSelected', demandsStatuses)
  },
  setFinancePartnersSelected({ commit }, financePartners) {
    commit('changeFinancePartnersSelected', financePartners)
  },
  setFinanceStatusesSelected({ commit }, financeStatuses) {
    commit('changeFinanceStatusesSelected', financeStatuses)
  },
  setFixedOrderPartners({ commit }, orderPartners) {
    commit('changeFixedOrderPartners', orderPartners)
  },
  setFixedFinancePartners({ commit }, financePartners) {
    commit('changeFixedFinancePartners', financePartners)
  },
  setWarehousesSelected({ commit }, warehouses) {
    commit('changeWarehousesSelected', warehouses)
  },
  clearAllFilters({ commit }) {
    commit('resetAllFilters')
  },
}
