export const initialFiltrationState = {
  productCategoriesSelected: [],
  productGroupsSelected: [],
  productsSelected: [],
  sortsSelected: [],
  countriesSelected: [],
  citiesSelected: [],
  citiesByCountries: [],
  orderPartnersSelected: [],
  orderStatusesSelected: [],
  demandsStatusesSelected: [],
  financePartnersSelected: [],
  financeStatusesSelected: [],
  warehousesSelected: [],

  // массивы-посредники для фильтрации
  fixedOrderPartners: [],
  fixedFinancePartners: [],
}

export const state = {
  ...initialFiltrationState,
}
