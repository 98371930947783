import Vue from 'vue'

export const actions = {
  async listCartItems({ state, commit }, { showLoadingBar = false, ...params }) {
    const result = await Vue.$http.get('/cart/items/', { showLoadingBar, params })
    commit('setCartItems', result.results)
    commit('SET_COMBINED_CART_ITEMS', [])

    if (state.totalPages !== result.total_pages) {
      commit('setTotalPages', result.total_pages)
      commit('setCurrentPage', 1)
    }
  },
  async getCartItem(_, { id }) {
    const result = await Vue.$http.get(`/cart/items/${id}/`)
    return result
  },
  async addToCart({ commit, getters }, params) {
    const alreadyInCart = getters.cartItemsProducts.some(
      item => item && item.seller_product.id === params.seller_product,
    )

    if (!alreadyInCart) {
      const result = await Vue.$http.post('/cart/add/', params)
      commit('addToCart', result)
    }
  },
  async editCartProduct({ commit }, { product_id, ...params }) {
    const result = await Vue.$http.patch(`/cart/products/${product_id}`, params)
    commit('saveCart', result)
  },
  async removeCartProduct({ commit }, { product_id }) {
    const result = await Vue.$http.delete(`/cart/products/${product_id}`)
    commit('saveCart', result)
  },
  async removeCartItem({ commit }, { id }) {
    await Vue.$http.delete(`/cart/items/${id}/`)
    commit('removeCartItem', id)
  },
  async clearCart({ commit }) {
    await Vue.$http.delete(`/cart/clear/`)
    commit('setCartItems', [])
    commit('SET_COMBINED_CART_ITEMS', [])
  },
  changePage({ commit }, page) {
    commit('setCurrentPage', page)
  },
  async editCartItem(_, { id, ...params }) {
    const result = await Vue.$http.patch(`/cart/items/${id}/edit/`, params)
    return result
  },
  addToCombinedCartItems({ commit, state }, item) {
    commit('SET_COMBINED_CART_ITEMS', [...state.combinedCartItems, item])
    commit(
      'setCartItems',
      state.cartItems.filter(i => i.id !== item.id),
    )
  },
  removeFromCombinedItems({ commit, state }, item) {
    commit(
      'SET_COMBINED_CART_ITEMS',
      state.combinedCartItems.filter(i => i.id !== item.id),
    )
    commit('setCartItems', [...state.cartItems, item])
  },
  setCombinedCartItems({ commit }, items) {
    commit('setCombinedCartItems', items)
  },
}
