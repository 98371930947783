export default {
  screenSize: [320, 450],
  responsiveSize: 'xs',
  // xs — mobile
  // sm — mobile/tablet
  // md — tablet/desktop
  // lg — desktop
  // xl — desktop,
  sidebarOpened: false,
  goodsFiltersOpened: false,
  goodsFiltersActiveTab: null,
}
