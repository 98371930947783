import i18n from '@/i18n'
import { updateField } from 'vuex-map-fields'

export const mutations = {
  setToken(state, token) {
    state.token = token
    ;(state.rememberMe ? localStorage : sessionStorage).setItem('token', JSON.stringify(token))
  },
  setCurrentUser(state, user) {
    user = { ...user }
    state.currentUser = user

    if (i18n.locale !== user.language) {
      i18n.locale = user.language
    }
  },
  logout(state) {
    state.token = null
    localStorage.removeItem('token')
    sessionStorage.removeItem('token')
  },
  updateField,
}
