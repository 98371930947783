import { mapGetters, mapActions } from 'vuex'

export default {
  computed: {
    ...mapGetters('filtration', [
      'productCategoriesSelected',
      'productGroupsSelected',
      'productsSelected',
      'sortsSelected',
      'countriesSelected',
      'citiesSelected',
    ]),
    hasActiveMarketFilters() {
      return [
        this.productCategoriesSelected.length,
        this.productGroupsSelected.length,
        this.productsSelected.length,
        this.sortsSelected.length,
        this.countriesSelected.length,
        this.citiesSelected.length,
      ].some(Boolean)
    },
    marketFiltersSummary() {
      return {
        productCategoriesSelected: this.productCategoriesSelected.length,
        productGroupsSelected: this.productGroupsSelected.length,
        productsSelected: this.productsSelected.length,
        sortsSelected: this.sortsSelected.length,
        countriesSelected: this.countriesSelected.length,
        citiesSelected: this.citiesSelected.length,
      }
    },
    marketOrderingOptions() {
      return [
        {
          value: null,
          name: this.$t('By default'),
        },
        {
          value: 'price',
          name: this.$t('By price'),
        },
        {
          value: 'product',
          name: this.$t('By name'),
        },
        {
          value: 'updated_at',
          name: this.$t('By date'),
        },
      ]
    },
  },
  methods: {
    ...mapActions('filtration', [
      'setProductCategoriesSelected',
      'setProductGroupsSelected',
      'setProductsSelected',
      'setProductSortsSelected',
      'setCountriesSelected',
      'setCitiesSelected',
      'clearAllFilters',
    ]),
  },
}
