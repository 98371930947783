export const mutations = {
  setCities: (state, data) => {
    state.cities = data
  },
  setCountries: (state, data) => {
    state.countries = data
  },
  setOrganizationTypes: (state, data) => {
    state.organizationTypes = data
  },
  setCurrencies: (state, data) => {
    state.currencies = data
  },
  setCurrentRubleExchangeRate: (state, data) => {
    state.currentRubleExchangeRate = data
  },
  setCitiesLoadedWithParams(state, loadedWithParams) {
    state.citiesLoadedWithParams = loadedWithParams
  },
  setWeightUnits(state, data) {
    state.weightUnits = data
  },
}
