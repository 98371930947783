import Vue from 'vue'

export const actions = {
  async getSuggestions({ commit }, { id, ...params }) {
    const result = await Vue.$http.get(`/demand_response/${id}/suggestions/`, {
      params,
    })
    // console.log(result)
    commit('setSuggestions', result.results)
  },
  async getBids({ commit }, { id, ...params }) {
    const result = await Vue.$http.get(`/demand_response/${id}/bids/`, params)
    // console.log('getBids', result)
    commit('setBids', result.results)
  },
  async createSuggestionForDemand(context, { id, ...params }) {
    await Vue.$http.post(`/demand_response/${id}/suggestions/`, params)
  },
  async createBidForDemandProduct(context, { id, ...params }) {
    await Vue.$http.post(`/demand_response/${id}/bids/`, params)
  },
  async rejectSuggestion(context, { id, suggestion_id }) {
    await Vue.$http.put(`/demand_response/${id}/suggestions/reject/${suggestion_id}`)
  },
  async getWinnerBid(context, { demand_id, item_id }) {
    const result = await Vue.$http.get(`/demand_response/${demand_id}/bids/win_bid/${item_id}/`)
    return result
  },
  async acceptBid(context, { demand_id, item_id }) {
    const result = await Vue.$http.put(`/demand_response/${demand_id}/bids/accept_bid/${item_id}/`)
    return result
  },
  async rejectBid(context, { demand_id, item_id }) {
    await Vue.$http.put(`/demand_response/${demand_id}/bids/reject_bid/${item_id}/`)
  },
  async deleteAuctionProductFromCart(context, { demand_id, product_id }) {
    await Vue.$http.put(`/demand_response/${demand_id}/bids/cancelled_bid/${product_id}/`)
  },
  async acceptSuggestionProduct(context, { demand_id, product_id }) {
    const result = await Vue.$http.put(`/demand_response/${demand_id}/suggestions/accept_product/${product_id}/`)
    console.log(result)
  },
  async rejectSuggestionProduct(context, { demand_id, product_id }) {
    const result = await Vue.$http.put(`/demand_response/${demand_id}/suggestions/reject_product/${product_id}/`)
    console.log(result)
  },
  async deleteSuggestionProductFromCart(context, { demand_id, product_id }) {
    const result = await Vue.$http.put(`/demand_response/${demand_id}/suggestions/cancelled_product/${product_id}/`)
    console.log(result)
  },
}
