<template>
  <header
    class="header"
    :class="{
      'header--no-auth': !loggedIn,
    }"
  >
    <router-link class="header__logo" :to="{ name: 'home' }">
      <Logo :change-color-on-mobile="loggedIn" />
    </router-link>

    <template v-if="loggedIn">
      <nav class="header__main-nav">
        <MainNav :navigation="userNav.mainNavigation" />
      </nav>

      <nav class="header__additional-nav">
        <UserNav :navigation="userNav.additionalNavigation" />
      </nav>

      <div class="header__account">
        <router-link
          :is="isSupportAgent ? 'span' : 'router-link'"
          class="header__account-avatar"
          :to="{ name: 'profileIndex' }"
        >
          <img
            :src="
              currentUser.photo_small
                ? resolveAssetUrl(currentUser.photo_small)
                : require('@/assets/images/icons/nophoto.png')
            "
            :alt="currentUser.name"
          />
        </router-link>
      </div>

      <router-link v-if="isBuyer" class="header__cart" :to="{ name: 'cart' }">
        <i class="icon-cart">
          <span v-if="hasCartItems" class="cart-quantity">{{ cartItemsProducts.length }}</span>
        </i>
      </router-link>
    </template>
    <div v-else class="header__no-auth">
      <LanguageSelect :small="inResponsiveSizes(['xs'])" />
      <router-link class="btn btn-new header__sign-link" :to="{ name: 'login' }">
        {{ $t('Sign in / Sign up') }}
      </router-link>
    </div>
  </header>
</template>

<script>
import Logo from './Logo'
import { mapState, mapGetters } from 'vuex'
import MainNav from './MainNav'
import UserNav from './UserNav'
import { API_URL, USER_NAVIGATION } from '@/constants.js'
import { eventBus } from '@/helpers'
import { resolveAssetUrl } from '@/helpers/url'
import LanguageSelect from './LanguageSelect.vue'
import inResponsiveSizes from '@/helpers/filters/inResponsiveSizes'

export default {
  components: {
    Logo,
    MainNav,
    UserNav,
    LanguageSelect,
  },
  data: () => ({ API_URL }),
  computed: {
    ...mapState('user', ['currentUser']),
    ...mapState('cart', ['cartItems']),
    ...mapGetters('user', ['isBuyer']),
    ...mapGetters('cart', ['cartItemsProducts']),
    ...mapGetters('user', ['isSupportAgent', 'isSystemSeller']),
    ...mapGetters('auth', ['loggedIn']),
    userNav() {
      if (!this.loggedIn) {
        return USER_NAVIGATION.noAuth
      }

      if (!this.isSupportAgent && this.isSystemSeller) {
        return USER_NAVIGATION.systemSeller
      }

      return USER_NAVIGATION[this.currentUser.user_type]
    },
    hasCartItems() {
      return this.cartItems?.length > 0
    },
  },
  async created() {
    if (this.isBuyer) {
      try {
        await this.$store.dispatch('cart/listCartItems', {})
      } catch (e) {
        console.error(e)
        eventBus.$emit('error', e)
      }
    }
  },
  methods: { resolveAssetUrl, inResponsiveSizes },
}
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/functions';
@import '@/assets/scss/config';
@import '@/assets/scss/mixins';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.header {
  background-color: $White;
  display: grid;
  grid-template-columns: 50px 1fr max-content max-content;
  grid-template-areas: 'logo additional-nav additional-nav additional-nav' 'main-nav main-nav user cart';
  max-width: 100%;
  // TODO: Remove after style clear
  &::before {
    content: unset;
    position: unset;
    z-index: unset;
    width: unset;
  }
  @include media-breakpoint-down(sm) {
    grid-template-rows: 41px 50px;
    padding: 0 20px;
    &::before {
      content: '';
      display: block;
      grid-column: 1 / -1;
      grid-row: 1 / span 1;
      background-color: $GreenM;
      color: $White;
      margin-left: -20px;
      margin-right: -20px;
    }
  }
  @include media-breakpoint-up(md) {
    grid-template-columns: 137px 1fr max-content max-content;
    grid-template-areas: 'logo main-nav additional-nav cart';
    grid-gap: 30px;
  }

  &--no-auth {
    display: flex;
    background-color: $White;
    padding: 0 16px;

    @include media-breakpoint-up(md) {
      padding: 0;
    }

    &::before {
      display: none;
    }
  }

  // Elements
  .header__logo {
    grid-area: logo;
    color: $GreenM;
    align-self: center;
    @include media-breakpoint-down(sm) {
      color: $White;
    }
    @include media-breakpoint-up(md) {
      padding-left: 32px;
    }
  }
  .header__main-nav {
    grid-area: main-nav;
    @include media-breakpoint-down(md) {
      overflow-x: auto;
      overflow-y: hidden;
      background: linear-gradient(to right, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0)),
        linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 50%) 100% 100%,
        radial-gradient(farthest-side at 0% 50%, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0)),
        radial-gradient(farthest-side at 100% 50%, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0)) 100% 100%;
      background-size: 40px 100%, 40px 100%, 7px 100%, 7px 100%;
      background-repeat: no-repeat;
      background-attachment: local, local, scroll, scroll;
      margin-left: -20px;
      padding-left: 20px;
      min-width: 120px;
    }
  }
  .header__additional-nav {
    grid-area: additional-nav;
    justify-self: end;
    align-self: center;
  }
  .header__cart {
    grid-area: cart;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: $GreenM;
    height: 50px;
    width: 50px;
    font-size: 26px;
    transition: 0.3s ease opacity 0s;

    &:hover {
      text-decoration: none;
      opacity: 0.6;
    }

    @include media-breakpoint-up(md) {
      height: $size-header-height;
      width: $size-header-height;

      border-left: 1px solid $LGray3;
    }

    .icon-cart {
      position: relative;
      padding: 10px 8px;
    }

    .cart-quantity {
      position: absolute;
      top: 0;
      right: 0;
      display: inline-block;
      border-radius: 1px;
      padding: 0 2px;
      background-color: $GreenM;
      font-weight: 600;
      font-size: 10px;
      line-height: 1.2;
      color: $White;
      font-family: $fontMain, sans-serif;
    }

    // Elements
    &::after {
      transform: translate(-50%, -80%);
    }
  }

  .header__account {
    grid-area: user;
    display: none;
    @include media-breakpoint-down(sm) {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 50px;
      width: 50px;
    }
  }
  .header__account-avatar {
    display: inline-flex;
    height: 22px;
    width: 22px;
    border-radius: 50%;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__no-auth {
    padding: 8px 0;
    margin-left: auto;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    > * + * {
      margin-left: 16px;

      @include media-breakpoint-down(xs) {
        margin-left: 12px;
      }
    }

    @include media-breakpoint-up(md) {
      margin-right: 32px;
      padding: 12px 0;
    }
  }

  &__sign-link {
    padding: 12px;
    border-radius: 10px;
    background-color: $GreenM;
    color: $White;

    @include media-breakpoint-up(md) {
      padding: 16px;
      border-radius: 14px;
    }
  }
}
</style>
