import localeDate from '@/helpers/filters/localeDate.js'
import localeTime from '@/helpers/filters/localeTime.js'
import { APPLICATION_TYPE } from '@/constants.js'

export const getters = {
  productsCount: state => (state.demand.products ? state.demand.products.length : 0),
  productsPrice: state =>
    state.demand.products ? state.demand.products.reduce((total, product) => (total += product.net_price), 0) : 0,
  productsWeight: state =>
    state.demand.products ? state.demand.products.reduce((total, product) => (total += product.net_weight), 0) : 0,
  dateTimeStart(state) {
    if (state.demand.type === APPLICATION_TYPE.AUCTION) {
      return `${localeDate(state.demand.auction_start_at)} ${localeTime(state.demand.auction_start_at)}`
    }
    return `${localeDate(state.demand.created_at)} ${localeTime(state.demand.created_at)}`
  },
  dateTimeEnd(state) {
    if (state.demand.type === APPLICATION_TYPE.AUCTION) {
      return `${localeDate(state.demand.auction_end_at)} ${localeTime(state.demand.auction_end_at)}`
    }
    return `${localeDate(state.demand.suggestion_end_at)} ${localeTime(state.demand.suggestion_end_at)}`
  },
}
