import { initialDeliveryInfo } from '@/constants'
import { transformOrderData } from '@/helpers/transformOrderData'
import { updateField } from 'vuex-map-fields'

export const mutations = {
  updateField,
  setOrders: (state, data) => (state.orders = data),
  setOrder: (state, data) => {
    const preparedOrder = transformOrderData(data)
    state.order = preparedOrder
    state.orderProducts = preparedOrder.products
    state.orderUpdatedCount += 1
  },
  setTotalPages: (state, pages) => (state.totalPages = pages),
  setCurrentPage: (state, page) => (state.currentPage = page),
  setOrderStatuses: (state, statuses) => (state.orderStatuses = statuses),
  SET_ORDER_PRODUCTS: (state, products) => (state.orderProducts = products),
  SET_DELIVERY_INFO: (state, data) => (state.deliveryInfo = data),
  RESET_DELIVERY_INFO: state => (state.deliveryInfo = initialDeliveryInfo),
  SET_WEIGHT_COEFFICIENT: (state, coefficient) => (state.weightCoefficient = coefficient),
}
