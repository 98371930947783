export const mutations = {
  setPersonalAccount: (state, data) => (state.personalAccount = data),
  setListFinances: (state, data) => (state.financesList = data),
  setFinanceDetail: (state, data) => (state.financeDetail = data),
  setTransfers: (state, data) => (state.transfers = data),
  setFinanceTotalPages: (state, pages) => (state.financeTotalPages = pages),
  setTransferTotalPages: (state, pages) => (state.transferTotalPages = pages),
  setCurrentPage: (state, page) => (state.currentPage = page),
  setFinanceStatuses: (state, statuses) => (state.financeStatuses = statuses),
}
