import Vue from 'vue'

export const actions = {
  async loadCurrentUser({ commit }) {
    let user = await Vue.$http.get(`/user/me/`, { showLoadingBar: false })
    commit('setCurrentUser', user)
  },
  async updateCurrentUser({ commit, state }, params) {
    const user = await Vue.$http.put(`/user/me/`, params)
    // Не возвращает контакты (потому что есть логика их скрытия, которая впрочем текущего юзера касаться не должна)
    commit('setCurrentUser', { ...user, email: state.currentUser.email, phone_number: state.currentUser.phone_number })
  },
  async updateCurrentProfile({ commit, state }, params) {
    const profile = await Vue.$http.put(`/user/me/profile/`, params)
    commit('setCurrentUser', { ...state.currentUser, profile })
  },
  async updateCurrentOrganization({ commit, state }, params) {
    const organization = await Vue.$http.put(`/user/me/organization/`, params)
    commit('setCurrentUser', { ...state.currentUser, profile: { ...state.currentUser.profile, organization } })
    // commit('setCurrentOrganization', organization)
  },
  async partiallyUpdateOrganization({ commit, state }, params) {
    const organization = await Vue.$http.patch(`/user/me/organization/`, params)
    commit('setCurrentUser', { ...state.currentUser, profile: { ...state.currentUser.profile, organization } })
  },
  async updateCurrentTermCondition({ commit, state }, params) {
    const term_condition = await Vue.$http.put('/user/me/term-condition/', params)
    commit('setCurrentUser', { ...state.currentUser, profile: { ...state.currentUser.profile, term_condition } })
    // commit('setCurrentTermCondition', term_condition)
  },
  async partiallyUpdateCurrentTermCondition({ commit, state }, params) {
    const term_condition = await Vue.$http.patch('/user/me/term-condition/', params)
    commit('setCurrentUser', { ...state.currentUser, profile: { ...state.currentUser.profile, term_condition } })
  },
  async addWarehouse(context, params) {
    await Vue.$http.post('/user/me/warehouse-addresses/', { ...params })
  },
  async updateWarehouse(context, params) {
    await Vue.$http.put(`/user/me/warehouse-addresses/${params.id}/`, {
      ...params,
    })
  },
  async deleteWarehouse(context, params) {
    await Vue.$http.delete(`/user/me/warehouse-addresses/${params.id}/`)
  },
  async uploadDocument(context, params) {
    await Vue.$http.post('/user/me/document', params)
  },
  async listDocuments({ commit }, params) {
    const result = await Vue.$http.get('/user/me/document', { params })
    console.log(result)
    commit('setDocuments', result.results)
  },
}
