import { WEIGHT_UNIT_NAMES } from '@/constants'

export const getters = {
  cities(state) {
    return state.cities
  },
  countries(state) {
    return state.countries
  },
  cityById: state => id => {
    if (!id) {
      return
    }
    return state.cities.find(city => city.id === id)
  },
  unitKg: state => {
    return state.weightUnits.find(u => u.name === WEIGHT_UNIT_NAMES.kg)
  },
  unitPcs: state => {
    return state.weightUnits.find(u => u.name === WEIGHT_UNIT_NAMES.pcs)
  },
  unitPkg: state => {
    return state.weightUnits.find(u => u.name === WEIGHT_UNIT_NAMES.pkg)
  },
  unitLiter: state => {
    return state.weightUnits.find(u => u.name === WEIGHT_UNIT_NAMES.l)
  },
  isKgOrLiter: state => weightUnit => {
    return state.weightUnits
      .filter(u => u.name === WEIGHT_UNIT_NAMES.kg || u.name === WEIGHT_UNIT_NAMES.l)
      .some(u => u.id === weightUnit.id)
  },
  isPcsOrPkg: state => weightUnit => {
    return state.weightUnits
      .filter(u => u.name === WEIGHT_UNIT_NAMES.pcs || u.name === WEIGHT_UNIT_NAMES.pkg)
      .some(u => u.id === weightUnit.id)
  },
  kgAndLiterOptions: state => {
    return state.weightUnits.filter(u => u.name === WEIGHT_UNIT_NAMES.kg || u.name === WEIGHT_UNIT_NAMES.l)
  },
}
