<template>
  <transition name="custom-modal">
    <div v-if="component" class="custom-modal" @click="closeModal">
      <div class="custom-modal__content" @click.stop>
        <component :is="component" :data="data" @close="closeModal" />
        <button class="custom-modal__close-button" @click="closeModal">
          <img class="custom-modal__close-button-icon" src="@/assets/images/icons/close.svg" :alt="$t('Close')" />
        </button>
      </div>
    </div>
  </transition>
</template>

<script>
import { eventBus } from '@/helpers/eventBus'

export default {
  name: 'GlobalModal',
  components: {
    AddToCart: () => import('@/components/modals/product/AddToCart'),
    ProductMoreInfo: () => import('@/components/modals/product/ProductMoreInfo'),
    FinanceOperationsModal: () => import('@/components/modals/finance/FinanceOperationsModal'),
    FeedbackModal: () => import('@/components/modals/delivery/FeedbackModal'),
    DeliveryReportModal: () => import('@/components/modals/delivery/DeliveryReportModal'),
    PaymentModal: () => import('@/components/modals/finance/PaymentModal'),
    DocumentAttachmentModal: () => import('@/components/modals/finance/DocumentAttachmentModal'),
    SubmitAction: () => import('@/components/modals/actions/SubmitAction'),
    ResultNotification: () => import('@/components/modals/actions/ResultNotification'),
    EditWarehouseModal: () => import('@/components/modals/profile/EditWarehouseModal'),
    SupplyComissionModal: () => import('@/components/modals/supplies/SupplyComissionModal'),
    ReturnProductModal: () => import('@/components/modals/supplies/ReturnProductModal'),
    SupplyRequestSummaryModal: () => import('@/components/modals/supplies/SupplyRequestSummaryModal'),
    DeliveryReportActionsModal: () => import('@/components/modals/delivery/DeliveryReportActionsModal'),
    ImagePreviewModal: () => import('@/components/modals/ImagePreviewModal'),
    AuthFinish: () => import('@/components/modals/AuthFinish'),
    ProfileDetailsModal: () => import('@/components/modals/ProfileDetailsModal'),
  },
  data() {
    return {
      component: null,
      data: null,
    }
  },
  watch: {
    component() {
      if (this.component !== null) {
        document.body.style.overflow = 'hidden'
        window.addEventListener('keydown', this.handleEscape)
      } else {
        document.body.style.overflow = ''
        window.removeEventListener('keydown', this.handleEscape)
      }
    },
  },
  created() {
    eventBus.$on('modal', passed => {
      this.component = passed.component
      this.data = passed.data
    })
  },
  methods: {
    closeModal() {
      this.component = null
      this.data = null
      document.body.style.overflow = ''
    },
    handleEscape(evt) {
      if (this.component !== null && evt.key === 'Escape') {
        this.closeModal()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~bootstrap/scss/functions';
@import '@/assets/scss/config';
@import '@/assets/scss/mixins';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import '@/assets/scss/silence/scroll-bar-decorative';

.custom-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 10001;
  overflow-y: auto;
  @extend %scroll-bar-decorative;
  padding: $base-grid * 2.5;
  background-color: rgba(11, 11, 11, 0.8);

  @include media-breakpoint-down(sm) {
    padding: 20px 10px;
  }

  // Elements
  .custom-modal__content {
    margin: auto;
    position: relative;
    background-color: $White;
    color: $Black;
    padding: $base-grid * 3;
    transition: all 0.2s;
    max-width: 100%;
    border-radius: $base-grid * 0.5;

    @include media-breakpoint-down(xs) {
      padding: $base-grid * 2;
    }
  }

  .custom-modal__close-button {
    position: absolute;
    z-index: 1000;
    top: 12px;
    right: 12px;
    display: flex;
    background-color: $White;
    border: none;
    border-radius: 4px;
    outline: none;
    box-shadow: none;
    padding: $base-grid * 0.5;

    &-icon {
      transition: $transition ease opacity 0s;
      filter: brightness(0.5);
    }

    @include media-breakpoint-up(lg) {
      &:hover {
        &-icon {
          opacity: 0.6;
        }
      }
    }

    @include media-breakpoint-down(sm) {
      top: $base-grid * 1.5;
      right: $base-grid * 1.5;
    }
  }

  // States
  &-enter-active,
  &-leave-active {
    transition: opacity $transition;
  }

  &-enter,
  &-leave-to {
    opacity: 0;

    .custom-modal__content {
      opacity: 0;
      transform: translateY(40%);
    }
  }
}
</style>
