import Vue from 'vue'

export default {
  namespaced: true,
  state: {
    distributorBuyers: [],
    totalPages: 1,
    currentPage: 1,
  },
  actions: {
    async listDistributorBuyers({ commit }, params = {}) {
      const result = await Vue.$http.get('/user/me/distributor-buyers/', { params })
      commit('SET_DISTRIBUTOR_BUYERS', result.results)
      commit('SET_TOTAL_PAGES', result.total_pages)
    },
    setCurrentPage({ commit }, page) {
      commit('SET_CURRENT_PAGE', page)
    },
    async updateDistributorBuyer({ commit, state }, { id, ...params }) {
      const result = await Vue.$http.patch(`/user/me/distributor-buyers/${id}/`, params)

      const updatedBuyers = state.distributorBuyers.map(item => {
        if (item.id === result.id) {
          return result
        }
        return item
      })

      commit('SET_DISTRIBUTOR_BUYERS', updatedBuyers)
    },
  },
  mutations: {
    SET_DISTRIBUTOR_BUYERS: (state, buyers /* a.k.a suppliers */) => {
      state.distributorBuyers = buyers
    },
    SET_TOTAL_PAGES: (state, pages) => {
      state.totalPages = pages
    },
    SET_CURRENT_PAGE: (state, page) => {
      state.currentPage = page
    },
  },
}
