import Vue from 'vue'

export const actions = {
  async listChats({ commit }, params = {}) {
    const result = await Vue.$http.get('/chat/chats/', { params, showLoadingBar: false })
    // console.log(result)
    commit('setChats', result)
  },
  async uploadFile(context, { id, formData }) {
    await Vue.$http.post(`/chat/chats/${id}/upload_file/`, formData)
  },
  setNewChatMessages({ commit }, messages) {
    commit('updateNewChatMessages', messages)
  },
}
