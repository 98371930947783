export const getters = {
  productCategoriesSelected(state) {
    return state.productCategoriesSelected
  },
  productCategoriesSelectedLength(state) {
    return state.productCategoriesSelected.length
  },
  productGroupsSelected(state) {
    return state.productGroupsSelected
  },
  productGroupsSelectedLength(state) {
    return state.productGroupsSelected.length
  },
  productsSelected(state) {
    return state.productsSelected
  },
  productsSelectedLength(state) {
    return state.productsSelected.length
  },
  sortsSelected(state) {
    return state.sortsSelected
  },
  sortsSelectedLength(state) {
    return state.sortsSelected.length
  },
  countriesSelected(state) {
    return state.countriesSelected
  },
  countriesSelectedLength(state) {
    return state.countriesSelected.length
  },
  citiesSelected(state) {
    return state.citiesSelected
  },
  citiesSelectedLength(state) {
    return state.citiesSelected.length
  },
  citiesByCountries(state) {
    return state.citiesByCountries
  },
  orderPartnersSelected(state) {
    return state.orderPartnersSelected
  },
  orderPartnersSelectedLength(state) {
    return state.orderPartnersSelected.length
  },
  orderStatusesSelected(state) {
    return state.orderStatusesSelected
  },
  orderStatusesSelectedLength(state) {
    return state.orderStatusesSelected.length
  },
  demandsStatusesSelected(state) {
    return state.demandsStatusesSelected
  },
  financePartnersSelected(state) {
    return state.financePartnersSelected
  },
  financeStatusesSelected(state) {
    return state.financeStatusesSelected
  },
  fixedOrderPartners(state) {
    return state.fixedOrderPartners
  },
  fixedFinancePartners(state) {
    return state.fixedFinancePartners
  },
  warehousesSelected(state) {
    return state.warehousesSelected
  },
}
