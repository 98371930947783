import store from '@/store'

export default [
  // Market
  {
    path: '/market',
    name: 'market',
    component: () => import('../../views/Market.vue'),
    beforeEnter(to, from, next) {
      store.getters['user/isSeller'] || store.getters['user/isExporter'] ? next({ name: 'products' }) : next()
    },
    meta: {
      sidebarComponents: ['GroupsFilter', 'ModalTriggerList', 'CategoriesFilter'],
      excludeModalTriggers: ['location'],
      showClearFiltersButton: true,
    },
    props: true,
    children: [
      {
        path: ':productId',
        name: 'product',
        component: () => import('../../views/Market.vue'),
      },
    ],
  },
  // Provider
  {
    path: '/providers',
    component: () => import('../../views/BlankChildrenRoute.js'),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: '/providers',
        component: () => import('../../views/Providers.vue'),
        name: 'providers',
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: ':userId',
        component: () => import('../../views/Provider.vue'),
        props: true,
        meta: {
          requiresAuth: true,
        },
        children: [
          {
            path: '/providers/:userId',
            component: () => import('../../views/provider/Info.vue'),
            name: 'provider',
            props: true,
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: 'products',
            component: () => import('../../views/provider/Products.vue'),
            name: 'providerProducts',
            props: true,
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: 'chat',
            component: () => import('../../views/provider/Chat.vue'),
            name: 'providerChat',
            props: true,
            meta: {
              requiresAuth: true,
            },
          },
        ],
      },
    ],
  },
  // Applications
  {
    path: '/applications',
    component: () => import('../../views/BlankChildrenRoute.js'),
    beforeEnter(to, from, next) {
      store.getters['user/isBuyer'] ? next() : next({ name: 'demands' })
    },
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: '/applications',
        component: () => import('../../views/Applications.vue'),
        name: 'applications',
        meta: {
          sidebarComponents: ['StatusesFilter', 'ModalTriggerList'],
          requiresAuth: true,
        },
      },
      {
        path: 'add',
        name: 'applicationAdd',
        component: () => import('../../views/AddApplication.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: ':applicationId',
        component: () => import('../../views/Application.vue'),
        name: 'application',
        props: true,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: ':applicationId/edit',
        component: () => import('../../views/AddApplication.vue'),
        name: 'applicationEdit',
        props: true,
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
]
