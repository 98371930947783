import Vue from 'vue'
import { eventBus } from '@/helpers/eventBus'

export const actions = {
  async checkout({ dispatch }, params) {
    const result = await Vue.$http.post('/order/orders/', params)

    // Эта проверка не нужна но вдруг что-то странное вернется вместо ошибки
    if (result && result.id) {
      await dispatch('cart/removeCartItem', { id: params.type_id }, { root: true }).catch()
    }

    return result
  },
  async listOrders({ state, commit }, params = {}) {
    eventBus.$emit('isLoading', true)
    const result = await Vue.$http.get('/order/orders/', { params })
    eventBus.$emit('isLoading', false)

    if (state.orderStatuses.length !== result.statuses.length) {
      commit('setOrderStatuses', result.statuses)
    }

    if (state.totalPages !== result.total_pages) {
      commit('setTotalPages', result.total_pages)
    }
    // console.log('listOrders', result)
    commit('setOrders', result.results)
  },
  async loadMoreOrders({ state, commit }, params) {
    eventBus.$emit('isLoading', true)
    const result = await Vue.$http.get('/order/orders/', {
      params,
    })
    commit('setCurrentPage', params.page)
    commit('setOrders', [...state.orders, ...result.results])
    eventBus.$emit('isLoading', false)
  },
  async orderDetail({ commit }, { id, ...params }) {
    const result = await Vue.$http.get(`/order/orders/${id}/`, {
      params,
    })
    commit('setOrder', result)
  },
  async createOrUpdateTruck({ commit, state }, { id, data }) {
    const result = await Vue.$http.put(`/order/${id}/truck/`, data)
    commit('setOrder', { ...state.order, truck: result })
  },
  async updateOrderItem(_, { id, ...params }) {
    return await Vue.$http.put(`/order/${id}/items/`, params)
  },
  async updateOrderStatus({ commit }, { id, ...params }) {
    const result = await Vue.$http.put(`/order/orders/${id}/`, params)
    commit('setOrder', result)
  },
  async evaluateOrder(_, { id, ...params }) {
    const result = await Vue.$http.post(`/order/orders/${id}/evaluate/`, params)
    console.log('evaluateOrder', result)
  },
  async listEvaluations(_, { id, ...params }) {
    await Vue.$http.get(`/order/${id}/evaluations/`, {
      params,
    })
    // console.log(result)
  },
  async uploadFile({ commit, state }, { id, formData }) {
    const result = await Vue.$http.post(`/order/${id}/documents/`, formData)
    commit('setOrder', {
      ...state.order,
      documents: [...state.order.documents, result],
    })
    const order = await Vue.$http.get(`/order/orders/${id}/`)
    commit('setOrder', order)
  },
  async createInspectation({ commit }, { id, formData }) {
    await Vue.$http.post(`/order/${id}/report/`, formData)
    const order = await Vue.$http.get(`/order/orders/${id}/`)
    commit('setOrder', order)
  },
  async handleReportedItems(_, { id, ...params }) {
    const result = await Vue.$http.post(`/order/${id}/return/`, params)
    console.log('RESULT', result)
  },
  async updateReportedItems(_, { id, ...params }) {
    const result = await Vue.$http.put(`/order/${id}/return/`, params)
    console.log('UPDATE STATUS RESULT', result)
  },
  changePage({ commit }, page) {
    commit('setCurrentPage', page)
  },
  setOrderProducts({ commit }, products) {
    commit('SET_ORDER_PRODUCTS', products)
  },
  async getDeliveryInfo({ commit }, { load, unload, type, delivery_type }) {
    const result = await Vue.$http.get(`/order/delivery/${load}/${unload}/${type}/${delivery_type}`)
    commit('SET_DELIVERY_INFO', result)
    return result
  },
  async getWeightCoefficient({ commit }, { weight }) {
    const result = await Vue.$http.get(`/order/delivery/coefficient/${weight}`)
    commit('SET_WEIGHT_COEFFICIENT', result)
    return result
  },
}
