export default {
  modals: {
    submitAction: {
      deleteProduct: 'Are you sure you want to delete product?',
      actionCantBeRejected: "You won't be able to cancel this action",
      registerTitle: 'You were successfully registered in system.',
      deleteWarehouse: 'Are you sure you want to delete warehouse?',
      unpublishProduct: 'Are you sure you want to unpublish product?',
      publishProduct: 'Are you sure you want to publish product?',
      deleteApplication: 'Are you sure you want to delete application?',
    },
    confirmationButton: {
      request_cancelation: 'annul',
      cancel: 'Cancel',
      seller_update: 'Conform',
      buyer_confirm_update: 'Agree',
      seller_confirm_operator_update: 'Agree',
      waiting_operator_confirm: 'Agree',
      sent_from_dc: 'Agree',
      on_way: 'Ship',
      submit: 'Confirm',
      confirm: 'Confirm',
      collected: 'Notify about collecting',
      filled: 'Ship',
      waiting_truck: 'Waiting for transport',
      waiting_filled_truck: 'Waiting for goods shipment',
      deliver: 'Delivered',
      send: 'Send',
      seller_correct: 'Corrected',
      hold: 'hold',
      put_on_realization: 'Confirm',
      return_registration: 'Confirm',
      complete: 'Complete',
      estimate: 'Evaluate',
      add: 'Add',
      rate_buyer: 'Rate',
      delete: 'Удалить',
    },
    confirmationTitle: {
      request_cancelation: 'Send a request to cancel the order?',
      cancel: 'Cancel the order?',
      seller_update: 'Send changes for confirmation?',
      buyer_confirm_update: 'Do you agree with changes?',
      seller_confirm_operator_update: 'Do you agree with changes?',
      sent_from_dc: 'Do you agree with changes?',
      waiting_operator_confirm: 'Confirm order',
      on_way: 'Send goods to distribution center?',
      submit: 'Confirm order',
      confirm: 'Confirm order',
      collected: 'Do you confirm order collecting?',
      filled: 'Do you condfirm full order shipping?',
      waiting_truck: 'Confirm action',
      waiting_filled_truck: 'Confirm action',
      deliver: 'Order delivered',
      put_on_realization: 'Put on realization',
      return_registration: 'Return registration',
      send: 'Confirm action',
      seller_correct: 'Send changes for coordination?',
      hold: "You don't agree with correction?",
      complete: 'Complete order',
      estimate: 'Make a review now?',
    },
    confirmationProductButton: {
      delete: 'Delete product',
      renewDate: 'Renew date',
      unpublish: 'Unpublish',
      publish: 'Publish',
    },
    confirmationCommonButton: {
      deleteWarehouse: 'Delete warehouse',
    },
  },
}
