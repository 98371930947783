export default {
  weight: {
    kg: 'кг',
    t: 'т',
    piece: 'шт',
    pcs: 'шт',
    liter: 'л',
    package: 'уп',
    pkg: 'уп',
    l: 'л',
    'unit': 'ед',
  },
  currencies: {
    RUB: '₽',
    USD: '$',
    EUR: '€',
  },
  // примеры: 0 | 1, 21, 101 | 2, 32, 222 | 5, 20, 118
  position: '0 позиций | {n} позиция | {n} позиции | {n} позиций',
  bids: '0 ставок | {n} ставка | {n} ставки | {n} ставок',
  fromProviders: '0 поставщиков | {n} поставщика | {n} поставщиков',
  suggestions: '0 откликов | {n} отклик | {n} отклика | {n} откликов',
}
