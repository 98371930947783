export default {
  changeScreenSize(state, screenSize) {
    state.screenSize = screenSize
  },
  changeSidebarOpened(state, sidebarOpened) {
    state.sidebarOpened = sidebarOpened
  },
  changeGoodsFiltersOpened(state, opened) {
    state.goodsFiltersOpened = opened
  },
  changeGoodsFiltersActiveTab(state, activeTab) {
    state.goodsFiltersActiveTab = activeTab
  },
}
