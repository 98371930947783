export class JSONWebToken {
  #token = null
  #header = null
  #payload = null

  constructor(token) {
    this.token = token
  }

  get token() {
    return this.#token
  }

  set token(token) {
    this.parseToken(token)
  }

  parseToken(token) {
    this.#token = token
    let parts = token.split('.')
    parts.pop() // signature
    ;[this.#header, this.#payload] = parts.map(atob).map(JSON.parse)
    Object.freeze(this.#header)
    Object.freeze(this.#payload)
  }

  get header() {
    return this.#header
  }

  get payload() {
    return this.#payload
  }

  get expirationDate() {
    return new Date(this.exp * 1e3)
  }

  get isExpired() {
    return this.expirationDate <= Date.now()
  }

  get exp() {
    return this.payload.exp
  }

  get jti() {
    return this.payload.jti
  }

  get tokenType() {
    return this.payload.token_type
  }

  get userId() {
    return this.payload.user_id
  }

  toString() {
    return this.token
  }

  toJSON() {
    return this.token
  }

  get authorizationHeader() {
    return `Bearer ${this.token}`
  }
}

export class AccessToken extends JSONWebToken {}

export class RefreshToken extends JSONWebToken {}

export class Token {
  #access = null
  #refresh = null

  constructor(obj) {
    this.updateFromResponse(obj)
  }

  updateFromResponse({ access_token, refresh_token }) {
    if (undefined !== access_token) {
      this.access = access_token
    }
    if (undefined !== refresh_token) {
      this.refresh = refresh_token
    }
  }

  set access(value) {
    this.#access = new AccessToken(value)
  }

  get access() {
    return this.#access
  }

  set refresh(value) {
    this.#refresh = new RefreshToken(value)
  }

  get refresh() {
    return this.#refresh
  }

  toJSON() {
    return { access_token: this.access, refresh_token: this.refresh }
  }
}

export const getToken = () => {
  try {
    let parsedToken = JSON.parse(localStorage.token || sessionStorage.token)
    return new Token(parsedToken)
  } catch (e) {
    return null
  }
}
